import React from 'react'
import { Field, Form, Formik } from 'formik'
import Fields from '../custom/Fields'
import { useTranslation } from 'react-i18next'
import { ResetNewPasswordValidation } from '../../utils/validations/formValidations'
import { FORM_INITIAL_VALUES } from '../../utils/constants/formValues'

function ResetPasswordForm(props) {
    const { t } = useTranslation()
    const { handleSubmit } = props
    return (
        <Formik
            initialValues={FORM_INITIAL_VALUES.RESET_PASSWORD}
            validationSchema={ResetNewPasswordValidation}
            onSubmit={fields => {
                return handleSubmit(fields)
            }}
        >
            {({ isSubmitting }) => (
                <Form className='form w-100'>
                    <div className='text-center mb-10'>
                        <h1 className='text-dark mb-3'>{t('SetupNewPassword')}</h1>
                    </div>
                    <div className='mb-10 fv-row fv-plugins-icon-container' data-kt-password-meter='true'>
                        <div className='mb-1'>
                            <label className='form-label fs-6 fw-bolder text-dark'>{t('Password')}</label>
                            <Field {...props} type='password' placeholder={t('Password')} name='password' as={Fields} />
                        </div>
                    </div>
                    <div className='fv-row mb-10 fv-plugins-icon-container'>
                        <label className='form-label fs-6 fw-bolder text-dark'>{t('ConfirmPassword')}</label>
                        <Field {...props} type='password' placeholder={t('ConfirmPassword')} name='confirmPassword' as={Fields} />
                    </div>
                    <div className='text-center'>
                        <button disabled={isSubmitting} className='btn btn-lg btn-primary fw-bolder me-4' type='submit' data-kt-indicator={`${isSubmitting ? 'on' : 'off'}`}>
                            <span className='indicator-label'>{t('Submit')}</span>
                            <span className='indicator-progress'>
                                Please wait...
                                <span className='spinner-border spinner-border-sm align-middle ms-2' />
                            </span>
                        </button>
                    </div>
                    <div />
                </Form>
            )}
        </Formik>
    )
}

export default ResetPasswordForm

import { Field, Form, Formik } from 'formik'
import * as Yup from 'yup'
import Fields from '../custom/Fields'
import React from 'react'

import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

function LoginForm(props) {
    const { t, i18n } = useTranslation()
    const { handleSubmit } = props

    return (
        <Formik
            initialValues={ {
                email: '',
                password: ''
            } }
            validationSchema={ Yup.object().shape({
                email: Yup.string()
                    .email(t('EmailIsInvalid'))
                    .required(t('EmailIsRequired')),
                password: Yup.string()
                    .min(6, t('PasswordMustBeAtLeast6Characters'))
                    .required(t('PasswordIsRequired'))
            }) }
            onSubmit={ fields => {
                return handleSubmit(fields)
            } }
        >
            { ({ isSubmitting }) => (
                <Form className='form w-100'>
                    <div className='text-center mb-8'>
                        <h1 className='text-dark mb-3'>{ t('SignInToRaylee') }</h1>
                        <div className="text-gray-400 fw-bold fs-4">{ t('NewHere') }
                            <Link to="/register" className="ms-1 link-primary fw-bolder">{ t('CreateAnAccount') }</Link>
                        </div>
                    </div>
                    <div className='fv-row mb-8'>
                        <label className="form-label fs-6 fw-bolder text-dark">{ t('Email') }</label>
                        <Field { ...props } type='text' placeholder={ t('Email') } name='email' as={ Fields } />
                    </div>
                    <div className="fv-row mb-8">
                        <div className="d-flex flex-stack">
                            <label className="form-label text-dark fs-6 mb-0">{ t('Password') }</label>
                            <Link to="/forgot-password" className="link-primary fs-6 fw-bolder">{ t('ForgotPassword') }</Link>
                        </div>
                        <Field type='password' placeholder={ t('Password') } name='password' as={ Fields } />
                    </div>
                    {
                        i18n.resolvedLanguage === 'en' ?
                            <div className='text-center mb-2'>
                                <span className="form-check-label fw-bold text-gray-700 fs-6">{ t('TermsAndConditionsAndPrivacyPolicy.1') }
                                    <a href="/termsAndConditions" className="ms-1 link-primary">{ t('TermsAndConditionsAndPrivacyPolicy.2') }</a> { t('TermsAndConditionsAndPrivacyPolicy.3') }
                                    <a href="https://www.x-climb.com/privacypolicy_en.html" target="_blank" className="ms-1 link-primary" rel="noreferrer">{ t('TermsAndConditionsAndPrivacyPolicy.4') }</a>
                                </span>
                            </div>
                            :
                            <div className='text-center mb-2'>
                                <span className="form-check-label fw-bold text-gray-700 fs-6">{ t('TermsAndConditionsAndPrivacyPolicy.1') }
                                    <a href="/termsAndConditions" className="ms-1 link-primary">{ t('TermsAndConditionsAndPrivacyPolicy.2') }</a> { t('TermsAndConditionsAndPrivacyPolicy.3') }
                                    <a href="https://www.x-climb.com/privacypolicy.html" target="_blank" className="ms-1 link-primary" rel="noreferrer">{ t('TermsAndConditionsAndPrivacyPolicy.4') }</a> { t('TermsAndConditionsAndPrivacyPolicy.5') }
                                </span>
                            </div>
                    }
                    <div className='text-center'>
                        <button id='kt_sign_in_submit' disabled={ isSubmitting } className='btn btn-lg btn-info w-100 mb-5' type='submit' data-kt-indicator={ `${ isSubmitting ? 'on' : 'off' }` }>
                            <span className='indicator-label'>{ t('Continue') }</span>
                            <span className='indicator-progress'>
                                { t('PleaseWait') }
                                <span className="spinner-border spinner-border-sm align-middle ms-2" />
                            </span>
                        </button>
                    </div>
                </Form>
            ) }
        </Formik>
    )
}

export default LoginForm

import React from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import useAuth from '../../utils/hooks/useAuth'

function Verify() {
    const params = useParams()
    const { t, i18n } = useTranslation()
    const { resendActivationEmail } = useAuth()

    const handleResendActivationEmail = async () => {
        await resendActivationEmail({
            ...params,
            language: i18n.resolvedLanguage
        }, t)
    }

    return (
        <div className='d-flex flex-column flex-root'>
            <div className='d-flex flex-column flex-column-fluid'>
                <div className='d-flex flex-column flex-column-fluid text-center p-10 py-lg-10'>
                    <div className='mb-12'>
                        <img className='h-60px' src='https://raylee-test.s3.ap-northeast-1.amazonaws.com/assets/icons/Raylee+Logo.svg' alt='Logo' />
                    </div>
                    <div className='pt-lg-10 mb-10'>
                        <h1 className='fw-bolder fs-2qx text-dark mb-7'>{ t('VerifyYourEmail') }</h1>
                        <div className='fs-3 fw-bold text-gray-400 mb-10'>{ t('WeHaveSentAnEmailTo') }
                            <a href={ `mailto:${ params.email }` } className='link-primary fw-bolder ms-2'>{ params.email }</a>
                            <br />{ t('PleaseFollowALinkToVerifyYourEmail') }</div>
                        <div className='fs-5'>
                            <span className='fw-bold text-gray-700'>{ t('DidntReceiveAnEmail') }</span>
                            <a href='#resend' onClick={ handleResendActivationEmail } className='link-primary fw-bolder ms-2'>{ t('Resend') }</a>
                        </div>
                    </div>
                    <div className='d-flex flex-row-auto bgi-no-repeat bgi-position-x-center bgi-size-contain bgi-position-y-bottom min-h-100px min-h-lg-350px' style={ { backgroundImage: 'url(/assets/media/illustrations/sigma-1/14.png)' } } />
                </div>
            </div>
        </div>
    )
}

export default Verify

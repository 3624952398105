import axios from 'axios'
import { ENDPOINTS } from '../constants/endpoint'
import { toast } from '../toast/toast'

function useComments() {
    const getCommentsByParams = async (params) => {
        return axios.get(process.env.REACT_APP_API_URL + ENDPOINTS.GET_COMMENTS, {
            params: params
        }).catch(error => toast('error', '', error.response.data.message))
    }

    const deleteComment = async (id) => {
        return axios.delete(process.env.REACT_APP_API_URL + ENDPOINTS.COMMENTS + '/' + id).catch(error => toast('error', '', error.response.data.message))
    }

    return { getCommentsByParams, deleteComment }
}

export default useComments

import axios from 'axios'

export const setupAxiosInterceptors = () => {
    axios.interceptors.request.use(config => {
        const token = localStorage.getItem('access_tkn')
        if (token) {
            config.headers['Authorization'] = 'Bearer ' + token
        }
        return config
    })
    axios.interceptors.response.use(
        response => {
            return response
        },
        function (error) {
            if (error.response && error.response.data.message === 'TypeError: Cannot read property \'get\' of undefined') {
                return axios.request(error.response.config)
            }
            return Promise.reject(error)
        }
    )
}

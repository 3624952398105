import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import KTAside from '../custom/KTAside'
import KTContent from '../custom/KTContent'

export default function Home() {
    const { t } = useTranslation()
    const ktContentPage = useSelector(state => state.appData.ktContentPage)
    return (
        <>
            <div className='d-flex flex-column flex-root'>
                <div className='page d-flex flex-row flex-column-fluid'>
                    <KTAside />
                    <div className='wrapper d-flex flex-column flex-row-fluid' id='kt_wrapper'>
                        <div id='kt_header' className='header' data-kt-sticky='true' data-kt-sticky-name='header' data-kt-sticky-offset="{default: '200px', lg: '300px'}">
                            <div className='container-xxl d-flex align-items-center justify-content-between' id='kt_header_container'>
                                <div className='page-title d-flex flex-column align-items-start justify-content-center flex-wrap me-lg-2 pb-5 pb-lg-0' data-kt-swapper='true' data-kt-swapper-mode='prepend' data-kt-swapper-parent="{default: '#kt_content_container', lg: '#kt_header_container'}">
                                    <h1 className='text-dark fw-bold my-0 fs-2'>{ t(ktContentPage) }</h1>
                                </div>
                                <div className='d-flex d-lg-none align-items-center ms-n2 me-2'>
                                    <div className='btn btn-icon btn-active-icon-primary' id='kt_aside_toggle'>
                                        <span className='svg-icon svg-icon-2x'>
                                            <img src={ process.env.PUBLIC_URL + '/assets/media/icons/duotune/abstract/abs015.svg' } />
                                        </span>
                                    </div>
                                    <a href='/' className='d-flex align-items-center'>
                                        <img alt="Logo" src="https://raylee-test.s3.ap-northeast-1.amazonaws.com/assets/icons/Raylee+Logo.svg" className='h-40px' />
                                    </a>
                                </div>
                            </div>
                        </div>
                        <KTContent />
                    </div>
                </div>
            </div>
            <div id='kt_scrolltop' className='scrolltop' data-kt-scrolltop='true'>
                <span className='svg-icon'>
                    <svg xmlns='http://www.w3.org/2000/svg' width={ 24 } height={ 24 } viewBox='0 0 24 24' fill='none'>
                        <rect opacity='0.5' x={ 13 } y={ 6 } width={ 13 } height={ 2 } rx={ 1 } transform='rotate(90 13 6)' fill='black'>
                            <path d='M12.5657 8.56569L16.75 12.75C17.1642 13.1642 17.8358 13.1642 18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711L5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75C6.16421 13.1642 6.83579 13.1642 7.25 12.75L11.4343 8.56569C11.7467 8.25327 12.2533 8.25327 12.5657 8.56569Z' fill='black' /></rect></svg>
                </span>
            </div>
        </>
    )
}
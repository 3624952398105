import React from 'react'
import { Field, Form, Formik, FieldArray } from 'formik'
import Fields from '../custom/Fields'
import { RAQValidation } from '../../utils/validations/formValidations'

import { useTranslation } from 'react-i18next'

function RAQForm(props) {
    const { t } = useTranslation()
    const { initialValues, handleSubmit } = props

    return (
        <Formik
            initialValues={ initialValues }
            enableReinitialize
            validationSchema={ RAQValidation }
            onSubmit={ async (fields, { ...formikProps }) => {
                return await handleSubmit(fields, formikProps)
            } }
        >
            { ({ isSubmitting, values, ...formikProps }) => (
                <Form className='form fv-plugins-bootstrap5 fv-plugins-framework'>
                    <div className="d-flex flex-column scroll-y me-n7 px-7" style={ { maxHeight: '637px' } } data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_add_user_header" data-kt-scroll-wrappers="#kt_modal_add_user_scroll" data-kt-scroll-offset="300px">
                        <div className="fv-row mb-7 ">
                            <Field { ...formikProps } type='text' placeholder={ t('Organization') } label={ t('Organization') } name='organization' as={ Fields } isDisabled />
                        </div>
                        <div className="fv-row mb-7 ">
                            <Field { ...formikProps } type='text' placeholder={ t('FirstName') } label={ t('FirstName') } name='firstName' as={ Fields } isDisabled />
                        </div>
                        <div className="fv-row mb-7 ">
                            <Field { ...formikProps } type='text' placeholder={ t('MiddleName') } label={ t('MiddleName') } name='middleName' as={ Fields } isDisabled />
                        </div>
                        <div className="fv-row mb-7 ">
                            <Field { ...formikProps } type='text' placeholder={ t('LastName') } label={ t('LastName') } name='lastName' as={ Fields } isDisabled />
                        </div>
                        <div className="fv-row mb-7 ">
                            <Field { ...formikProps } type='text' placeholder={ t('Email') } label={ t('Email') } name='email' as={ Fields } isDisabled />
                        </div>
                        <div className="fv-row mb-7 ">
                            <Field { ...formikProps } type='text' placeholder={ t('Status') } label={ t('Status') } name='status' as={ Fields } />
                        </div>
                        <div className="fv-row mb-7">
                            <FieldArray
                                name="remarks"
                                render={ arrayHelpers => (
                                    <div>
                                        { values?.remarks && values?.remarks.length > 0 ? (
                                            values?.remarks.map((remark, index) => (
                                                <div className='d-flex align-items-end mb-7' key={ index }>
                                                    <div className='col-9'>
                                                        <Field { ...formikProps } type='text' placeholder={ t('Remarks') } label={ `${ t('Remarks') } ${ index + 1 }` } name={ `remarks.${ index }` } as={ Fields } />
                                                    </div>
                                                    <div className='col d-flex justify-content-center'>
                                                        <button className="btn btn-danger" style={ { height: 'max-content' } } disabled={ isSubmitting } type="button" onClick={ () => arrayHelpers.remove(index) }>
                                                            <span className="indicator-label">-</span>
                                                        </button>
                                                    </div>
                                                    <div className='col d-flex justify-content-center'>
                                                        <button className="btn btn-primary" style={ { height: 'max-content' } } disabled={ isSubmitting } type="button" onClick={ () => arrayHelpers.insert(index + 1, '') }>
                                                            <span className="indicator-label">+</span>
                                                        </button>
                                                    </div>
                                                </div>
                                            ))
                                        ) : (
                                            <button className="btn btn-primary" disabled={ isSubmitting } type="button" onClick={ () => arrayHelpers.push('') }>
                                                <span className="indicator-label">{ t('AddRemark') }</span>
                                                <span className="indicator-progress">
                                                    { t('PleaseWait') }
                                                    <span className="spinner-border spinner-border-sm align-middle ms-2" />
                                                </span>
                                            </button>
                                        ) }
                                    </div>
                                ) }
                            />
                        </div>
                    </div>
                    <div className="text-end pt-15">
                        <button className="btn btn-light me-3" disabled={ isSubmitting } type="reset" data-bs-dismiss="modal" data-kt-users-modal-action="close">{ t('Cancel') }</button>
                        <button className="btn btn-primary" disabled={ isSubmitting } type="submit" data-kt-indicator={ `${ isSubmitting ? 'on' : 'off' }` } data-kt-users-modal-action="submit">
                            <span className="indicator-label">{ initialValues._id ? t('Update') : t('Create') }</span> <span className="indicator-progress">
                                { t('PleaseWait') }
                                <span className="spinner-border spinner-border-sm align-middle ms-2" />
                            </span>
                        </button>
                    </div>
                </Form>
            ) }
        </Formik>
    )
}

export default RAQForm

import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getObjectId } from '../../utils/transformer/Object'
import useVideos from '../../utils/hooks/useVideos'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'

function VideoPreview({ selectedActiveBusiness, handleSetData }) {
    const [isLoading, setIsLoading] = useState(false)
    const { getVideosByParams, updateVideo } = useVideos()
    const [videos, setVideos] = useState(null)
    const { t } = useTranslation()
    const videosRef = useRef()

    useEffect(async () => {
        if (selectedActiveBusiness?.value) {
            setIsLoading(true)
            const response = await getVideosByParams({
                page: 1,
                limit: 100,
                sort: 'sequence:asc',
                business: selectedActiveBusiness?.value._id
            })
            setVideos({
                activeLane: response.data.docs?.filter(video => video.isActive),
                inactiveLane: response.data.docs?.filter(video => !video.isActive)
            })
            videosRef.current = response.data.docs
        } else {
            videosRef.current = null
        }
        setIsLoading(false)
    }, [selectedActiveBusiness])

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list)
        const [removed] = result.splice(startIndex, 1)
        result.splice(endIndex, 0, removed)

        return result
    }

    /**
     * Moves an item from one list to another list.
     */
    const move = (source, destination, droppableSource, droppableDestination) => {
        const sourceClone = Array.from(source)
        const destClone = Array.from(destination)
        const [removed] = sourceClone.splice(droppableSource.index, 1)

        destClone.splice(droppableDestination.index, 0, removed)

        const result = {}
        result[droppableSource.droppableId] = sourceClone
        result[droppableDestination.droppableId] = destClone

        return result
    }

    const grid = 8

    const getItemStyle = (isDragging, draggableStyle) => ({
        // some basic styles to make the items look a bit nicer
        userSelect: 'none',
        padding: grid * 2,
        margin: `0 ${ grid }px 0 0`,

        // change background color if dragging
        background: isDragging ? '#6dc6e3' : '#F5F8FA',
        borderRadius: '10px',

        // styles we need to apply on draggable
        ...draggableStyle,
    })

    const getListStyle = isDraggingOver => ({
        background: isDraggingOver && 'lightblue',
        borderRadius: '10px',
        display: 'flex',
        padding: grid,
        overflow: 'auto',
        minHeight: '274px'
    })

    const onDragEnd = (result) => {
        const { source, destination } = result
        const isFromActiveLane = source.droppableId === 'activeLane'

        // dropped outside the list
        if (!destination) {
            return
        }

        if (source.droppableId === destination.droppableId) {
            const items = reorder(
                isFromActiveLane ? videos.activeLane : videos.inactiveLane,
                source.index,
                destination.index
            )
            items.map((video, index) => {
                updateVideo({
                    ...video,
                    sequence: index + 1,
                    organization: getObjectId(video.organization),
                    business: getObjectId(video.business),
                    user: getObjectId(video.user),
                    tags: video.tags || [],
                    isActive: isFromActiveLane
                })
                return video
            })
            if (isFromActiveLane) {
                setVideos({
                    inactiveLane: videos.inactiveLane,
                    activeLane: items
                })
            } else {
                setVideos({
                    activeLane: videos.activeLane,
                    inactiveLane: items
                })
            }
        } else {
            let result
            if (isFromActiveLane) {
                result = move(
                    videos.activeLane,
                    videos.inactiveLane,
                    source,
                    destination
                )
            } else {
                result = move(
                    videos.inactiveLane,
                    videos.activeLane,
                    source,
                    destination
                )
            }
            setVideos(result)
            result.activeLane.map((video, index) => {
                updateVideo({
                    ...video,
                    sequence: index + 1,
                    organization: getObjectId(video.organization),
                    business: getObjectId(video.business),
                    user: getObjectId(video.user),
                    tags: video.tags || [],
                    isActive: true
                })
                return video
            })
            result.inactiveLane.map((video, index) => {
                updateVideo({
                    ...video,
                    sequence: index + 1,
                    organization: getObjectId(video.organization),
                    business: getObjectId(video.business),
                    user: getObjectId(video.user),
                    tags: video.tags || [],
                    isActive: false
                })
                return video
            })
        }
    }

    if (isLoading) {
        return (
            <div className="w-100 vh-100 d-flex align-items-center justify-content-center">
                <div className="spinner-border" style={ { width: '3rem', height: '3rem' } } role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
        )
    }


    if (!selectedActiveBusiness?.value) {
        return (
            <div className="d-flex align-items-center justify-content-center">
                <span className="text-gray-700 fw-bold me-1">{ t('NoSelectedBusiness') }</span>
            </div>
        )
    }

    return (
        <div className="w-100">
            <div className="w-100">
                <div className="row mt-3">
                    <div
                        id={ 'video-preview-toggle-video-form-modal' }
                        data-bs-toggle={ 'modal' }
                        data-bs-target={ '#kt_modal_add_video' }
                    />
                    <DragDropContext onDragEnd={ onDragEnd }>
                        <header className={ 'ms-2' }>
                            <span className={ 'text-gray-700 fs-4 fw-bold' }>{ t('ActiveVideos') }</span>
                        </header>
                        <Droppable droppableId="activeLane" direction="horizontal">
                            { (provided, snapshot) => (
                                <div
                                    className='mx-2'
                                    ref={ provided.innerRef }
                                    style={ getListStyle(snapshot.isDraggingOver) }
                                    { ...provided.droppableProps }
                                >
                                    { videos?.activeLane.map((item, index) => (
                                        <Draggable key={ item._id } draggableId={ item._id } index={ index }>
                                            { (provided, snapshot) => (
                                                <div
                                                    ref={ provided.innerRef }
                                                    { ...provided.draggableProps }
                                                    { ...provided.dragHandleProps }
                                                    style={ getItemStyle(
                                                        snapshot.isDragging,
                                                        provided.draggableProps.style
                                                    ) }
                                                    onClick={ () => {
                                                        handleSetData(item)
                                                        const el = document.getElementById('video-preview-toggle-video-form-modal')
                                                        if (el) {
                                                            el.click()
                                                        }
                                                    } }
                                                >
                                                    <img
                                                        src={ process.env.REACT_APP_AWS_CLOUDFRONT_DOMAIN + '/' + item.thumbnailKey }
                                                        height='140px'
                                                        width='140px'
                                                        className='react-player cursor-pointer mb-2'
                                                    />
                                                    <div className='ms-1'>
                                                        <span className={ 'fw-bold badge badge-primary' }>
                                                            { `${ index + 1 }/${ videos?.activeLane?.length }` }
                                                        </span>
                                                        <br />
                                                        <span className={ 'fw-bold' }>
                                                            { `${ item.title }` }
                                                        </span>
                                                        <br />
                                                        <i className='bi bi-eye me-2'></i>
                                                        <span className={ 'fw-bold' }>
                                                            { `${ item.stats.viewCount } ${ t('ViewCount') }` }
                                                        </span>
                                                        <br />
                                                        <i className='bi bi-heart me-2'></i>
                                                        <span className={ 'fw-bold' }>
                                                            { `${ item.stats.likeCount } ${ t('LikeCount') }` }
                                                        </span>
                                                    </div>
                                                </div>
                                            ) }
                                        </Draggable>
                                    )) }
                                </div>
                            ) }
                        </Droppable>
                        <header className={ 'ms-2' }>
                            <span className={ 'text-gray-700 fs-4 fw-bold' }>{ t('InactiveVideos') }</span>
                        </header>
                        <Droppable droppableId="inactiveLane" direction="horizontal">
                            { (provided, snapshot) => (
                                <div
                                    className='mx-2'
                                    ref={ provided.innerRef }
                                    style={ getListStyle(snapshot.isDraggingOver) }
                                    { ...provided.droppableProps }
                                >
                                    { videos?.inactiveLane.map((item, index) => (
                                        <Draggable key={ item._id } draggableId={ item._id } index={ index }>
                                            { (provided, snapshot) => (
                                                <div
                                                    ref={ provided.innerRef }
                                                    { ...provided.draggableProps }
                                                    { ...provided.dragHandleProps }
                                                    style={ getItemStyle(
                                                        snapshot.isDragging,
                                                        provided.draggableProps.style
                                                    ) }
                                                    onClick={ () => {
                                                        handleSetData(item)
                                                        const el = document.getElementById('video-preview-toggle-video-form-modal')
                                                        if (el) {
                                                            el.click()
                                                        }
                                                    } }
                                                >
                                                    <img
                                                        src={ process.env.REACT_APP_AWS_CLOUDFRONT_DOMAIN + '/' + item.thumbnailKey }
                                                        height='140px'
                                                        width='140px'
                                                        className='react-player cursor-pointer mb-2'
                                                    />
                                                    <div className='ms-1'>
                                                        <span className={ 'fw-bold badge badge-primary' }>
                                                            { `${ index + 1 }/${ videos?.inactiveLane?.length }` }
                                                        </span>
                                                        <br />
                                                        <span className={ 'fw-bold' }>
                                                            { `${ item.title }` }
                                                        </span>
                                                        <br />
                                                        <i className='bi bi-eye me-2'></i>
                                                        <span className={ 'fw-bold' }>
                                                            { `${ item.stats.viewCount } ${ t('ViewCount') }` }
                                                        </span>
                                                        <br />
                                                        <i className='bi bi-heart me-2'></i>
                                                        <span className={ 'fw-bold' }>
                                                            { `${ item.stats.likeCount } ${ t('LikeCount') }` }
                                                        </span>
                                                    </div>
                                                </div>
                                            ) }
                                        </Draggable>
                                    )) }
                                </div>
                            ) }
                        </Droppable>
                    </DragDropContext>
                </div>
            </div>
        </div>
    )
}

export default VideoPreview

import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { logout, setKtContentPage } from '../../reducers'
import useLanguage from '../../utils/hooks/useLanguage'

import { useTranslation } from 'react-i18next'
import useOrganizations from '../../utils/hooks/useOrganizations'
import { useHistory } from 'react-router'

function KTAside() {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const history = useHistory()
    const loggedInUser = useSelector(state => state.authData.loggedInUser)
    const ktContentPage = useSelector(state => state.appData.ktContentPage)
    const { getOrganizationByParams } = useOrganizations()
    const [organization, setOrganization] = useState(null)
    const { updateLanguage } = useLanguage()
    const currentLocale = localStorage.getItem('i18nextLng')

    useEffect(() => {
        if (loggedInUser.type !== 'ADMIN') {
            getOrganizationData()
        }
    }, [loggedInUser])

    const getOrganizationData = async () => {
        const response = await getOrganizationByParams({
            id: loggedInUser.organization
        })
        if (response.data?.docs.length > 0) {
            setOrganization(response.data.docs[0])
        }
    }

    const handleMenuItemClick = (page) => {
        dispatch(setKtContentPage(page))
        history.push('/')
    }

    const handleLogoutClick = () => {
        dispatch(logout())
        history.push('/')
    }
    const handleChangedLanguage = (lang) => {
        updateLanguage(lang)
    }

    return (
        <div id='kt_aside' className='aside aside-extended bg-white' data-kt-drawer='true' data-kt-drawer-name='aside' data-kt-drawer-activate='{default: true, lg: false}' data-kt-drawer-overlay='true' data-kt-drawer-width='auto' data-kt-drawer-direction='start' data-kt-drawer-toggle='#kt_aside_toggle'>
            <div className='aside-primary d-flex flex-column align-items-lg-center flex-row-auto'>
                <div className='aside-logo d-none d-lg-flex flex-column align-items-center flex-column-auto py-10' id='kt_aside_logo'>
                    <img alt="Logo" src="https://raylee-test.s3.ap-northeast-1.amazonaws.com/assets/icons/Raylee+Logo.svg" className="h-50px" />
                </div>
                <div className='aside-nav d-flex flex-column align-items-center flex-column-fluid w-100 pt-5 pt-lg-0' id='kt_aside_nav'>
                    <div className='hover-scroll-y mb-10' data-kt-scroll='true' data-kt-scroll-activate='{default: false, lg: true}' data-kt-scroll-height='auto' data-kt-scroll-wrappers='#kt_aside_nav' data-kt-scroll-dependencies='#kt_aside_logo, #kt_aside_footer' data-kt-scroll-offset='0px'>
                        <ul className='nav flex-column'>
                            <li className='nav-item mb-2' data-bs-toggle='tooltip' data-bs-trigger='hover' data-bs-placement='right' data-bs-dismiss='click' >
                            </li>
                        </ul>
                    </div>
                </div>
                <div className='aside-footer d-flex flex-column align-items-center flex-column-auto' id='kt_aside_footer'>
                    <div className='d-flex align-items-center mb-10 dropend' id='kt_header_user_menu_toggle'>
                        <div className='cursor-pointer symbol symbol-40px dropdown-toggle position-static' id={ 'user-popup-menu' } data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false">
                            <img src='https://raylee-test.s3.ap-northeast-1.amazonaws.com/assets/icons/default-profile-pic.svg' alt='' />
                        </div>
                        <div className='dropdown-menu menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-auto w-300px' data-kt-menu='true' aria-labelledby={ 'user-popup-menu' }>
                            <div className='menu-item px-3'>
                                <div className='menu-content d-flex align-items-center px-3'>
                                    <div className='d-flex flex-column'>
                                        <div className='fw-bolder fs-5 px-5 lh-1'>
                                            <p>{ `${ loggedInUser.email ? loggedInUser.email : loggedInUser.firstName }` }</p>
                                            <span className='badge badge-light-success fw-bolder fs-8 px-2 py-1'>
                                                { loggedInUser.type }
                                            </span>
                                            <span className='badge badge-light-info fw-bolder fs-8 px-2 py-1'>
                                                { organization?.planType }
                                            </span>
                                        </div>
                                        <span className='text-muted'>{ organization?.name }</span>
                                    </div>
                                </div>
                            </div>
                            <div className='separator my-2' />
                            <div className='menu-item px-5'>
                                <span className='menu-link px-5 drop-down' data-bs-toggle="collapse" data-bs-target="#language" aria-expanded="false" aria-controls="language">
                                    <span className='menu-title position-relative'>{ t('Language') }
                                        <span className='fs-8 rounded bg-light px-3 py-2 position-absolute translate-middle-y top-50 end-0'>{ currentLocale == 'en' ? t('English') : t('Japanese') }
                                            <img className='w-15px h-15px rounded-1 ms-2' src={ currentLocale == 'en' ? '/assets/media/flags/united-states.svg' : '/assets/media/flags/japan.svg' } alt='' />
                                        </span>
                                    </span>
                                </span>
                                <div className="collapse" id="language">
                                    <div className="card card-body">
                                        <div className='menu-item px-3'>
                                            <span className={ currentLocale == 'en' ? 'menu-link d-flex px-5 active' : 'menu-link d-flex px-5 ' } onClick={ () => handleChangedLanguage('en') }>
                                                <span className='symbol symbol-20px me-4'>
                                                    <img className='rounded-1' src='/assets/media/flags/united-states.svg' alt='' />
                                                </span>{ t('English') }</span>
                                        </div>
                                        <div className='menu-item px-3'>
                                            <span className={ currentLocale == 'jp' ? 'menu-link d-flex px-5 active' : 'menu-link d-flex px-5' } onClick={ () => handleChangedLanguage('jp') } >
                                                <span className='symbol symbol-20px me-4'>
                                                    <img className='rounded-1' src='/assets/media/flags/japan.svg' alt='' />
                                                </span>{ t('Japanese') }</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='separator my-2' />
                            <div className='menu-item px-5'>
                                <span className='menu-link px-5 drop-down' data-bs-toggle="collapse" data-bs-target="#account" aria-expanded="false" aria-controls="account">
                                    <span className='menu-title position-relative'>{ t('Account') }</span>
                                </span>
                                <div className="collapse" id="account">
                                    <div className="card card-body">
                                        <div className='menu-item px-3' onClick={ () => handleMenuItemClick('ChangePassword') }>
                                            <span className={ 'menu-link d-flex px-5' }>
                                                <span className='symbol symbol-20px me-4'>
                                                    <img className='rounded-1' src='/assets/media/icons/duotune/general/gen049.svg' alt='' />
                                                </span>{ t('ChangePassword') }
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='separator my-2' />
                            <div className='menu-item px-5'>
                                <span className='menu-link px-5' onClick={ handleLogoutClick }>{ t('SignOut') }</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='aside-secondary d-flex flex-row-fluid'>
                <div className='aside-workspace my-5 p-5' id='kt_aside_wordspace'>
                    <div className='d-flex h-100 flex-column'>
                        <div className='flex-column-fluid hover-scroll-y' data-kt-scroll='true' data-kt-scroll-activate='true' data-kt-scroll-height='auto' data-kt-scroll-wrappers='#kt_aside_wordspace' data-kt-scroll-dependencies='#kt_aside_secondary_footer' data-kt-scroll-offset='0px' >
                            <div className='tab-content'>
                                <div className='tab-pane fade active show' id='kt_aside_nav_tab_menu' role='tabpanel'>
                                    <div className='menu menu-column menu-fit menu-rounded menu-title-gray-600 menu-icon-gray-400 menu-state-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-500 fw-bold fs-6 px-6 my-5 my-lg-0' id='kt_aside_menu' data-kt-menu='true'>
                                        <div id='kt_aside_menu_wrapper' className='menu-fit'>
                                            <div className='menu-item'>
                                                <div className='menu-content pb-2'>
                                                    <span className='menu-section text-muted text-uppercase fs-8 ls-1'></span>
                                                </div>
                                            </div>
                                            {/* 
                                            Hide For now, because this Page is not functional yet
                                            <div className='menu-item mb-1'>
                                                <span className={`menu-link ${ktContentPage === t('Dashboard') && 'active'}`} onClick={() => handleMenuItemClick(t('Dashboard'))}>
                                                    <span className='menu-icon'>
                                                        <span className='svg-icon svg-icon-2'>
                                                            <img src="/assets/media/icons/duotune/general/gen001.svg" />
                                                        </span>
                                                    </span>
                                                    <span className='menu-title'>{t('Dashboard')}</span>
                                                </span>
                                            </div> */}
                                            <div className='menu-item mb-1'>
                                                <span className={ `menu-link ${ ktContentPage === 'VerticalVideoManagement' && 'active' }` } onClick={ () => handleMenuItemClick('VerticalVideoManagement') }>
                                                    <span className='menu-icon'>
                                                        <span className='svg-icon svg-icon-2'>
                                                            <img src="/assets/media/icons/duotune/abstract/abs032.svg" />
                                                        </span>
                                                    </span>
                                                    <span className='menu-title'>{ t('VideoManagement') }</span>
                                                </span>
                                            </div>
                                            <div className='menu-item mb-1'>
                                                <span className={ `menu-link ${ ktContentPage === 'LandscapeVideoManagement' && 'active' }` } onClick={ () => handleMenuItemClick('LandscapeVideoManagement') }>
                                                    <span className='menu-icon'>
                                                        <span className='svg-icon svg-icon-2'>
                                                            <img src="/assets/media/icons/duotune/abstract/abs032.svg" />
                                                        </span>
                                                    </span>
                                                    <span className='menu-title'>{ t('Video Management (16:9)') }</span>
                                                </span>
                                            </div>
                                            <div className='menu-item mb-1'>
                                                <span className={ `menu-link ${ ktContentPage === 'PageManagement' && 'active' }` } onClick={ () => handleMenuItemClick('PageManagement') }>
                                                    <span className='menu-icon'>
                                                        <span className='svg-icon svg-icon-2'>
                                                            <img src="/assets/media/icons/duotune/coding/cod003.svg" />
                                                        </span>
                                                    </span>
                                                    <span className='menu-title'>{ t('PageManagement') }</span>
                                                </span>
                                            </div>
                                            <div className='menu-item mb-1'>
                                                <span className={ `menu-link ${ ktContentPage === 'AssetManagement' && 'active' }` } onClick={ () => handleMenuItemClick('AssetManagement') }>
                                                    <span className='menu-icon'>
                                                        <span className='svg-icon svg-icon-2'>
                                                            <img src="/assets/media/icons/duotune/files/fil012.svg" />
                                                        </span>
                                                    </span>
                                                    <span className='menu-title'>{ t('AssetManagement') }</span>
                                                </span>
                                            </div>
                                            <div className='menu-item mb-1'>
                                                <span className={ `menu-link ${ ktContentPage === 'PopUpManagement' && 'active' }` } onClick={ () => handleMenuItemClick('PopUpManagement') }>
                                                    <span className='menu-icon'>
                                                        <span className='svg-icon svg-icon-2'>
                                                            <img src="/assets/media/icons/duotune/abstract/abs039.svg" />
                                                        </span>
                                                    </span>
                                                    <span className='menu-title'>{ t('PopUpManagement') }</span>
                                                </span>
                                            </div>
                                            { loggedInUser.type === 'ADMIN' &&
                                                <div className='menu-item mb-1'>
                                                    <span className={ `menu-link ${ ktContentPage === 'OrganizationManagement' && 'active' }` } onClick={ () => handleMenuItemClick('OrganizationManagement') }>
                                                        <span className='menu-icon'>
                                                            <span className='svg-icon svg-icon-2'>
                                                                <img src="/assets/media/icons/duotune/abstract/abs038.svg" />
                                                            </span>
                                                        </span>
                                                        <span className='menu-title'>{ t('OrganizationManagement') }</span>
                                                    </span>
                                                </div>
                                            }
                                            <div className='menu-item mb-1'>
                                                <span className={ `menu-link ${ ktContentPage === 'BusinessManagement' && 'active' }` } onClick={ () => handleMenuItemClick('BusinessManagement') }>
                                                    <span className='menu-icon'>
                                                        <span className='svg-icon svg-icon-2'>
                                                            <img src="/assets/media/icons/duotune/general/gen017.svg" />
                                                        </span>
                                                    </span>
                                                    <span className='menu-title'>{ t('BusinessManagement') }</span>
                                                </span>
                                            </div>
                                            {
                                                (loggedInUser.type === 'ADMIN' || loggedInUser.type === 'OWNER') &&
                                                <div className='menu-item mb-1'>
                                                    <span className={ `menu-link ${ ktContentPage === 'UserManagement' && 'active' }` } onClick={ () => handleMenuItemClick('UserManagement') }>
                                                        <span className='menu-icon'>
                                                            <span className='svg-icon svg-icon-2'>
                                                                <img src="/assets/media/icons/duotune/communication/com014.svg" />
                                                            </span>
                                                        </span>
                                                        <span className='menu-title'>{ t('UserManagement') }</span>
                                                    </span>
                                                </div>
                                            }
                                            <div className='menu-item mb-1'>
                                                <span className={ `menu-link ${ ktContentPage === 'ChatConnectionManagement' && 'active' }` } onClick={ () => handleMenuItemClick('ChatConnectionManagement') }>
                                                    <span className='menu-icon'>
                                                        <span className='svg-icon svg-icon-2'>
                                                            <img src="/assets/media/icons/duotune/communication/com001.svg" />
                                                        </span>
                                                    </span>
                                                    <span className='menu-title'>{ t('ChatConnectionManagement') }</span>
                                                </span>
                                            </div>
                                            <div className='menu-item mb-1'>
                                                <span className={ `menu-link ${ ktContentPage === 'Chat' && 'active' }` } onClick={ () => handleMenuItemClick('Chat') }>
                                                    <span className='menu-icon'>
                                                        <span className='svg-icon svg-icon-2'>
                                                            <img src="/assets/media/icons/duotune/communication/com007.svg" />
                                                        </span>
                                                    </span>
                                                    <span className='menu-title'>{ t('Chat') }</span>
                                                </span>
                                            </div>
                                            { loggedInUser.type === 'ADMIN' &&
                                                <div className='menu-item mb-1'>
                                                    <span className={ `menu-link ${ ktContentPage === 'CommentsManagement' && 'active' }` } onClick={ () => handleMenuItemClick('CommentsManagement') }>
                                                        <span className='menu-icon'>
                                                            <span className='svg-icon svg-icon-2'>
                                                                <img src="/assets/media/icons/duotune/communication/com012.svg" />
                                                            </span>
                                                        </span>
                                                        <span className='menu-title'>{ t('CommentsManagement') }</span>
                                                    </span>
                                                </div>
                                            }
                                            { loggedInUser.type === 'ADMIN' && process.env.REACT_APP_ENVIRONMENT === 'development' &&
                                                <div className='menu-item mb-1'>
                                                    <span className={ `menu-link ${ ktContentPage === 'RAQManagement' && 'active' }` } onClick={ () => handleMenuItemClick('RAQManagement') }>
                                                        <span className='menu-icon'>
                                                            <span className='svg-icon svg-icon-2'>
                                                                <img src="/assets/media/icons/duotune/communication/com011.svg" />
                                                            </span>
                                                        </span>
                                                        <span className='menu-title'>{ t('RAQManagement') }</span>
                                                    </span>
                                                </div>
                                            }
                                            <div className='menu-item mb-1'>
                                                <span className={ `menu-link ${ ktContentPage === 'Manuals' && 'active' }` } onClick={ () => {
                                                    const win = window.open('/manuals', '_blank')
                                                    win.focus()
                                                } }>
                                                    <span className='menu-icon'>
                                                        <span className='svg-icon svg-icon-2'>
                                                            <img src="/assets/media/icons/duotune/general/gen046.svg" />
                                                        </span>
                                                    </span>
                                                    <span className='menu-title'>{ t('Manuals') }</span>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <button className='btn btn-sm btn-icon bg-body btn-color-gray-600 btn-active-primary position-absolute translate-middle start-100 end-0 bottom-0 shadow-sm d-none d-lg-flex' data-kt-toggle='true' data-kt-toggle-state='active' data-kt-toggle-target='body' data-kt-toggle-name='aside-minimize' style={ { marginBottom: '1.35rem' } }>
                <span className='svg-icon svg-icon-2 rotate-180'>
                    <svg xmlns='http://www.w3.org/2000/svg' width={ 24 } height={ 24 } viewBox='0 0 24 24' fill='none'>
                        <rect opacity='0.5' x={ 6 } y={ 11 } width={ 13 } height={ 2 } rx={ 1 } fill='black'>
                            <path d='M8.56569 11.4343L12.75 7.25C13.1642 6.83579 13.1642 6.16421 12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75L5.70711 11.2929C5.31658 11.6834 5.31658 12.3166 5.70711 12.7071L11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25C13.1642 17.8358 13.1642 17.1642 12.75 16.75L8.56569 12.5657C8.25327 12.2533 8.25327 11.7467 8.56569 11.4343Z' fill='black' /></rect></svg>
                </span>
            </button>
        </div>
    )
}

export default KTAside

import * as Yup from 'yup'

export const VideoFormValidation = (formValues) => {
    if (formValues._id) {
        return Yup.object().shape({
            business: Yup.mixed().required('ThisFieldIsRequired'),
            title: Yup.string(),
            description: Yup.string(),
            tags: Yup.mixed(),
            leadValue: Yup.string(),
            qaList: Yup.array()
                .max(10, 'MaxQuestionAndAnswer')
                .of(
                    Yup.object().shape({
                        question: Yup.string().required('ThisFieldIsRequired'),
                        answer: Yup.string().required('ThisFieldIsRequired')
                    })
                ),
            isLikeBtnVisible: Yup.boolean(),
            isCommentBtnVisible: Yup.boolean(),
            isLikeShareVisible: Yup.boolean(),
        })
    } else {
        return Yup.object().shape({
            business: Yup.mixed().required('ThisFieldIsRequired'),
            video: Yup.mixed().test((fileData, props) => {
                const validFileTypes = 'video/mp4,video/x-m4v,video/quicktime,image/png,image/svg,image/jpeg'.split(',')
                if (fileData && fileData.file) {
                    const isFileTypeValid = validFileTypes.includes(fileData.file.type)
                    if (!isFileTypeValid) {
                        return props.createError({
                            message: 'InvalidFileType'
                        })
                    }
                    return true
                }
            }).required('Required'),
            title: Yup.string(),
            description: Yup.string(),
            tags: Yup.mixed(),
            leadValue: Yup.string(),
            qaList: Yup.array()
                .max(10, 'MaxQuestionAndAnswer')
                .of(
                    Yup.object().shape({
                        question: Yup.string().required('ThisFieldIsRequired'),
                        answer: Yup.string().required('ThisFieldIsRequired')
                    })
                ),
            isLikeBtnVisible: Yup.boolean(),
            isCommentBtnVisible: Yup.boolean(),
            isLikeShareVisible: Yup.boolean(),
        })
    }
}

export const EmployeeUserValidation = (type, initialValues) => {
    if (initialValues._id) {
        if (type === 'ADMIN') {
            return Yup.object().shape({
                type: Yup.mixed().required('ThisFieldIsRequired'),
                organization: Yup.mixed().when('type', (type, schema) => {
                    if (type === 'OWNER') {
                        return schema.required('ThisFieldIsRequired')
                    }
                }),
                planType: Yup.mixed().when('type', (type, schema) => {
                    if (type === 'OWNER') {
                        return schema.required('ThisFieldIsRequired')
                    }
                }),
                firstName: Yup.string().required('ThisFieldIsRequired'),
                middleName: Yup.string(),
                lastName: Yup.string().required('ThisFieldIsRequired'),
                email: Yup.string().email().required('ThisFieldIsRequired')
            })
        } else {
            return Yup.object().shape({
                firstName: Yup.string().required('ThisFieldIsRequired'),
                middleName: Yup.string(),
                lastName: Yup.string().required('ThisFieldIsRequired'),
                email: Yup.string().email().required('ThisFieldIsRequired')
            })
        }
    } else {
        if (type === 'ADMIN') {
            return Yup.object().shape({
                type: Yup.mixed().required('ThisFieldIsRequired'),
                organization: Yup.mixed().when('type', (type, schema) => {
                    if (type === 'OWNER') {
                        return schema.required('ThisFieldIsRequired')
                    }
                }),
                planType: Yup.mixed().when('type', (type, schema) => {
                    if (type === 'OWNER') {
                        return schema.required('ThisFieldIsRequired')
                    }
                }),
                firstName: Yup.string().required('ThisFieldIsRequired'),
                middleName: Yup.string(),
                lastName: Yup.string().required('ThisFieldIsRequired'),
                email: Yup.string().email().required('ThisFieldIsRequired'),
                password: Yup.string()
                    .min(6, 'PasswordMustBeAtLeast6Characters')
                    .required('PasswordIsRequired'),
            })
        } else {
            return Yup.object().shape({
                firstName: Yup.string().required('ThisFieldIsRequired'),
                middleName: Yup.string(),
                lastName: Yup.string().required('ThisFieldIsRequired'),
                email: Yup.string().email().required('ThisFieldIsRequired'),
                password: Yup.string()
                    .min(6, 'PasswordMustBeAtLeast6Characters')
                    .required('PasswordIsRequired'),
            })
        }
    }
}

export const OrganizationValidation = () => {
    return Yup.object().shape({
        name: Yup.string().required('ThisFieldIsRequired'),
        code: Yup.string().required('ThisFieldIsRequired').matches(
            /^[a-zA-Z0-9-]+$/,
            'AlphaNumericOnly'
        )
    })
}

export const RAQValidation = () => {
    return Yup.object().shape({
        status: Yup.string().required('ThisFieldIsRequired')
    })
}

export const PageValidation = (type) => {
    if (type === 'ADMIN') {
        return Yup.object().shape({
            organization: Yup.mixed().required('ThisFieldIsRequired'),
            bodyContent: Yup.string().required('ThisFieldIsRequired'),
            title: Yup.string().required('ThisFieldIsRequired'),
            category: Yup.string().required('ThisFieldIsRequired')
        })
    } else {
        return Yup.object().shape({
            bodyContent: Yup.string().required('ThisFieldIsRequired'),
            title: Yup.string().required('ThisFieldIsRequired'),
            category: Yup.string().required('ThisFieldIsRequired')
        })
    }
}

export const ChatConnectionValidation = (type) => {
    if (type === 'ADMIN') {
        return Yup.object().shape({
            organization: Yup.mixed().required('ThisFieldIsRequired'),
            name: Yup.string().required('ThisFieldIsRequired'),
            domainURL: Yup.string().required('ThisFieldIsRequired'),
            title: Yup.string().required('ThisFieldIsRequired'),
            composeInputPlaceholder: Yup.string().required('ThisFieldIsRequired'),
            isOnline: Yup.string().required('ThisFieldIsRequired'),
        })
    } else {
        return Yup.object().shape({
            domainURL: Yup.string().required('ThisFieldIsRequired'),
            name: Yup.string().required('ThisFieldIsRequired'),
            isOnline: Yup.string().required('ThisFieldIsRequired'),
            title: Yup.string().required('ThisFieldIsRequired'),
            composeInputPlaceholder: Yup.string().required('ThisFieldIsRequired'),
        })
    }
}

export const AssetValidation = (type, initialValues) => {
    if (initialValues._id) {
        if (type === 'ADMIN') {
            return Yup.object().shape({
                organization: Yup.mixed().required('ThisFieldIsRequired'),
                name: Yup.string().required('ThisFieldIsRequired'),
                tags: Yup.mixed(),
            })
        } else {
            return Yup.object().shape({
                name: Yup.string().required('ThisFieldIsRequired'),
                tags: Yup.mixed(),
            })
        }
    } else {
        if (type === 'ADMIN') {
            return Yup.object().shape({
                organization: Yup.mixed().required('ThisFieldIsRequired'),
                names: Yup.array().of(
                    Yup.string().required('ThisFieldIsRequired')
                ),
                tags: Yup.mixed(),
            })
        } else {
            return Yup.object().shape({
                names: Yup.array().of(
                    Yup.string().required('ThisFieldIsRequired')
                ),
                tags: Yup.mixed(),
            })
        }
    }
}

export const BusinessValidation = (type) => {
    if (type === 'ADMIN') {
        return Yup.object().shape({
            organization: Yup.mixed().required('ThisFieldIsRequired'),
            name: Yup.string().required('ThisFieldIsRequired'),
            websiteUrl: Yup.string().required('ThisFieldIsRequired')
        })
    } else {
        return Yup.object().shape({
            name: Yup.string().required('ThisFieldIsRequired'),
            websiteUrl: Yup.string().required('ThisFieldIsRequired')
        })
    }
}

export const CommentValidation = () => {
    return Yup.object().shape({
        text: Yup.string().required('ThisFieldIsRequired')
    })
}

export const RegisterValidation = () => {
    return Yup.object().shape({
        email: Yup.string()
            .email()
            .required('Required'),
        password: Yup.string()
            .min(6, 'PasswordMustBeAtLeast6Characters')
            .required('PasswordIsRequired'),
        organization: Yup.string().required('ThisFieldIsRequired'),
        firstName: Yup.string().required('ThisFieldIsRequired'),
        middleName: Yup.string(),
        lastName: Yup.string().required('ThisFieldIsRequired'),
        confirmPassword: Yup.string()
            .oneOf([Yup.ref('password'), null], 'PasswordMustMatch')
    })
}

export const ChangePasswordValidation = () => {
    return Yup.object().shape({
        oldPassword: Yup.string()
            .min(6, 'PasswordMustBeAtLeast6Characters')
            .required('PasswordIsRequired'),
        newPassword: Yup.string()
            .min(6, 'PasswordMustBeAtLeast6Characters')
            .required('PasswordIsRequired'),
        confirmPassword: Yup.string()
            .oneOf([Yup.ref('newPassword'), null], 'PasswordMustMatch')
    })
}

export const ForgotPasswordValidation = () => {
    return Yup.object().shape({
        email: Yup.string()
            .email()
            .required('Required')
    })
}

export const ResetNewPasswordValidation = () => {
    return Yup.object().shape({
        password: Yup.string()
            .min(6)
            .required('Required'),
        confirmPassword: Yup.string()
            .oneOf([Yup.ref('password'), null], 'PasswordMustMatch')
    })
}
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { FORM_INITIAL_VALUES } from '../../utils/constants/formValues'
import useBusiness from '../../utils/hooks/useBusiness'
import useOrganizations from '../../utils/hooks/useOrganizations'
import { getObjectId } from '../../utils/transformer/Object'
import { ObjectToSelect } from '../../utils/transformer/Select'
import BusinessForm from './BusinessForm'
import BusinessTable from './BusinessTable'

function Business() {
    const { t } = useTranslation()
    const loggedInUser = useSelector(state => state.authData.loggedInUser)
    const [organizationOptions, setOrganizationOptions] = useState(null)
    const [params, setParams] = useState({
        page: 1,
        limit: 10,
        organization: loggedInUser.organization
    })
    const [data, setData] = useState(FORM_INITIAL_VALUES.BUSINESS)
    const [datas, setDatas] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const { getOrganizationByParams } = useOrganizations()
    const { getBusinessByParams, createBusiness, updateBusiness } = useBusiness()
    const modalCloseBtnRef = useRef()

    useEffect(() => {
        if (loggedInUser && loggedInUser.type === 'ADMIN') {
            getOrganizations()
        }
    }, [])

    useEffect(() => {
        getData()
    }, [params])

    const getOrganizations = async () => {
        const response = await getOrganizationByParams({
            page: 1,
            limit: 20
        })
        const options = response.data.docs.map(doc => ObjectToSelect(doc.name, doc))
        setOrganizationOptions(options)
    }

    const getData = async () => {
        setIsLoading(true)
        const response = await getBusinessByParams(params)
        setDatas(response.data)
        setIsLoading(false)
    }

    const getSpecificPageData = (page) => {
        setParams(oldParams => {
            return {
                ...oldParams,
                page: page.selected + 1
            }
        })
    }

    const handleCreateUpdateData = async (formData, formikProps) => {
        const data = {
            ...formData,
            name: formData.name,
            organization: loggedInUser.type !== 'ADMIN' ? loggedInUser.organization : getObjectId(formData.organization.value),
            websiteUrl: formData.websiteUrl,
            owner: loggedInUser.type !== 'ADMIN' ? loggedInUser._id : formData.organization.value.owner._id
        }
        const response = formData._id ? await updateBusiness(data) : await createBusiness(data)
        if (response.data._id) {
            formikProps.resetForm()
            modalCloseBtnRef.current.click()
        }
        getData()
        return response
    }

    const handleSetData = (data) => {
        let tempData = { ...data }
        tempData.organization = ObjectToSelect(tempData.organization.name, tempData.organization)
        setData(tempData)
    }

    return (
        <div id="kt_content" className="content d-flex flex-column flex-column-fluid">
            <div id="kt_content_container" className="container-xxl">
                <div className="card">
                    <div className="card-header border-0 pt-6">
                        <div className="card-title">
                        </div>
                        <div className="card-toolbar">
                            <div className="d-flex justify-content-end" data-kt-user-table-toolbar="base">
                                <button className="btn btn-primary" type="button" onClick={ () => setData(FORM_INITIAL_VALUES.BUSINESS) } data-bs-toggle="modal" data-bs-target="#kt_modal_add_business">{ t('AddBusiness') }</button>
                            </div>
                            <div id="kt_modal_add_business" className="modal fade" tabIndex={ -1 }>
                                <div className="modal-dialog modal-dialog-centered mw-650px">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h2 className="fw-bolder">{ `${ data._id ? t('UpdateBusiness') : t('CreateBusiness') }` }</h2>
                                            <div className="btn btn-icon btn-sm btn-active-icon-primary" ref={ modalCloseBtnRef } data-bs-dismiss="modal" data-kt-users-modal-action="close">
                                                <span className="svg-icon svg-icon-1">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                        <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="black"></rect>
                                                        <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="black"></rect>
                                                    </svg>
                                                </span>
                                            </div>
                                        </div>
                                        <div className="modal-body scroll-y mx-5 mx-xl-15 my-7">
                                            <BusinessForm initialValues={ data } handleSubmit={ handleCreateUpdateData } organizationOptions={ organizationOptions } />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card-body pt-0">
                        <BusinessTable
                            datas={ datas }
                            handleSetData={ handleSetData }
                            getSpecificPageData={ getSpecificPageData }
                            params={ params }
                            isLoading={ isLoading }
                        />
                    </div>
                </div>
            </div>
        </div >
    )
}

export default Business

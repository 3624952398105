import axios from 'axios'
import { ENDPOINTS } from '../constants/endpoint'
import { toast } from '../toast/toast'

function useVideos() {
    const getVideosByParams = async (params) => {
        return axios.get(process.env.REACT_APP_API_URL + ENDPOINTS.GET_VIDEOS + '/?layout.ratio=9:16', {
            params: params
        }).catch(error => toast('error', '', error.response.data.message))
    }

    const getVideosLandscapeByParams = async (params) => {
        return axios.get(process.env.REACT_APP_API_URL + ENDPOINTS.GET_VIDEOS + '/?layout.ratio=16:9', {
            params: params
        }).catch(error => toast('error', '', error.response.data.message))
    }

    const getVideosById = async (id) => {
        return axios.get(process.env.REACT_APP_API_URL + ENDPOINTS.GET_VIDEO + id).catch(error => toast('error', '', error.response.data.message))
    }

    const uploadVideo = async (videoData) => {
        return axios.post(process.env.REACT_APP_API_URL + ENDPOINTS.UPLOAD_VIDEO, videoData).catch(error => toast('error', '', error.response.data.message))
    }

    const updateVideo = async (videoData) => {
        return axios.put(process.env.REACT_APP_API_URL + ENDPOINTS.UPLOAD_VIDEO + '/' + videoData._id, videoData).catch(error => toast('error', '', error.response.data.message))
    }

    const deleteVideo = async (id) => {
        return axios.delete(process.env.REACT_APP_API_URL + ENDPOINTS.UPLOAD_VIDEO + '/' + id).catch(error => toast('error', '', error.response.data.message))
    }

    return { getVideosById, getVideosByParams, getVideosLandscapeByParams, uploadVideo, updateVideo, deleteVideo }
}

export default useVideos

import React, { useEffect, useState } from 'react'
import S3FileUpload from 'react-s3'
import { v4 as uuidv4 } from 'uuid'
import Select from 'react-select'
import useAssets from '../../utils/hooks/useAssets'
import { FORM_INITIAL_VALUES } from '../../utils/constants/formValues'
import { ArrayStringToSelectMulti, ObjectToSelect, SelectMultiToArrayString } from '../../utils/transformer/Select'
import { getObjectId } from '../../utils/transformer/Object'

import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { selectStyles } from '../custom/FieldsStyles'
import useOrganizations from '../../utils/hooks/useOrganizations'
import AssetForm from './AssetForm'
import AssetTable from './AssetTable'

function Assets() {
    const { t } = useTranslation()
    const loggedInUser = useSelector(state => state.authData.loggedInUser)
    const [activeOrganization, setActiveOrganization] = useState(null)
    const [data, setData] = useState(FORM_INITIAL_VALUES.PAGE)
    const [organizationOptions, setOrganizationOptions] = useState([])
    const [datas, setDatas] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const [view, setView] = useState('table')
    const [params, setParams] = useState({
        page: 1,
        limit: 10,
        sort: 'createdAt:desc',
        organization: loggedInUser.organization,
    })
    const [searchByTagText, setSearchByTagText] = useState('')
    const [searchByNameText, setSearchByNameText] = useState('')
    const { getAssetsByParams, createAsset, updateAsset, deleteAsset } = useAssets()
    const { getOrganizationByParams } = useOrganizations()
    const noRegisteredOrganizations = organizationOptions.length <= 0

    useEffect(() => {
        getOrganizationData()
    }, [])

    useEffect(() => {
        getData()
    }, [params])

    const getData = async () => {
        setIsLoading(true)
        const response = await getAssetsByParams(params)
        setDatas(response.data)
        setIsLoading(false)
    }

    const getSpecificPageData = (asset) => {
        setParams(oldParams => {
            return {
                ...oldParams,
                asset: asset.selected + 1
            }
        })
    }

    const getOrganizationData = async () => {
        const response = await getOrganizationByParams({
            asset: 1,
            limit: 100
        })
        if (response.data && response.data.docs.length > 0) {
            const options = response.data.docs.map(doc => ObjectToSelect(`${ doc.name }`, doc))
            setOrganizationOptions(options)
        }
    }

    const handleDelete = async (id, formikProps) => {
        await deleteAsset(id)
        formikProps.resetForm()
        setView('table')
        getData()
    }

    const handleUpload = async (formValues, formikProps) => {
        const organizationId = loggedInUser.type !== 'ADMIN' ? loggedInUser.organization : getObjectId(formValues.organization.value)
        if (formValues._id) {
            const data = {
                ...formValues,
                organization: organizationId,
                user: getObjectId(formValues.user),
                tags: formValues.tags ? SelectMultiToArrayString(formValues.tags) : []
            }
            const uploadResponse = await updateAsset(data)
            if (uploadResponse.data._id) {
                setView('table')
                formikProps.resetForm()
                getData()
            }
        } else {
            const date = new Date()
            const year = date.getFullYear()
            const month = date.getMonth() + 1
            return await Promise.all(formValues.files.map(async (data, index) => {
                const extension = data.file.name.split('.').pop()
                const fileName = formValues.names[index] + '.' + extension
                const blob = data.file.slice(0, data.file.size, data.file.type)
                const newFile = new File([blob], fileName, { type: data.file.type })
                const config = {
                    bucketName: process.env.REACT_APP_ENVIRONMENT === 'development' ? 'raylee-test' : 'raylee-production',
                    dirName: `assets/${ organizationId }/${ year }/${ month }/${ uuidv4() }`,
                    region: 'ap-northeast-1',
                    accessKeyId: 'AKIA4FUMDWP4KTKAPNVI',
                    secretAccessKey: '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',
                }
                return await S3FileUpload.uploadFile(newFile, config).then(async response => {
                    if (response.result.ok) {
                        const requestBody = {
                            name: fileName,
                            key: response.key,
                            organization: organizationId,
                            user: loggedInUser._id,
                            size: data.file.size,
                            type: data.file.type,
                            tags: formValues.tags[index] ? SelectMultiToArrayString(formValues.tags[index]) : []
                        }
                        const uploadResponse = await createAsset(requestBody)
                        if (uploadResponse.data._id) {
                            setView('table')
                            formikProps.resetForm()
                            getData()
                        }
                        return uploadResponse
                    }
                })
            }))
        }
    }

    const handleSetData = (data) => {
        let tempData = { ...data }
        tempData.organization = ObjectToSelect(tempData.organization?.name, tempData.organization)
        tempData.tags = ArrayStringToSelectMulti(tempData.tags)
        setData(tempData)
        setView('form')
    }

    const openForm = () => {
        let newData = { ...FORM_INITIAL_VALUES.ASSET }
        if (activeOrganization) {
            newData.organization = activeOrganization
        }
        setView('form')
        setData(newData)
    }

    const cancelForm = () => {
        setView('table')
        setData(FORM_INITIAL_VALUES.ASSET)
    }

    return (
        <div id='kt_content' className='content d-flex flex-column flex-column-fluid'>
            <div id='kt_content_container' className='container-xxl'>
                <div className='card'>
                    {
                        view === 'table' &&
                        <div className='card-header border-0 pt-6'>
                            <div className='card-title w-75'>
                                <div className='d-flex align-items-center position-relative w-100 my-1'>
                                    {
                                        loggedInUser.type === 'ADMIN' &&
                                        <Select
                                            styles={ selectStyles }
                                            className={ 'form-control py-1 form-control-solid' }
                                            onChange={ data => {
                                                setActiveOrganization(data)
                                                setParams(state => ({
                                                    ...state,
                                                    asset: 1,
                                                    organization: data?.value?._id
                                                }))
                                            } }
                                            placeholder={ t('SelectOrganization') }
                                            value={ activeOrganization }
                                            options={ organizationOptions }
                                            isClearable={ true }
                                            isSearchable={ true }
                                        />
                                    }
                                    <div className='fv-row fv-plugins-icon-container w-50 mx-2'>
                                        <input
                                            className={ 'form-control form-control-solid' }
                                            autoComplete='off'
                                            placeholder={ t('SearchByTag') }
                                            type={ 'text' }
                                            onChange={ (e) => setSearchByTagText(e.target.value) }
                                        />
                                    </div>
                                    <div className='fv-row fv-plugins-icon-container w-50 mx-2'>
                                        <input
                                            className={ 'form-control form-control-solid' }
                                            autoComplete='off'
                                            placeholder={ t('SearchByName') }
                                            type={ 'text' }
                                            onChange={ (e) => setSearchByNameText(e.target.value) }
                                        />
                                    </div>
                                    <button
                                        className='btn btn-primary'
                                        style={ { whiteSpace: 'nowrap' } }
                                        type='button'
                                        onClick={ () => {
                                            setParams(state => ({
                                                ...state,
                                                tags: searchByTagText ? [searchByTagText] : null,
                                                name: searchByNameText ? searchByNameText : null
                                            }))
                                        } }
                                    >
                                        { t('Search') }
                                    </button>
                                </div>
                            </div>
                            <div className='card-toolbar'>
                                <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
                                    <button className='btn btn-primary' type='button' disabled={ noRegisteredOrganizations } onClick={ openForm }>{ t('AddAsset') }</button>
                                </div>
                            </div >
                        </div >
                    }
                    {
                        view === 'form' &&
                        <>
                            <div className='card-header border-0 pt-6'>
                                <div className='card-title w-50'>
                                    <div className='d-flex align-items-center position-relative my-1 w-100'>
                                        <h3>{ t(data._id ? 'UpdateAsset' : 'CreateAsset') }</h3>
                                    </div>
                                </div>
                            </div >
                            <div className='card-body pt-0'>
                                <AssetForm
                                    initialValues={ data }
                                    handleSubmit={ handleUpload }
                                    handleDelete={ handleDelete }
                                    organizationOptions={ organizationOptions }
                                    cancelForm={ cancelForm }
                                />
                            </div>
                        </>
                    }
                    {
                        view === 'table' &&
                        <div className='card-body pt-0'>
                            <AssetTable
                                datas={ datas }
                                handleSetData={ handleSetData }
                                params={ params }
                                getSpecificPageData={ getSpecificPageData }
                                isLoading={ isLoading }
                            />
                        </div>
                    }
                </div >
            </div >
        </div >
    )
}

export default Assets

export const ENDPOINTS = {
    ACTIVATE_ACCOUNT: '/auth/activate',
    AUTH: '/auth',
    ASSETS: '/assets',
    CHAT_CONNECTION: '/chat-connections',
    CONVERSATIONS: '/conversations',
    CREATE_BUSINESS: '/businesses',
    CREATE_ORGANIZATION: '/organizations',
    CREATE_USER: '/auth/register',
    COMMENTS: '/comments',
    CHANGE_PASSWORD: '/auth/change-password',
    FORGOT_PASSWORD: '/auth/forgot-password',
    GET_BUSINESS: '/businesses/query',
    GET_BUSINESS_CLUSTERS: '/organizations/query',
    GET_COMMENTS: '/comments/query',
    GET_USERS: '/users/query',
    GET_VIDEOS: '/videos/query',
    GET_VIDEO: '/videos/',
    GET_POPUPS: '/popups',
    GET_POPUPS_QUERY: '/popups/query',
    LOGIN: '/auth/login',
    MESSAGES: '/messages',
    PAGES: '/pages',
    POPUPS_ACTIVE: '/popups/active',
    POPUPS_INACTIVE: '/popups/inactive',
    RAQS: '/raqs',
    RAQS_QUERY: '/raqs/query',
    REGISTER: '/auth/register',
    UPLOAD_VIDEO: '/videos',
    UPLOAD_POPUP: '/popups',
    UPLOAD_POPUP_VIDEO: '/popups/video',
    UPLOAD_POPUP_IMAGE: '/popups/image',
    USERS: '/users',
    RESET_PASSWORD: '/auth/reset-password',
    RESEND_ACTIVATION: '/auth/resend-activation',
    QUERY: '/query'
}
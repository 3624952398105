import {
    LOGIN_LOADING,
    LOGIN_SUCCESS,
    LOGIN_FAILED,
    LOGOUT
} from './actionTypes'

const initialState = {
    isAuthenticated: false,
    loggedInUser: null,
    token: '',
    loginIsLoading: false,
    loginHaserror: false,
}

export default function reducer(state = initialState, action) {
    switch (action.type) {
    case LOGIN_LOADING:
        return {
            ...state,
            loginIsLoading: true,
            isAuthenticated: false,
            token: false,
        }
    case LOGIN_SUCCESS:
        return {
            ...state,
            loggedInUser: action.payload.user,
            token: action.payload.accessToken,
            loginUserHasError: false,
            loginIsLoading: false,
            isAuthenticated: true,
        }
    case LOGIN_FAILED:
        return {
            ...state,
            isAuthenticated: false,
            loginIsLoading: false,
            loggedInUser: null,
            loginHaserror: true,
            token: ''
        }
    case LOGOUT:
        return {
            ...state,
            isAuthenticated: false,
            loggedInUser: null,
            token: '',
            loginIsLoading: false,
            loginHaserror: false,
        }
    default:
        return state
    }
}
import React from 'react'
import { Field, Form, Formik } from 'formik'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import CopyToClipboard from 'react-copy-to-clipboard'
import Fields from '../custom/Fields'
import { ChatConnectionValidation } from '../../utils/validations/formValidations'
import { toast } from '../../utils/toast/toast'


function ChatConnectionForm(props) {
    const loggedInUser = useSelector(state => state.authData.loggedInUser)
    const { t } = useTranslation()
    const { initialValues, handleSubmit, handleDelete, organizationOptions, cancelForm } = props
    const initSetup = `
    <script src='https://cdn.socket.io/4.5.0/socket.io.min.js' integrity='sha384-7EyYLQZgWBi67fBtVxw60/OWl1kjsfrPFcaU0pp0nAh+i8FD068QogUvg85Ewy1k' crossorigin='anonymous'></script>
    <script src='${ process.env.REACT_APP_RAYLEE_CLIENT_BASE_URL }/${ process.env.REACT_APP_ENVIRONMENT === 'development' ? 'raylee-init-chat-test.js' : 'raylee-init-chat.js' }'></script>
    <script>
        window.rayleeChatConnection = '${ initialValues?._id }';
        window.organization = '${ initialValues?.organization?.value?._id }';
    </script>
    `

    const deleteData = async (formikProps) => {
        window.Swal.fire({
            text: t('AreYouSureYouWantToDeleteThisChatConnection'),
            icon: 'warning',
            buttonsStyling: false,
            showCancelButton: true,
            confirmButtonText: t('OkGotIt'),
            cancelButtonText: t('Cancel'),
            customClass: {
                confirmButton: 'btn btn-danger',
                cancelButton: 'btn btn-primary'
            }
        }).then(async response => {
            if (response.isConfirmed) {
                formikProps.setSubmitting(true)
                await handleDelete(initialValues._id, formikProps)
                formikProps.setSubmitting(false)
            }
        })
    }

    return (
        <Formik
            initialValues={ initialValues }
            enableReinitialize
            validationSchema={ ChatConnectionValidation(loggedInUser.type) }
            onSubmit={ async (fields, { ...formikProps }) => {
                return await handleSubmit(fields, formikProps)
            } }
        >
            { ({ isSubmitting, ...formikProps }) => (
                <Form className='form col-11 fv-plugins-bootstrap5 fv-plugins-framework'>
                    <div className='d-flex flex-column scroll-y me-n7 px-7' style={ { maxHeight: '637px' } } data-kt-scroll='true' data-kt-scroll-activate='{default: false, lg: true}' data-kt-scroll-max-height='auto' data-kt-scroll-offset='300px'>
                        {
                            initialValues._id &&
                            <div className='fv-row mb-7'>
                                <pre className='form-label fw-bolder text-dark'>
                                    { `${ initSetup }` }
                                </pre>
                                <CopyToClipboard text={ initSetup } onCopy={ () => toast('info', '', 'Copied!') }>
                                    <button className='btn btn-light' type='button'>
                                        Copy
                                        <img src='assets/media/icons/duotune/communication/com008.svg' />
                                    </button>
                                </CopyToClipboard>
                                <span className='d-block mt-3'>{ t('NotePutTheCodeInsideTheTag') }</span>
                            </div>
                        }
                        <div className='d-flex row'>
                            <div className='col-12'>
                                {
                                    loggedInUser.type === 'ADMIN' &&
                                    <div className='fv-row mb-7 fv-plugins-icon-container'>
                                        <Field
                                            { ...formikProps }
                                            type='select'
                                            placeholder={ t('Organization') }
                                            label={ t('Organization') }
                                            name='organization'
                                            as={ Fields }
                                            options={ organizationOptions }
                                            isDisabled={ initialValues._id }
                                        />
                                    </div>
                                }
                                <div className='fv-row mb-7 fv-plugins-icon-container'>
                                    <Field { ...formikProps } type='text' placeholder={ t('Name') } label={ t('Name') } name='name' as={ Fields } />
                                </div>
                                <div className='fv-row mb-7 fv-plugins-icon-container'>
                                    <Field { ...formikProps } type='text' placeholder={ t('URL') } label={ t('URL') } name='domainURL' as={ Fields } />
                                </div>
                                <div className='fv-row mb-7 fv-plugins-icon-container'>
                                    <Field { ...formikProps } type='text' placeholder={ t('Title') } label={ t('Title') } name='title' as={ Fields } />
                                </div>
                                <div className='fv-row mb-7 fv-plugins-icon-container'>
                                    <Field { ...formikProps } type='text' placeholder={ t('InputPlaceholder') } label={ t('InputPlaceholder') } name='composeInputPlaceholder' as={ Fields } />
                                </div>
                                <div className='fv-row mb-7 fv-plugins-icon-container'>
                                    <Field { ...formikProps } type='checkbox' placeholder={ t('IsOnline') } label={ t('IsOnline') } name='isOnline' as={ Fields } />
                                </div>
                                <div className='fv-row mb-7 fv-plugins-icon-container'>
                                    <Field { ...formikProps } type='tag' placeholder={ t('EmailRecipients') } label={ t('EmailRecipients') } name='emailRecipients' as={ Fields } />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='text-end pt-15'>
                        <button className='btn btn-light me-3 ' disabled={ isSubmitting } type='reset' data-bs-dismiss='modal' data-kt-users-modal-action='close' onClick={ cancelForm }>{ t('Cancel') }</button>
                        { initialValues._id &&
                            <button className='btn btn-danger mx-3' disabled={ isSubmitting } type='button' onClick={ () => deleteData(formikProps) } data-kt-indicator={ `${ isSubmitting ? 'on' : 'off' }` }>
                                <span className='indicator-label'>{ t('Delete') }</span> <span className='indicator-progress'>
                                    { t('PleaseWait') }
                                    <span className='spinner-border spinner-border-sm align-middle ms-2' />
                                </span>
                            </button>

                        }
                        <button className='btn btn-primary' disabled={ isSubmitting } type='submit' data-kt-indicator={ `${ isSubmitting ? 'on' : 'off' }` }>
                            <span className='indicator-label'>{ initialValues._id ? t('Update') : t('Create') }</span> <span className='indicator-progress'>
                                { t('PleaseWait') }
                                <span className='spinner-border spinner-border-sm align-middle ms-2' />
                            </span>
                        </button>
                    </div>
                </Form>
            ) }
        </Formik>
    )
}

export default ChatConnectionForm

import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Chart from 'react-apexcharts'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router'
import useVideos from '../../utils/hooks/useVideos'
import { constructLineChart, constructAreaChart } from '../../utils/transformer/Chart'
import KTAside from '../custom/KTAside'
import useQuery from '../../utils/hooks/useQuery'
import usePopups from '../../utils/hooks/usePopups'
import usePages from '../../utils/hooks/usePages'

function Analysis() {
    const params = useParams()
    const query = useQuery()
    const [data, setData] = useState(null)
    const [type, setType] = useState(null)
    const [viewSelectedReport, setViewSelectedReport] = useState(null)
    const [viewReportData, setViewReportData] = useState(null)
    const [leadSelectedReport, setLeadSelectedReport] = useState(null)
    const [leadReportData, setLeadReportData] = useState(null)
    const [durationSelectedReport, setDurationSelectedReport] = useState(null)
    const [durationReportData, setDurationReportData] = useState(null)
    const { getVideosById } = useVideos()
    const { getPopupById } = usePopups()
    const { getPagesById } = usePages()
    const { t } = useTranslation()
    const ktContentPage = useSelector(state => state.appData.ktContentPage)

    useEffect(() => {
        const queryType = query.get('type')
        if (queryType) {
            setType(queryType)
        }
        if (params.id) {
            getData(params?.id)
        }
    }, [])

    useEffect(() => {
        if (data && data.views) {
            const dailyOptions = constructAreaChart(
                data.views.daily.map(data => data.count)?.filter(data => data != undefined),
                'Number of views',
                data.views.daily.map(data => data.count > 0 ? data.date : null).filter(data => data != null),
                '',
                ' Views',
                '#E91E63'
            )
            const weeklyOptions = constructAreaChart(
                data.views.weekly.map(data => data.count)?.filter(data => data != undefined),
                'Number of views',
                data.views.weekly.map(data => data.week),
                '',
                ' Views',
                '#E91E63'
            )
            const monthlyOptions = constructAreaChart(
                data.views.monthly.map(data => data.count),
                'Number of views',
                data.views.monthly.map(data => data.monthName),
                '',
                ' Views',
                '#E91E63'
            )
            setViewReportData({
                daily: dailyOptions,
                weekly: weeklyOptions,
                monthly: monthlyOptions
            })
        }
    }, [viewSelectedReport])

    useEffect(() => {
        if (data && data.leads) {
            const dailyOptions = constructAreaChart(
                data.leads.daily.map(data => data.count)?.filter(data => data != undefined),
                'Number of clicks',
                data.leads.daily.map(data => data.date),
                '',
                ' Clicked',
                '#85C1E9'
            )
            const weeklyOptions = constructAreaChart(
                data.leads.weekly.map(data => data.count)?.filter(data => data != undefined),
                'Number of clicks',
                data.leads.weekly.map(data => data.week),
                '',
                ' Clicked',
                '#85C1E9'
            )
            const monthlyOptions = constructAreaChart(
                data.leads.monthly.map(data => data.count)?.filter(data => data != undefined),
                'Number of clicks',
                data.leads.monthly.map(data => data.monthName),
                '',
                ' Clicked',
                '#85C1E9'
            )
            setLeadReportData({
                daily: dailyOptions,
                weekly: weeklyOptions,
                monthly: monthlyOptions
            })
        }
    }, [leadSelectedReport])

    useEffect(() => {
        if (data && data.views && ((type === 'video' || type === 'popup'))) {
            const dailyOptions = constructLineChart(
                [
                    {
                        name: t('OverallWatchTime'),
                        data: data.views.daily.map(data => data.duration?.overall)
                    },
                    {
                        name: t('AverageWatchTime'),
                        data: data.views.daily.map(data => parseInt(data.duration?.average))
                    },
                    {
                        name: t('CompletedVideoCount'),
                        data: data.views.daily.map(data => data.duration?.finishedCount)
                    }
                ],
                'Daily',
                data.views.daily.map(data => data.date),
                '',
                ' Views',
                '#BB8FCE'
            )
            const weeklyOptions = constructLineChart(
                [
                    {
                        name: t('OverallWatchTime'),
                        data: data.views.weekly.map(data => data.duration?.overall || 0)
                    },
                    {
                        name: t('AverageWatchTime'),
                        data: data.views.weekly.map(data => parseInt(data.duration?.average).toFixed(0) || 0)
                    },
                    {
                        name: t('NumberOfFinishedWatch'),
                        data: data.views.weekly.map(data => data.duration?.finishedCount || 0)
                    }
                ],
                'Weekly',
                data.views.weekly.map(data => data.week),
                '',
                ' Views',
                '#BB8FCE'
            )
            const monthlyOptions = constructLineChart(
                [
                    {
                        name: t('OverallWatchTime'),
                        data: data.views.monthly.map(data => data.duration?.overall || 0)?.filter(data => data != 0)
                    },
                    {
                        name: t('AverageWatchTime'),
                        data: data.views.monthly.map(data => parseInt(data.duration?.average).toFixed(0) || 0)?.filter(data => data != 0)
                    },
                    {
                        name: t('NumberOfFinishedWatch'),
                        data: data.views.monthly.map(data => data.duration?.finishedCount || 0)?.filter(data => data != 0)
                    }
                ],
                'Monthly',
                data.views.monthly.map(data => data.monthName),
                '',
                ' Views',
                '#BB8FCE'
            )
            setDurationReportData({
                daily: dailyOptions,
                weekly: weeklyOptions,
                monthly: monthlyOptions
            })
        } else if (data && data.views && type === 'page') {
            const dailyOptions = constructLineChart(
                [
                    {
                        name: t('OverallDurationTime'),
                        data: data.views.daily.map(data => data.duration?.overall)
                    },
                    {
                        name: t('AverageDurationTime'),
                        data: data.views.daily.map(data => parseInt(data.duration?.average))
                    },
                ],
                'Daily',
                data.views.daily.map(data => data.date),
                '',
                ' Views',
                '#BB8FCE'
            )
            const weeklyOptions = constructLineChart(
                [
                    {
                        name: t('OverallDurationTime'),
                        data: data.views.weekly.map(data => data.duration?.overall || 0)
                    },
                    {
                        name: t('AverageDurationTime'),
                        data: data.views.weekly.map(data => parseInt(data.duration?.average).toFixed(0) || 0)
                    }
                ],
                'Weekly',
                data.views.weekly.map(data => data.week),
                '',
                ' Views',
                '#BB8FCE'
            )
            const monthlyOptions = constructLineChart(
                [
                    {
                        name: t('OverallDurationTime'),
                        data: data.views.monthly.map(data => data.duration?.overall || 0)?.filter(data => data != 0)
                    },
                    {
                        name: t('AverageDurationTime'),
                        data: data.views.monthly.map(data => parseInt(data.duration?.average).toFixed(0) || 0)?.filter(data => data != 0)
                    }
                ],
                'Monthly',
                data.views.monthly.map(data => data.monthName),
                '',
                ' Views',
                '#BB8FCE'
            )
            setDurationReportData({
                daily: dailyOptions,
                weekly: weeklyOptions,
                monthly: monthlyOptions
            })
        }
    }, [durationSelectedReport])

    const getData = async (id) => {
        const dataType = query.get('type')
        const response = dataType === 'video' ? await getVideosById(id) : dataType === 'popup' ? await getPopupById(id) : await getPagesById(id)
        if (response?.data) {
            setData(response.data)
            setViewSelectedReport('Daily')
            setLeadSelectedReport('Daily')
            setDurationSelectedReport('Daily')
        }
    }

    if (data === null) {
        return (
            <div className='w-100 vh-100 d-flex align-items-center justify-content-center'>
                <div className='spinner-border' style={ { width: '3rem', height: '3rem' } } role='status'>
                    <span className='visually-hidden'>Loading...</span>
                </div>
            </div>
        )
    } else {
        return (
            <>
                <div className='d-flex flex-column flex-root'>
                    <div className='page d-flex flex-row flex-column-fluid'>
                        <KTAside />
                        <div className='wrapper d-flex flex-column flex-row-fluid' id='kt_wrapper'>
                            <div id='kt_header' className='header' data-kt-sticky='true' data-kt-sticky-name='header' data-kt-sticky-offset="{default: '200px', lg: '300px'}">
                                <div className='container-xxl d-flex align-items-center justify-content-between' id='kt_header_container'>
                                    <div className='page-title d-flex flex-column align-items-start justify-content-center flex-wrap me-lg-2 pb-5 pb-lg-0' data-kt-swapper='true' data-kt-swapper-mode='prepend' data-kt-swapper-parent="{default: '#kt_content_container', lg: '#kt_header_container'}">
                                        <h1 className='text-dark fw-bold my-0 fs-2'>{ t(ktContentPage) }</h1>
                                    </div>
                                    <div className='d-flex d-lg-none align-items-center ms-n2 me-2'>
                                        <div className='btn btn-icon btn-active-icon-primary' id='kt_aside_toggle'>
                                            <span className='svg-icon svg-icon-2x'>
                                                <img src={ process.env.PUBLIC_URL + '/assets/media/icons/duotune/abstract/abs015.svg' } />
                                            </span>
                                        </div>
                                        <a href='/' className='d-flex align-items-center'>
                                            <img alt='Logo' src='https://raylee-test.s3.ap-northeast-1.amazonaws.com/assets/icons/Raylee+Logo.svg' className='h-40px' />
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div id='kt_content' className='content d-flex flex-column flex-column-fluid'>
                                <div id='kt_content_container' className='container-xxl'>
                                    <div className='card'>
                                        <div className='card-header border-0 pt-6'>
                                            <div className='card-title w-50'>
                                                <h3>{ t('Analysis') }</h3>
                                            </div>
                                        </div>
                                        <div className='card-body pt-0'>
                                            <div className='row'>
                                                {
                                                    type === 'video' || type === 'popup' ?
                                                        <div className='col'>
                                                            {
                                                                data?.fileType === 'image' ?
                                                                    <img src={ process.env.REACT_APP_AWS_CLOUDFRONT_DOMAIN + '/' + data.key } className='w-100 mb-4' style={ { maxHeight: '640px' } } />
                                                                    :
                                                                    <video src={ process.env.REACT_APP_AWS_CLOUDFRONT_DOMAIN + '/' + data.key } className='w-100 mb-4' controls style={ { maxHeight: '640px' } } />
                                                            }
                                                        </div> : <span></span>
                                                }
                                                <div className='col'>
                                                    <div className='mb-5 mb-xl-10'>
                                                        <div className='cursor-pointer'>
                                                            <div className='card-title m-0'>
                                                                <h3 className='fw-bolder m-0'>{ t('Details') }</h3>
                                                            </div>
                                                        </div>
                                                        <div className='card-body p-9'>
                                                            <div className='row mb-7'>
                                                                <label className='col-lg-4 fw-bold text-muted'>{ t('Title') }</label>
                                                                <div className='col-lg-8'>
                                                                    <span className='fw-bolder fs-6 text-gray-800'>{ data.title }</span>
                                                                </div>
                                                            </div>
                                                            {
                                                                type === 'video' || type === 'popup' ? 
                                                                    <div className='row mb-7'>
                                                                        <label className='col-lg-4 fw-bold text-muted'>{ t('Description') }</label>
                                                                        <div className='col-lg-8 fv-row'>
                                                                            <span className='fw-bold fs-6 text-gray-800'>{ data.description }</span>
                                                                        </div>
                                                                    </div> : <span></span>
                                                            }
                                                            {
                                                                type === 'video' || type === 'popup' ? 
                                                                    <span>
                                                                        <div className='row mb-7'>
                                                                            <label className='col-lg-4 fw-bold text-muted'>{ t('Tags') }</label>
                                                                            <div className='col-lg-8 fv-row'>
                                                                                { data.tags?.map(tag => (
                                                                                    <span key={ tag } className='fw-bold fs-6 text-gray-800'>{ tag }</span>
                                                                                )) }
                                                                            </div>
                                                                        </div>
                                                                        <div className='row mb-7'>
                                                                            <label className='col-lg-4 fw-bold text-muted'>{ t('CTA') }</label>
                                                                            <div className='col-lg-8 fv-row'>
                                                                                <span className='fw-bold fs-6 text-gray-800'>{ data.leadValue }</span>
                                                                            </div>
                                                                        </div>
                                                                    </span> : <span></span>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col d-flex justify-content-end align-items-center'>
                                                    <label className='fw-bold text-muted'>{ t('SelectReport') }</label>
                                                </div>
                                                <div className='col'>
                                                    <select className='form-select' data-control='select2' data-placeholder='Select an option' onChange={ data => setViewSelectedReport(data.target.value) }>
                                                        <option value='Daily'>{ t('Daily') }</option>
                                                        <option value='Weekly'>{ t('Weekly') }</option>
                                                        <option value='Monthly'>{ t('Monthly') }</option>
                                                    </select>
                                                </div>
                                            </div>
                                            {
                                                type === 'video' || type === 'popup' ? 
                                                    <h3>{ t(`${ viewSelectedReport }ViewsReport`) }</h3> :
                                                    <h3>{ t(`${ viewSelectedReport }SessionsReport`) }</h3>
                                            }
                                            {
                                                viewSelectedReport !== null && viewReportData !== null &&
                                                < Chart
                                                    options={ viewSelectedReport === 'Daily' ? viewReportData.daily.options : viewSelectedReport === 'Weekly' ? viewReportData.weekly.options : viewReportData.monthly.options }
                                                    series={ viewSelectedReport === 'Daily' ? viewReportData.daily.series : viewSelectedReport === 'Weekly' ? viewReportData.weekly.series : viewReportData.monthly.series }
                                                    type='area'
                                                    width='100%'
                                                />
                                            }
                                            {
                                                type === 'video' || type === 'popup' ? 
                                                    <div className='row'>
                                                        <div className='col-12 col-md-6'>
                                                            <div className='row'>
                                                                <div className='col d-flex justify-content-end align-items-center'>
                                                                    <label className='fw-bold text-muted'>{ t('SelectReport') }</label>
                                                                </div>
                                                                <div className='col'>
                                                                    <select className='form-select' data-control='select2' data-placeholder='Select an option' onChange={ data => setLeadSelectedReport(data.target.value) }>
                                                                        <option value='Daily'>{ t('Daily') }</option>
                                                                        <option value='Weekly'>{ t('Weekly') }</option>
                                                                        <option value='Monthly'>{ t('Monthly') }</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <h3>{ t(`${ leadSelectedReport }CTAReport`) }</h3>
                                                            {
                                                                leadSelectedReport !== null && leadReportData !== null &&
                                                            < Chart
                                                                options={ leadSelectedReport === 'Daily' ? leadReportData.daily.options : leadSelectedReport === 'Weekly' ? leadReportData.weekly.options : leadReportData.monthly.options }
                                                                series={ leadSelectedReport === 'Daily' ? leadReportData.daily.series : leadSelectedReport === 'Weekly' ? leadReportData.weekly.series : leadReportData.monthly.series }
                                                                type='area'
                                                                width='100%'
                                                            />
                                                            }
                                                        </div>
                                                        <div className='col-12 col-md-6'>
                                                            <div className='row'>
                                                                <div className='col d-flex justify-content-end align-items-center'>
                                                                    <label className='fw-bold text-muted'>{ t('SelectReport') }</label>
                                                                </div>
                                                                <div className='col'>
                                                                    <select className='form-select' data-control='select2' data-placeholder='Select an option' onChange={ data => setDurationSelectedReport(data.target.value) }>
                                                                        <option value='Daily'>{ t('Daily') }</option>
                                                                        <option value='Weekly'>{ t('Weekly') }</option>
                                                                        <option value='Monthly'>{ t('Monthly') }</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <h3>{ t(`${ durationSelectedReport }DurationReport`) }</h3>
                                                            {
                                                                durationSelectedReport !== null && durationReportData !== null &&
                                                            < Chart
                                                                options={ durationSelectedReport === 'Daily' ? durationReportData.daily.options : durationSelectedReport === 'Weekly' ? durationReportData.weekly.options : durationReportData.monthly.options }
                                                                series={ durationSelectedReport === 'Daily' ? durationReportData.daily.series : durationSelectedReport === 'Weekly' ? durationReportData.weekly.series : durationReportData.monthly.series }
                                                                type='line'
                                                                width='100%'
                                                            />
                                                            }
                                                        </div>
                                                    </div> 
                                                    : 
                                                    <div>
                                                        <div className='row'>
                                                            <div className='col d-flex justify-content-end align-items-center'>
                                                                <label className='fw-bold text-muted'>{ t('SelectReport') }</label>
                                                            </div>
                                                            <div className='col'>
                                                                <select className='form-select' data-control='select2' data-placeholder='Select an option' onChange={ data => setDurationSelectedReport(data.target.value) }>
                                                                    <option value='Daily'>{ t('Daily') }</option>
                                                                    <option value='Weekly'>{ t('Weekly') }</option>
                                                                    <option value='Monthly'>{ t('Monthly') }</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <h3>{ t(`${ durationSelectedReport }DurationReport`) }</h3>
                                                        {
                                                            durationSelectedReport !== null && durationReportData !== null &&
                                                        < Chart
                                                            options={ durationSelectedReport === 'Daily' ? durationReportData.daily.options : durationSelectedReport === 'Weekly' ? durationReportData.weekly.options : durationReportData.monthly.options }
                                                            series={ durationSelectedReport === 'Daily' ? durationReportData.daily.series : durationSelectedReport === 'Weekly' ? durationReportData.weekly.series : durationReportData.monthly.series }
                                                            type='line'
                                                            width='100%'
                                                        />
                                                        }
                                                    </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div >
                        </div>
                    </div>
                </div>
                <div id='kt_scrolltop' className='scrolltop' data-kt-scrolltop='true'>
                    <span className='svg-icon'>
                        <svg xmlns='http://www.w3.org/2000/svg' width={ 24 } height={ 24 } viewBox='0 0 24 24' fill='none'>
                            <rect opacity='0.5' x={ 13 } y={ 6 } width={ 13 } height={ 2 } rx={ 1 } transform='rotate(90 13 6)' fill='black'>
                                <path d='M12.5657 8.56569L16.75 12.75C17.1642 13.1642 17.8358 13.1642 18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711L5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75C6.16421 13.1642 6.83579 13.1642 7.25 12.75L11.4343 8.56569C11.7467 8.25327 12.2533 8.25327 12.5657 8.56569Z' fill='black' /></rect></svg>
                    </span>
                </div>
            </>
        )
    }

}

export default Analysis

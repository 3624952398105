import { Field, Form, Formik } from 'formik'
import Fields from '../custom/Fields'
import React from 'react'

import { useTranslation } from 'react-i18next'
import { RegisterValidation } from '../../utils/validations/formValidations'
import { FORM_INITIAL_VALUES } from '../../utils/constants/formValues'

function RegisterForm(props) {
    const { t, i18n } = useTranslation()
    const { handleSubmit } = props
    return (
        <Formik
            initialValues={ FORM_INITIAL_VALUES.REGISTER }
            validationSchema={ RegisterValidation }
            onSubmit={ async fields => {
                return await handleSubmit(fields)
            } }
        >
            { ({ isSubmitting }) => (
                <Form className='form w-100'>
                    <div className='text-center mb-8'>
                        <h1 className='text-dark mb-3'>{ t('SignUpToRaylee') }</h1>
                    </div>
                    <div className='fv-row mb-8'>
                        <label className='form-label fs-6 fw-bolder text-dark'>{ t('Email') }</label>
                        <Field { ...props } type='text' placeholder={ t('Email') } name='email' as={ Fields } />
                    </div>
                    <div className='fv-row mb-8'>
                        <label className='form-label fs-6 fw-bolder text-dark'>{ t('OrganizationName') }</label>
                        <Field { ...props } type='text' placeholder={ t('OrganizationName') } name='organization' as={ Fields } />
                    </div>
                    <div className='fv-row mb-8'>
                        <label className='form-label fs-6 fw-bolder text-dark'>{ t('FirstName') }</label>
                        <Field { ...props } type='text' placeholder={ t('FirstName') } name='firstName' as={ Fields } />
                    </div>
                    <div className='fv-row mb-8'>
                        <label className='form-label fs-6 fw-bolder text-dark'>{ t('MiddleName') }</label>
                        <Field { ...props } type='text' placeholder={ t('MiddleName') } name='middleName' as={ Fields } />
                    </div>
                    <div className='fv-row mb-8'>
                        <label className='form-label fs-6 fw-bolder text-dark'>{ t('LastName') }</label>
                        <Field { ...props } type='text' placeholder={ t('LastName') } name='lastName' as={ Fields } />
                    </div>
                    <div className='fv-row mb-8'>
                        <div className='d-flex flex-stack'>
                            <label className='form-label text-dark fs-6 mb-0'>{ t('Password') }</label>
                        </div>
                        <Field type='password' placeholder={ t('Password') } name='password' as={ Fields } />
                    </div>
                    <div className='fv-row mb-8'>
                        <div className='d-flex flex-stack'>
                            <label className='form-label text-dark fs-6 mb-0'>{ t('ConfirmPassword') }</label>
                        </div>
                        <Field type='password' placeholder={ t('ConfirmPassword') } name='confirmPassword' as={ Fields } />
                    </div>
                    {
                        i18n.resolvedLanguage === 'en' ?
                            <div className='text-center mb-2'>
                                <span className='form-check-label fw-bold text-gray-700 fs-6'>{ t('TermsAndConditionsAndPrivacyPolicy.1') }
                                    <a href='/termsAndConditions' className='ms-1 link-primary'>{ t('TermsAndConditionsAndPrivacyPolicy.2') }</a> { t('TermsAndConditionsAndPrivacyPolicy.3') }
                                    <a href='https://www.x-climb.com/privacypolicy_en.html' target='_blank' className='ms-1 link-primary' rel="noreferrer">{ t('TermsAndConditionsAndPrivacyPolicy.4') }</a>
                                </span>
                            </div>
                            :
                            <div className='text-center mb-2'>
                                <span className='form-check-label fw-bold text-gray-700 fs-6'>{ t('TermsAndConditionsAndPrivacyPolicy.1') }
                                    <a href='/termsAndConditions' className='ms-1 link-primary'>{ t('TermsAndConditionsAndPrivacyPolicy.2') }</a> { t('TermsAndConditionsAndPrivacyPolicy.3') }
                                    <a href='https://www.x-climb.com/privacypolicy.html' target='_blank' className='ms-1 link-primary' rel="noreferrer">{ t('TermsAndConditionsAndPrivacyPolicy.4') }</a> { t('TermsAndConditionsAndPrivacyPolicy.5') }
                                </span>
                            </div>
                    }
                    <div className='text-center'>
                        <button id='kt_sign_in_submit' disabled={ isSubmitting } className='btn btn-lg btn-info w-100 mb-5' type='submit' data-kt-indicator={ `${ isSubmitting ? 'on' : 'off' }` }>
                            <span className='indicator-label'>{ t('Continue') }</span>
                            <span className='indicator-progress'>
                                Please wait...
                                <span className='spinner-border spinner-border-sm align-middle ms-2' />
                            </span>
                        </button>
                    </div>
                </Form>
            ) }
        </Formik>
    )
}

export default RegisterForm

import React from 'react'
import { Field, Form, Formik } from 'formik'
import Fields from '../custom/Fields'
import { useTranslation } from 'react-i18next'
import { ForgotPasswordValidation } from '../../utils/validations/formValidations'
import { FORM_INITIAL_VALUES } from '../../utils/constants/formValues'
import { Link } from 'react-router-dom'

function ForgotPasswordForm(props) {
    const { t } = useTranslation()
    const { handleSubmit } = props
    return (
        <Formik
            initialValues={FORM_INITIAL_VALUES.FORGOT_PASSWORD}
            validationSchema={ForgotPasswordValidation}
            onSubmit={fields => {
                return handleSubmit(fields)
            }}
        >
            {({ isSubmitting }) => (
                <Form className='form w-100'>
                    <div className='text-center mb-10'>
                        <h1 className='text-dark mb-3'>{t('ForgotPassword')}</h1>
                        <div className='text-gray-400 fw-bold fs-4'>{t('EnterYourEmailToResetYourPassword')}</div>
                    </div>
                    <div className='fv-row mb-8'>
                        <label className='form-label fs-6 fw-bolder text-dark'>{t('Email')}</label>
                        <Field {...props} type='text' placeholder={t('Email')} name='email' as={Fields} />
                    </div>
                    <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
                        <button disabled={isSubmitting} className='btn btn-lg btn-primary fw-bolder me-4' type='submit' data-kt-indicator={`${isSubmitting ? 'on' : 'off'}`}>
                            <span className='indicator-label'>{t('Continue')}</span>
                            <span className='indicator-progress'>
                                Please wait...
                                <span className='spinner-border spinner-border-sm align-middle ms-2' />
                            </span>
                        </button>
                        <Link to='/' className='btn btn-lg btn-light-primary fw-bolder'>
                            {t('Cancel')}
                        </Link>
                    </div>
                </Form>
            )}
        </Formik>
    )
}

export default ForgotPasswordForm

import React from 'react'
import { useTranslation } from 'react-i18next'
import CopyToClipboard from 'react-copy-to-clipboard'
import { bytesToReadableSize } from '../../utils/transformer/Number'
import Pagination from '../custom/Pagination'
import { toast } from '../../utils/toast/toast'

function AssetTable({ datas, handleSetData, params, getSpecificPageData, isLoading }) {
    const { t } = useTranslation()
    return (
        <div className="dataTables_wrapper dt-bootstrap4 no-footer">
            <div className="table-responsive">
                <table className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer">
                    <thead>
                        <tr className="text-start text-muted fw-bolder fs-7 text-uppercase gs-0">
                            <th className="min-w-125px sorting" style={ { whiteSpace: 'pre' } } tabIndex={ 0 } colSpan={ 1 } rowSpan={ 1 }>{ t('Organization') }</th>
                            <th className="min-w-125px sorting" style={ { whiteSpace: 'pre' } } tabIndex={ 0 } colSpan={ 1 } rowSpan={ 1 }>{ t('Name') }</th>
                            <th className="min-w-125px sorting" style={ { whiteSpace: 'pre' } } tabIndex={ 0 } colSpan={ 1 } rowSpan={ 1 }>{ t('Size') }</th>
                            <th className="min-w-125px sorting" style={ { whiteSpace: 'pre' } } tabIndex={ 0 } colSpan={ 1 } rowSpan={ 1 }>{ t('Tags') }</th>
                            <th className="min-w-125px sorting" style={ { whiteSpace: 'pre' } } tabIndex={ 0 } colSpan={ 1 } rowSpan={ 1 }>{ t('CreatedBy') }</th>
                            <th className="min-w-125px sorting" style={ { whiteSpace: 'pre' } } tabIndex={ 0 } colSpan={ 1 } rowSpan={ 1 }>{ t('CreatedDate') }</th>
                            <th className="min-w-125px sorting" style={ { whiteSpace: 'pre' } } tabIndex={ 0 } colSpan={ 1 } rowSpan={ 1 }>{ t('UpdatedDate') }</th>
                        </tr>
                    </thead>
                    <tbody className="text-gray-600 fw-bold">
                        { isLoading ? 
                            <tr key={ 0 }>
                                <td className="text-center" colSpan="11">{ t('Loading') }</td>
                            </tr> 
                            :
                            datas && datas.docs.length > 0 ? 
                                datas.docs.map(doc => (
                                    <tr key={ doc._id } onClick={ () => {
                                        handleSetData && handleSetData(doc)
                                    } } data-bs-toggle="modal" data-bs-target="#kt_modal_add_Asset">
                                        <td>{ doc.organization.name }</td>
                                        <td>{ doc.name }</td>
                                        <td>{ bytesToReadableSize(doc.size) }</td>
                                        <td>{ doc.tags.map(tag => <span key={ tag } className="badge badge-primary badge mx-2">{ tag }</span>) }</td>
                                        <td>{ doc.user.email }</td>
                                        <td>{ new Date(doc.createdAt).toLocaleString() }</td>
                                        <td>{ new Date(doc.updatedAt).toLocaleString() }</td>
                                    </tr>
                                )) 
                                :
                                <tr key={ 0 }>
                                    <td className="text-center" colSpan="11">{ t('NoRecordFound') }</td>
                                </tr>
                        }
                    </tbody>
                </table>
            </div>
            <div className="row">
                <div className="col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start">&nbsp;</div>
                <div className="col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end">
                    <div className="dataTables_paginate paging_simple_numbers">
                        { datas && <Pagination pageCount={ datas.totalPages } selectedPage={ params.page } handlePageClick={ getSpecificPageData } /> }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AssetTable

export const AssetTableMini = ({ datas, params, getSpecificPageData, isLoading }) => {
    const { t } = useTranslation()
    return (
        <div className="dataTables_wrapper dt-bootstrap4 no-footer">
            <div className="table-responsive">
                <table className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer">
                    <thead>
                        <tr className="text-start text-muted fw-bolder fs-7 text-uppercase gs-0">
                            <th className="min-w-125px sorting" style={ { whiteSpace: 'pre' } } tabIndex={ 0 } colSpan={ 1 } rowSpan={ 1 }>{ t('Name') }</th>
                            <th className="min-w-125px sorting" style={ { whiteSpace: 'pre' } } tabIndex={ 0 } colSpan={ 1 } rowSpan={ 1 }>{ t('Tags') }</th>
                        </tr>
                    </thead>
                    <tbody className="text-gray-600 fw-bold">
                        {
                            isLoading ?
                                <tr key={ 0 }>
                                    <td className="text-center" colSpan="11">{ t('Loading') }</td>
                                </tr>
                                :
                                datas && datas.docs.length > 0 ? datas.docs.map(doc => (
                                    <CopyToClipboard key={ doc._id } text={ process.env.REACT_APP_AWS_CLOUDFRONT_DOMAIN + '/' + doc.key } onCopy={ () => toast('info', '', `Copied! ${ doc.name }`) }>
                                        <tr className={ 'cursor-pointer' }>
                                            <td>{ doc.name }</td>
                                            <td>{ doc.tags.map(tag => <span key={ tag } className="badge badge-primary badge mx-2">{ tag }</span>) }</td>
                                        </tr>
                                    </CopyToClipboard>
                                ))
                                    :
                                    <tr key={ 0 }>
                                        <td className="text-center" colSpan="11">{ t('NoRecordFound') }</td>
                                    </tr>
                        }
                    </tbody>
                </table>
            </div>
            <div className="row">
                <div className="d-flex align-items-center justify-content-center">
                    <div className="dataTables_paginate paging_simple_numbers">
                        { datas && <Pagination pageCount={ datas.totalPages } selectedPage={ params.page } handlePageClick={ getSpecificPageData } /> }
                    </div>
                </div>
            </div>
        </div>
    )
}

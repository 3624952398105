import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'

function Manuals() {
    const [ready, setReady] = useState(false)
    useEffect(() => {
        setTimeout(() => {
            setReady(true)
        }, 1000)
        return () => setReady(false)
    }, [])

    return (
        <div className={ `${ !ready && 'invisible' }` }>
            <Helmet>
                <meta charSet="UTF-8" />
                <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
                <meta name="viewport" content="initial-scale=1.0,user-scalable=no" />
                <meta name="format-detection" content="telephone=no" />
                <title>
                    Raylee-レイリー｜縦型ショート動画をWebサイトに！ストーリーテリングサービス
                </title>
                <meta name="keywords" content />
                <meta name="copyright" content="x-climb株式会社" />
                <link
                    href="https://use.fontawesome.com/releases/v5.6.1/css/all.css"
                    rel="stylesheet"
                />
                <link href={ `${ process.env.PUBLIC_URL }/external-assets/css/style.css` } rel="stylesheet" />
                <script src={ `${ process.env.PUBLIC_URL }/external-assets/js/toggle.js` }></script>
                <script src={ `${ process.env.PUBLIC_URL }/external-assets/js/pagetop.js` }></script>
            </Helmet>
            <div className="subpage">
                <header id="header">
                    <div className="header_inner">
                        <div className="logo">
                            <a href="#">
                                <img src={ `${ process.env.PUBLIC_URL }/external-assets/images/logo01.svg` } alt="Raylee" />
                            </a>
                        </div>
                    </div>
                </header>
            </div>
            {/*//.top_mv*/ }
            <section className="terms" style={ { padding: '120px 0px 0px' } }>
                <iframe
                    src="https://raylee-test.s3.ap-northeast-1.amazonaws.com/assets/documents/manual_jp.pdf"
                    frameBorder="0"
                    scrolling="auto"
                    height="1080px"
                    width="100%">
                </iframe>
            </section>
            {/*//.movie_area*/ }
            <footer>
                <div className="footer_inner">
                    <div className="logo">
                        <img src={ `${ process.env.PUBLIC_URL }/external-assets/images/logo03.svg` } alt="レイリー" />
                    </div>
                    <nav className="footer_nav">
                        <ul>
                            <li>
                                <a href="https://www.x-climb.com/" target="_blank" rel="noreferrer">
                                    運営会社
                                </a>
                            </li>
                            <li>
                                <a href="/termsAndConditions">利用規約</a>
                            </li>
                            <li>
                                <a
                                    href="https://www.x-climb.com/privacypolicy.html"
                                    target="_blank" rel="noreferrer"
                                >
                                    プライバシーポリシー
                                </a>
                            </li>
                        </ul>
                    </nav>
                </div>
                {/*//.footer_inner*/ }
                <div className="copyright">
                    <p>Copyrights©Raylee All rights reserved.</p>
                </div>
            </footer>
            <ul className="footer_btn">
                <li>
                    <a href="https://www.raylee.one/" target="_blank" rel="noreferrer">
                        ログイン
                    </a>
                </li>
                <li>
                    <a href="https://www.raylee.one/register" target="_blank" rel="noreferrer">
                        新規登録
                    </a>
                </li>
            </ul>
        </div>
    )
}

export default Manuals

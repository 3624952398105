import { useTranslation } from 'react-i18next'

export default function useLanguage() {
    const { i18n } = useTranslation()

    const updateLanguage = (lang) => {
        i18n.changeLanguage(lang)
    }

    return { updateLanguage }
}
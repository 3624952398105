import axios from 'axios'
import { ENDPOINTS } from '../constants/endpoint'
import { toast } from '../toast/toast'

function useChatConnection() {
    const getChatConnectionByParams = async (params) => {
        return axios.get(process.env.REACT_APP_API_URL + ENDPOINTS.CHAT_CONNECTION + ENDPOINTS.QUERY, {
            params: params
        }).catch(error => toast('error', '', error?.response?.data?.message))
    }

    const createChatConnection = async (requestBody) => {
        return axios.post(process.env.REACT_APP_API_URL + ENDPOINTS.CHAT_CONNECTION, requestBody).catch(error => toast('error', '', error?.response?.data?.message))
    }

    const updateChatConnection = async (requestBody) => {
        return axios.put(process.env.REACT_APP_API_URL + ENDPOINTS.CHAT_CONNECTION + '/' + requestBody._id, requestBody).catch(error => toast('error', '', error?.response?.data?.message))
    }

    const deleteChatConnection = async (id) => {
        return axios.delete(process.env.REACT_APP_API_URL + ENDPOINTS.CHAT_CONNECTION + '/' + id).catch(error => toast('error', '', error?.response?.data?.message))
    }

    return { getChatConnectionByParams, createChatConnection, updateChatConnection, deleteChatConnection }
}

export default useChatConnection

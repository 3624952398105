import React from 'react'
import { useTranslation } from 'react-i18next'

function PaymentSuccess() {
    const { t } = useTranslation()

    return (
        <div>
            <div className='d-flex flex-column flex-root'>
                <div className='d-flex flex-column flex-column-fluid'>
                    <div className='d-flex flex-column flex-column-fluid text-center p-10 py-lg-10'>
                        <a href='/' className='pt-lg-20'>
                            <img alt='Logo' src='https://raylee-test.s3.ap-northeast-1.amazonaws.com/assets/icons/Raylee+Logo.svg' className='h-75px mb-5' />
                        </a>
                        <div className='pt-lg-10 mb-10'>
                            <h1 className='fw-bolder fs-4x text-gray-900 mb-10'>{ t('TransactionSuccess') }</h1>
                            <div className='fw-bold fs-3 text-gray-400 mb-15'>{ t('TransactionSuccessBody') }</div>
                            <div className='text-center'>
                                <a href='/' className='btn btn-lg btn-primary fw-bolder'>{ t('GoToHomePage') }</a>
                            </div>
                        </div>
                        <div
                            className='d-flex flex-row-auto bgi-no-repeat bgi-position-x-center bgi-size-contain bgi-position-y-bottom min-h-100px min-h-lg-350px'
                            style={ {
                                backgroundImage: `url(${ process.env.PUBLIC_URL }/assets/media/illustrations/sigma-1/6.png)`
                            } }
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PaymentSuccess


import { useTranslation } from 'react-i18next'
import React, { useEffect, useState } from 'react'
import { getObjectId } from '../../utils/transformer/Object'
import Pagination from '../custom/Pagination'

function PopUpPreview({ datas, handleUpdate, params, getSpecificPageData, selectedActiveBusiness, makePopUpActive, makePopUpInactive, getData }) {
    const [isLoading, setIsLoading] = useState(false)
    const [popUps, setPopUps] = useState([])
    const { t } = useTranslation()

    useEffect(() => {
        if (selectedActiveBusiness?.value && datas) {
            setPopUps(datas.docs.filter(doc => doc.business?._id === selectedActiveBusiness?.value?._id))
        }
    }, [datas, selectedActiveBusiness])

    const handleMoveToActivePopUp = async (popUp) => {
        setIsLoading(true)
        await makePopUpActive(popUp._id)
        await getData()
        setIsLoading(false)
    }

    const handleMoveToInactivePopUp = async (popUp) => {
        setIsLoading(true)
        await makePopUpInactive(popUp._id)
        await getData()
        setIsLoading(false)
    }

    const updateSequence = async (popUp, direction) => {
        setIsLoading(true)
        const prevSequence = popUp.sequence
        const nextSequence = popUp.sequence === 1 ? 2 : direction === 'left' ? popUp.sequence - 1 : popUp.sequence + 1
        const adjustedPopUp = datas.docs.find(doc => doc.sequence === nextSequence)
        if (adjustedPopUp?._id) {
            const adjustedPopUpData = {
                ...adjustedPopUp,
                sequence: prevSequence,
                organization: getObjectId(popUp.organization),
                business: getObjectId(popUp.business),
                user: getObjectId(popUp.user),
                tags: adjustedPopUp.tags || [],
            }
            await handleUpdate(adjustedPopUpData)
        }
        const data = {
            ...popUp,
            organization: getObjectId(popUp.organization),
            business: getObjectId(popUp.business),
            user: getObjectId(popUp.user),
            tags: popUp.tags || [],
            sequence: nextSequence
        }
        await handleUpdate(data)
        setIsLoading(false)
    }

    if (isLoading) {
        return (
            <div className="w-100 vh-100 d-flex align-items-center justify-content-center">
                <div className="spinner-border" style={ { width: '3rem', height: '3rem' } } role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
        )
    }


    if (!selectedActiveBusiness?.value) {
        return (
            <div className="d-flex align-items-center justify-content-center">
                <span className="text-gray-700 fw-bold me-1">{ t('NoSelectedBusiness') }</span>
            </div>
        )
    }

    return (
        <div className="w-100">
            <div className="w-100">
                <img src="line.svg" className="w-100" />
                <span className="text-gray-700 fw-bold me-1 b-5">{ t('ActivePopUp') }</span>
                <img src="vector.svg" height="15px" width="15px" />
                <div className="row mt-3">
                    {
                        popUps && popUps.length <= 0 ?
                            <div className="d-flex align-items-center justify-content-center">
                                <span className="text-gray-700 fw-bold me-1">{ t('NoRecordFound') }</span>
                            </div>
                            :
                            popUps && popUps.map((doc) => doc.isActive && (
                                <div key={ doc._id } className="position-relative col-12 col-md-6 col-lg-4 col-xl-3 mb-3">
                                    <div className="dropdown">
                                        <div className="drop-down me-6" style={ { zIndex: 1 } } id={ `video-active-option-${ doc._id }` } data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false">
                                            <img src="assets/media/icons/duotune/general/gen039.svg" />
                                        </div>
                                        <div className="dropdown-menu dropdown-menu-end menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-300px py-4" aria-labelledby={ `video-active-option-${ doc._id }` }>
                                            <div className="menu-item px-3">
                                                <div className="d-flex justify-content-around align-items-center">
                                                    <button className="btn btn-primary" disabled={ doc.sequence === 1 } onClick={ () => updateSequence(doc, 'left') }>{ t('MoveLeft') }</button>
                                                    { doc.sequence }
                                                    <button className="btn btn-primary" disabled={ datas.docs.filter(doc => doc.isActive).length <= doc.sequence } onClick={ () => updateSequence(doc, 'right') }>{ t('MoveRight') }</button>
                                                </div>
                                            </div>
                                            <div className="menu-item px-3">
                                                <span className="menu-link" onClick={ () => handleMoveToInactivePopUp(doc) }>
                                                    { t('RemoveFromActivePopUp') }
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <img
                                        style={ { objectFit: 'cover', width: '171px', height: '286px', borderRadius: '10px' } }
                                        src={ `${ process.env.REACT_APP_AWS_CLOUDFRONT_DOMAIN }/${ doc.fileType === 'image' ? doc.key : doc.thumbnailKey }` }
                                        className="mb-4"
                                    />
                                </div>
                            ))
                    }
                </div>
            </div>
            <div className="w-100">
                <img src="line.svg" className="w-100" />
                <span className="text-gray-700 fw-bold me-1">{ t('InactivePopUp') }</span>
                <img src="vector.svg" height="15px" width="15px" />
                <div className="row mt-3">
                    {
                        popUps && popUps && popUps.map((doc) => !doc.isActive && (
                            <div key={ doc._id } className="position-relative col-12 col-md-6 col-lg-4 col-xl-3 mb-5">
                                <div className="dropdown">
                                    <div className="drop-down me-6" style={ { zIndex: 1 } } id={ `video-inactive-option-${ doc._id }` } data-bs-toggle="dropdown" aria-expanded="false">
                                        <img src="assets/media/icons/duotune/general/gen039.svg" />
                                    </div>
                                    <div className="dropdown-menu dropdown-menu-end menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-200px py-4" aria-labelledby={ `video-inactive-option-${ doc._id }` }>
                                        <div className="menu-item px-3">
                                            <span className="menu-link" onClick={ () => handleMoveToActivePopUp(doc) }>
                                                { t('MoveToActivePopUp') }
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <img
                                    style={ { objectFit: 'cover', width: '171px', height: '286px', borderRadius: '10px' } }
                                    src={ `${ process.env.REACT_APP_AWS_CLOUDFRONT_DOMAIN }/${ doc.fileType === 'image' ? doc.key : doc.thumbnailKey }` }
                                    className="mb-4"
                                />
                            </div>
                        ))
                    }
                    <div className="row">
                        <div className="col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start">&nbsp;</div>
                        <div className="col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end">
                            <div id="kt_table_users_paginate" className="dataTables_paginate paging_simple_numbers">
                                { datas && <Pagination pageCount={ datas.totalPages } selectedPage={ params.page } handlePageClick={ getSpecificPageData } /> }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PopUpPreview

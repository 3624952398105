import axios from 'axios'
import { ENDPOINTS } from '../constants/endpoint'
import { toast } from '../toast/toast'

function useAuth() {
    const resendActivationEmail = async (requestBody, t) => {
        return await axios.put(`${ process.env.REACT_APP_API_URL }${ ENDPOINTS.RESEND_ACTIVATION }/${ requestBody.email }/${ requestBody.language }`)
            .then(() => {
                window.Swal.fire({
                    text: t('EmailForResetPasswordSentSuccessfullyPleaseCheckYourEmail'),
                    icon: 'success',
                    buttonsStyling: false,
                    confirmButtonText: t('OkGotIt'),
                    customClass: {
                        confirmButton: 'btn btn-primary'
                    }
                })
            })
            .catch(error => toast('error', '', t(error?.response?.data?.message)))
    }

    const requestForgotPasswordEmail = async (requestBody, t) => {
        return await axios.post(process.env.REACT_APP_API_URL + ENDPOINTS.FORGOT_PASSWORD, requestBody).then(() => {
            window.Swal.fire({
                text: t('EmailForResetPasswordResentSuccessfullyPleaseCheckYourEmail'),
                icon: 'success',
                buttonsStyling: false,
                confirmButtonText: t('OkGotIt'),
                customClass: {
                    confirmButton: 'btn btn-primary'
                }
            })
        }).catch(error => toast('error', '', t(error.response.data.message)))
    }

    const resetPassword = async (requestBody, t) => {
        return await axios.post(process.env.REACT_APP_API_URL + ENDPOINTS.RESET_PASSWORD, requestBody).catch(error => toast('error', '', t(error.response.data.message)))
    }

    const activateAccount = async (requestBody) => {
        return await axios.put(`${ process.env.REACT_APP_API_URL }${ ENDPOINTS.ACTIVATE_ACCOUNT }/${ requestBody.user }/${ requestBody.code }`)
            .then(() => true)
            .catch(() => false)
    }

    const changePassword = async (requestBody, t) => {
        return await axios.post(process.env.REACT_APP_API_URL + ENDPOINTS.CHANGE_PASSWORD, requestBody)
            .then(() => {
                toast('success', '', t('Success'))
            })
            .catch(error => toast('error', '', t(error.response.data.message)))
    }

    return { resendActivationEmail, activateAccount, requestForgotPasswordEmail, resetPassword, changePassword }
}

export default useAuth

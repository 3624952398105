import axios from 'axios'
import { ENDPOINTS } from '../constants/endpoint'
import { toast } from '../toast/toast'

function useBusiness() {
    const getBusinessByParams = async (params) => {
        return axios.get(process.env.REACT_APP_API_URL + ENDPOINTS.GET_BUSINESS, {
            params: params
        }).catch(error => toast('error', '', error?.response?.data?.message))
    }

    const createBusiness = async (requestBody) => {
        return axios.post(process.env.REACT_APP_API_URL + ENDPOINTS.CREATE_BUSINESS, requestBody).catch(error => toast('error', '', error?.response?.data?.message))
    }

    const updateBusiness = async (requestBody) => {
        return axios.put(process.env.REACT_APP_API_URL + ENDPOINTS.CREATE_BUSINESS + '/' + requestBody._id, requestBody).catch(error => toast('error', '', error?.response?.data?.message))
    }

    return { getBusinessByParams, createBusiness, updateBusiness }
}

export default useBusiness

export const constructAreaChart = (verticalDatas, SeriesName, horizontalDatas, colorBase) => {
    return {
        options: {
            chart: {
                id: SeriesName
            },
            xaxis: {
                categories: horizontalDatas
            },
            stroke: {
                curve: 'smooth',
                colors: [colorBase]
            },
            fill: {
                type: 'solid',
                opacity: 0.5,
                colors: [colorBase]
            }
        },
        series: [
            {
                type: 'area',
                name: SeriesName,
                data: verticalDatas
            }
        ]
    }
}

export const constructLineChart = (seriesDatas, SeriesName, horizontalDatas, colorBase) => {
    return {
        options: {
            chart: {
                id: SeriesName
            },
            xaxis: {
                categories: horizontalDatas
            },
            stroke: {
                curve: 'smooth',
            },
            fill: {
                type: 'solid',
                opacity: 0.5,
                colors: [colorBase]
            }
        },
        series: seriesDatas
    }
}
import React from 'react'
import { Field, Form, Formik } from 'formik'
import Fields from '../custom/Fields'
import { OrganizationValidation } from '../../utils/validations/formValidations'

import { useTranslation } from 'react-i18next'

function OrganizationForm(props) {
    const { t } = useTranslation()
    const { initialValues, handleSubmit } = props

    return (
        <Formik
            initialValues={ initialValues }
            enableReinitialize
            validationSchema={ OrganizationValidation }
            onSubmit={ async (fields, { ...formikProps }) => {
                return await handleSubmit(fields, formikProps)
            } }
        >
            { ({ isSubmitting, ...formikProps }) => (
                <Form className='form fv-plugins-bootstrap5 fv-plugins-framework'>
                    <div className="d-flex flex-column scroll-y me-n7 px-7" style={ { maxHeight: '637px' } } data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_add_user_header" data-kt-scroll-wrappers="#kt_modal_add_user_scroll" data-kt-scroll-offset="300px">
                        <div className="fv-row mb-7 fv-plugins-icon-container">
                            <Field { ...formikProps } type='text' placeholder={ t('Name') } label={ t('Name') } name='name' as={ Fields } />
                        </div>
                        <div className="fv-row mb-7 fv-plugins-icon-container">
                            <Field { ...formikProps } type='text' placeholder={ t('Code') } label={ t('Code') } name='code' as={ Fields } />
                        </div>
                    </div>
                    <div className="text-end pt-15">
                        <button className="btn btn-light me-3" disabled={ isSubmitting } type="reset" data-bs-dismiss="modal" data-kt-users-modal-action="close">{ t('Cancel') }</button>
                        <button className="btn btn-primary" disabled={ isSubmitting } type="submit" data-kt-indicator={ `${ isSubmitting ? 'on' : 'off' }` } data-kt-users-modal-action="submit">
                            <span className="indicator-label">{ initialValues._id ? t('Update') : t('Create') }</span> <span className="indicator-progress">
                                { t('PleaseWait') }
                                <span className="spinner-border spinner-border-sm align-middle ms-2" />
                            </span>
                        </button>
                    </div>
                </Form>
            ) }
        </Formik>
    )
}

export default OrganizationForm

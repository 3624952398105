import React, { useEffect, useRef, useState } from 'react'
import useUsers from '../../utils/hooks/useUsers'
import { FORM_INITIAL_VALUES } from '../../utils/constants/formValues'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import UserTable from './UserTable'
import UserForm from './UserForm'
import useOrganizations from '../../utils/hooks/useOrganizations'
import { ObjectToSelect } from '../../utils/transformer/Select'
import { getObjectId } from '../../utils/transformer/Object'

function Users() {
    const { t, i18n } = useTranslation()
    const loggedInUser = useSelector(state => state.authData.loggedInUser)

    const [params, setParams] = useState({
        page: 1,
        limit: 10,
        userTypes: ['OWNER', 'ADMIN', 'EMPLOYEE'],
        organization: loggedInUser.organization
    })
    const [data, setData] = useState(FORM_INITIAL_VALUES.USER)
    const [datas, setDatas] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const [organizationOptions, setOrganizationOptions] = useState([])
    const { getUsersByParams, createUser, updateUser, deleteUser } = useUsers()
    const { getOrganizationByParams } = useOrganizations()
    const modalCloseBtnRef = useRef()

    useEffect(() => {
        getData()
    }, [params])

    useEffect(() => {
        getOrganizationData()
    }, [])

    const getData = async () => {
        setIsLoading(true)
        const response = await getUsersByParams(params)
        setDatas(response.data)
        setIsLoading(false)
    }

    const getOrganizationData = async () => {
        const response = await getOrganizationByParams({
            page: 1,
            limit: 50
        })
        if (response.data && response.data.docs.length > 0) {
            const options = response.data.docs.map(doc => ObjectToSelect(`${ doc.name }`, doc))
            setOrganizationOptions(options)
        }
    }

    const getSpecificPageData = (page) => {
        setParams(oldParams => {
            return {
                ...oldParams,
                page: page.selected + 1
            }
        })
    }

    const handleCreateUpdateData = async (formData, formikProps) => {
        const type = formData.type.value
        let data = {
            ...formData,
            type: formData._id ? type : loggedInUser.type === 'OWNER' ? 'EMPLOYEE' : type,
            organization: loggedInUser.type === 'OWNER' ? loggedInUser.organization : type === 'ADMIN' ? '' : type === 'OWNER' ? formData.organization : getObjectId(formData.organization.value),
            planType: type === 'OWNER' ? formData.planType.value : '',
            language: i18n.resolvedLanguage
        }
        if (formData._id) {
            if (type === 'OWNER') {
                delete data.organization
            }
        }
        let response = formData._id ? await updateUser(data) : await createUser(data, t)
        if (response.data.user?._id || response.data?._id) {
            formikProps.resetForm()
            modalCloseBtnRef.current.click()
        }
        getData()
        return response
    }

    const handleSetData = (data) => {
        let tempData = { ...data }
        if (tempData.type === 'OWNER') {
            tempData.organization = tempData.organization.name
            tempData.planType = ObjectToSelect(data.organization.planType, data.organization.planType)
        } else if (tempData.type === 'EMPLOYEE') {
            tempData.organization = ObjectToSelect(tempData.organization.name, tempData.organization)
        }
        tempData.type = ObjectToSelect(tempData.type, tempData.type)
        setData(tempData)
    }

    const handleDelete = async (id, formikProps) => {
        await deleteUser(id)
        formikProps.resetForm()
        modalCloseBtnRef.current.click()
        getData()
    }

    return (
        <div id='kt_content' className='content d-flex flex-column flex-column-fluid'>
            <div id='kt_content_container' className='container-xxl'>
                <div className='card'>
                    <div className='card-header border-0 pt-6'>
                        <div className='card-title'>
                        </div>
                        <div className='card-toolbar'>
                            <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
                                <button className='btn btn-primary' type='button' onClick={ () => setData(FORM_INITIAL_VALUES.USER) } data-bs-toggle='modal' data-bs-target='#kt_modal_add_user'>{ t('AddUser') }</button>
                            </div>
                            <div id='kt_modal_add_user' className='modal fade' tabIndex={ -1 }>
                                <div className='modal-dialog modal-dialog-centered mw-650px'>
                                    <div className='modal-content'>
                                        <div className='modal-header'>
                                            <h2 className='fw-bolder'>{ `${ data._id ? t('UpdateUser') : t('CreateUser') }` }</h2>
                                            <div className='btn btn-icon btn-sm btn-active-icon-primary' ref={ modalCloseBtnRef } data-bs-dismiss='modal' data-kt-users-modal-action='close'>
                                                <span className='svg-icon svg-icon-1'>
                                                    <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
                                                        <rect opacity='0.5' x='6' y='17.3137' width='16' height='2' rx='1' transform='rotate(-45 6 17.3137)' fill='black'></rect>
                                                        <rect x='7.41422' y='6' width='16' height='2' rx='1' transform='rotate(45 7.41422 6)' fill='black'></rect>
                                                    </svg>
                                                </span>
                                            </div>
                                        </div>
                                        <div className='modal-body scroll-y mx-5 mx-xl-15 my-7'>
                                            <UserForm initialValues={ data } handleSubmit={ handleCreateUpdateData } handleDelete={ handleDelete } organizationOptions={ organizationOptions } />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='card-body pt-0'>
                        <UserTable
                            datas={ datas }
                            handleSetData={ handleSetData }
                            getSpecificPageData={ getSpecificPageData }
                            params={ params }
                            isLoading={ isLoading }
                        />
                    </div>
                </div>
            </div>
        </div >
    )
}

export default Users

import React, { useEffect, useRef, useState } from 'react'
import Select from 'react-select'
import ChatConnectionForm from './ChatConnectionForm'
import { FORM_INITIAL_VALUES } from '../../utils/constants/formValues'
import { ArrayStringToSelectMulti, ObjectToSelect, SelectMultiToArrayString } from '../../utils/transformer/Select'
import { getObjectId } from '../../utils/transformer/Object'
import { useTranslation } from 'react-i18next'
import ChatConnectionTable from './ChatConnectionTable'
import { useSelector } from 'react-redux'
import { selectStyles } from '../custom/FieldsStyles'
import useChatConnection from '../../utils/hooks/useChatConnection'
import useOrganizations from '../../utils/hooks/useOrganizations'

function ChatConnection() {
    const { t } = useTranslation()
    const loggedInUser = useSelector(state => state.authData.loggedInUser)
    const [data, setData] = useState(FORM_INITIAL_VALUES.CHAT_CONNECTION)
    const [activeOrganization, setActiveOrganization] = useState(null)
    const [organizationOptions, setOrganizationOptions] = useState([])
    const [datas, setDatas] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const [params, setParams] = useState({
        page: 1,
        limit: 10,
        sort: 'createdAt:desc',
        organization: loggedInUser.organization
    })
    const { getChatConnectionByParams, createChatConnection, updateChatConnection, deleteChatConnection } = useChatConnection()
    const { getOrganizationByParams } = useOrganizations()
    const noRegisteredOrganizations = organizationOptions.length <= 0
    const modalCloseBtnRef = useRef()

    useEffect(() => {
        getOrganizationData()
    }, [])

    useEffect(() => {
        getData()
    }, [params])

    const getOrganizationData = async () => {
        const response = await getOrganizationByParams({
            page: 1,
            limit: 100
        })
        if (response.data && response.data.docs.length > 0) {
            const options = response.data.docs.map(doc => ObjectToSelect(`${ doc.name }`, doc))
            setOrganizationOptions(options)
        }
    }

    const getData = async () => {
        setIsLoading(true)
        const response = await getChatConnectionByParams(params)
        setDatas(response.data)
        setIsLoading(false)
    }

    const getSpecificPageData = (page) => {
        setParams(oldParams => {
            return {
                ...oldParams,
                page: page.selected + 1
            }
        })
    }

    const handleSubmit = async (formValues, formikProps) => {
        const organizationId = loggedInUser.type !== 'ADMIN' ? loggedInUser.organization : getObjectId(formValues.organization.value)
        const data = {
            ...formValues,
            organization: organizationId,
            emailRecipients: formValues.emailRecipients ? SelectMultiToArrayString(formValues.emailRecipients) : [],
            user: loggedInUser._id
        }
        const response = formValues._id ? await updateChatConnection(data) : await createChatConnection(data)
        if (response?.data?._id) {
            formikProps.resetForm()
            modalCloseBtnRef.current.click()
            getData()
            setData(FORM_INITIAL_VALUES.CHAT_CONNECTION)
        }
    }

    const handleDelete = async (id, formikProps) => {
        await deleteChatConnection(id)
        formikProps.resetForm()
        modalCloseBtnRef.current.click()
        getData()
    }

    const handleSetData = (data) => {
        let tempData = { ...data }
        tempData.organization = ObjectToSelect(tempData.organization?.name, tempData.organization)
        tempData.emailRecipients = tempData.emailRecipients && ArrayStringToSelectMulti(tempData.emailRecipients)
        setData(tempData)
    }

    const openModal = () => {
        let newData = { ...FORM_INITIAL_VALUES.CHAT_CONNECTION }
        if (activeOrganization) {
            newData.organization = ObjectToSelect(activeOrganization.name, activeOrganization)
        }
        setData(newData)
    }

    return (
        <div id='kt_content' className='content d-flex flex-column flex-column-fluid'>
            <div id='kt_content_container' className='container-xxl'>
                <div className='card'>
                    <div className='card-header border-0 pt-6'>
                        <div className='card-title w-50'>
                            <div className='d-flex align-items-center position-relative my-1 w-100'>
                                {
                                    loggedInUser.type === 'ADMIN' &&
                                    <Select
                                        styles={ selectStyles }
                                        className={ 'form-control py-1 form-control-solid' }
                                        onChange={ data => {
                                            setActiveOrganization(data)
                                            setParams(state => ({
                                                ...state,
                                                page: 1,
                                                organization: data?.value?._id
                                            }))
                                        } }
                                        placeholder={ t('SelectOrganization') }
                                        value={ activeOrganization }
                                        options={ organizationOptions }
                                        isClearable={ true }
                                        isSearchable={ true }
                                    />
                                }
                            </div>
                        </div>
                        <div className='card-toolbar'>
                            <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
                                <button
                                    className='btn btn-primary'
                                    type='button'
                                    disabled={ noRegisteredOrganizations }
                                    onClick={ openModal }
                                    data-bs-toggle='modal'
                                    data-bs-target='#kt_modal_add_chat_connection'>
                                    { t('AddChatConnection') }
                                </button>
                            </div>
                            <div id='kt_modal_add_chat_connection' className='modal fade' tabIndex={ -1 }>
                                <div className='modal-dialog modal-dialog-centered mw-650px'>
                                    <div className='modal-content'>
                                        <div id='kt_modal_add_chat_connection_header' className='modal-header'>
                                            <h2 className='fw-bolder'>{ `${ data._id ? t('UpdateChatConnection') : t('CreateChatConnection') }` }</h2>
                                            <div className='btn btn-icon btn-sm btn-active-icon-primary' ref={ modalCloseBtnRef } data-bs-dismiss='modal' data-kt-users-modal-action='close'>
                                                <span className='svg-icon svg-icon-1'>
                                                    <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
                                                        <rect opacity='0.5' x='6' y='17.3137' width='16' height='2' rx='1' transform='rotate(-45 6 17.3137)' fill='black'></rect>
                                                        <rect x='7.41422' y='6' width='16' height='2' rx='1' transform='rotate(45 7.41422 6)' fill='black'></rect>
                                                    </svg>
                                                </span>
                                            </div>
                                        </div>
                                        <div className='modal-body scroll-y mx-5 mx-xl-15 my-7'>
                                            <ChatConnectionForm
                                                initialValues={ data }
                                                handleSubmit={ handleSubmit }
                                                handleDelete={ handleDelete }
                                                organizationOptions={ organizationOptions }
                                                modalCloseBtnRef={ modalCloseBtnRef }
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div >
                    </div >
                    <div className='card-body pt-0'>
                        <ChatConnectionTable
                            datas={ datas }
                            handleSetData={ handleSetData }
                            getSpecificPageData={ getSpecificPageData }
                            params={ params }
                            isLoading={ isLoading }
                            modalCloseBtnRef={ modalCloseBtnRef }
                        />
                    </div>
                </div >
            </div >
        </div >
    )
}

export default ChatConnection
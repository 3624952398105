import axios from 'axios'
import { ENDPOINTS } from '../constants/endpoint'
import { toast } from '../toast/toast'

function useAssets() {
    const getAssetsByParams = async (params) => {
        return await axios.get(process.env.REACT_APP_API_URL + ENDPOINTS.ASSETS + ENDPOINTS.QUERY, {
            params: params
        }).catch(error => toast('error', '', error.response.data.message))
    }

    const getAssetsById = async (id) => {
        return await axios.get(process.env.REACT_APP_API_URL + ENDPOINTS.ASSETS + '/' + id).catch(error => toast('error', '', error.response.data.message))
    }

    const createAsset = async (assetData) => {
        return await axios.post(process.env.REACT_APP_API_URL + ENDPOINTS.ASSETS, assetData).catch(error => toast('error', '', error.response.data.message))
    }

    const updateAsset = async (assetData) => {
        return await axios.put(process.env.REACT_APP_API_URL + ENDPOINTS.ASSETS + '/' + assetData._id, assetData).catch(error => toast('error', '', error.response.data.message))
    }

    const deleteAsset = async (id) => {
        return await axios.delete(process.env.REACT_APP_API_URL + ENDPOINTS.ASSETS + '/' + id).catch(error => toast('error', '', error.response.data.message))
    }

    return { getAssetsById, getAssetsByParams, createAsset, updateAsset, deleteAsset }
}

export default useAssets

import React from 'react'
import { useTranslation } from 'react-i18next'
import useAuth from '../../utils/hooks/useAuth'
import ChangePasswordForm from './ChangePasswordForm'

function ChangePassword() {
    const { changePassword } = useAuth()
    const { t } = useTranslation()

    const handleChangePassword = async (formValues, formikProps) => {
        await changePassword(formValues, t)
        formikProps.resetForm()
    }

    return (
        <div id="kt_content" className="content d-flex flex-column flex-column-fluid">
            <div id="kt_content_container" className="container-xxl">
                <div className="card">
                    <div className="card-body pt-0">
                        <ChangePasswordForm handleSubmit={ handleChangePassword } />
                    </div>
                </div>
            </div>
        </div >
    )
}

export default ChangePassword

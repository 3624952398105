import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Field, Form, Formik } from 'formik'
import Fields from '../custom/Fields'
import { EmployeeUserValidation } from '../../utils/validations/formValidations'


function UserForm(props) {
    const loggedInUser = useSelector(state => state.authData.loggedInUser)
    const { t } = useTranslation()
    const { initialValues, handleSubmit, handleDelete, organizationOptions } = props

    const deleteData = async (formikProps) => {
        formikProps.setSubmitting(true)
        await handleDelete(initialValues._id, formikProps)
        formikProps.setSubmitting(false)
    }

    return (
        <Formik
            initialValues={ initialValues }
            enableReinitialize
            validationSchema={ EmployeeUserValidation(loggedInUser.type, initialValues) }
            onSubmit={ async (fields, { ...formikProps }) => {
                return await handleSubmit(fields, formikProps)
            } }
        >
            { ({ isSubmitting, ...formikProps }) => (
                <Form className='form fv-plugins-bootstrap5 fv-plugins-framework'>
                    <div className="d-flex flex-column scroll-y" style={ { maxHeight: '637px' } } data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_add_comment_header" data-kt-scroll-wrappers="#kt_modal_add_comment_scroll" data-kt-scroll-offset="300px">
                        {
                            loggedInUser.type === 'ADMIN' &&
                            <div className="fv-row mb-7 fv-plugins-icon-container">
                                <Field { ...formikProps } type='select' placeholder={ t('Type') } label={ t('Type') } name='type' as={ Fields } options={
                                    [
                                        {
                                            label: 'ADMIN',
                                            value: 'ADMIN'
                                        },
                                        {
                                            label: 'OWNER',
                                            value: 'OWNER'
                                        },
                                        {
                                            label: 'EMPLOYEE',
                                            value: 'EMPLOYEE'
                                        }
                                    ]
                                } />
                            </div>
                        }
                        {
                            loggedInUser.type === 'ADMIN' && formikProps?.values?.type?.value === 'OWNER' &&
                            <>
                                <div className="fv-row mb-7 fv-plugins-icon-container">
                                    <Field { ...formikProps } type='text' placeholder={ t('Organization') } label={ t('Organization') } name='organization' as={ Fields } isDisabled={ initialValues._id } />
                                </div>
                                {
                                    !initialValues._id &&
                                    <div className="fv-row mb-7 fv-plugins-icon-container">
                                        <Field { ...formikProps } type='select' placeholder={ t('PlanType') } label={ t('PlanType') } name='planType' as={ Fields } options={
                                            [
                                                {
                                                    label: 'FREE',
                                                    value: 'FREE'
                                                },
                                                {
                                                    label: 'STANDARD',
                                                    value: 'STANDARD'
                                                },
                                                {
                                                    label: 'ENTERPRISE',
                                                    value: 'ENTERPRISE'
                                                }
                                            ]
                                        } />
                                    </div>
                                }
                            </>
                        }
                        {
                            loggedInUser.type === 'ADMIN' && formikProps?.values?.type?.value === 'EMPLOYEE' &&
                            <div className="fv-row mb-7 fv-plugins-icon-container">
                                <Field { ...formikProps } type='select' placeholder={ t('Organization') } label={ t('Organization') } name='organization' as={ Fields } options={ organizationOptions } />
                            </div>
                        }
                        <div className="fv-row mb-7 fv-plugins-icon-container">
                            <Field { ...formikProps } type='text' placeholder={ t('FirstName') } label={ t('FirstName') } name='firstName' as={ Fields } />
                        </div>
                        <div className="fv-row mb-7 fv-plugins-icon-container">
                            <Field { ...formikProps } type='text' placeholder={ t('MiddleName') } label={ t('MiddleName') } name='middleName' as={ Fields } />
                        </div>
                        <div className="fv-row mb-7 fv-plugins-icon-container">
                            <Field { ...formikProps } type='text' placeholder={ t('LastName') } label={ t('LastName') } name='lastName' as={ Fields } />
                        </div>
                        <div className="fv-row mb-7 fv-plugins-icon-container">
                            <Field { ...formikProps } type='text' placeholder={ t('Email') } label={ t('Email') } name='email' as={ Fields } isDisabled={ initialValues._id } />
                        </div>
                        {
                            !initialValues._id &&
                            <div className="fv-row mb-7 fv-plugins-icon-container">
                                <Field { ...formikProps } type='password' placeholder={ t('Password') } label={ t('Password') } name='password' as={ Fields } />
                            </div>
                        }
                    </div>
                    <div className="text-end pt-15">
                        <button className="btn btn-light me-3" disabled={ isSubmitting } type="reset" data-bs-dismiss="modal" data-kt-users-modal-action="close">{ t('Cancel') }</button>
                        { initialValues._id && initialValues._id !== loggedInUser._id &&
                            < button className="btn btn-danger mx-3" disabled={ isSubmitting } type="button" onClick={ () => deleteData(formikProps) } data-kt-indicator={ `${ isSubmitting ? 'on' : 'off' }` }>
                                <span className="indicator-label">{ t('Delete') }</span> <span className="indicator-progress">
                                    { t('PleaseWait') }
                                    <span className="spinner-border spinner-border-sm align-middle ms-2" />
                                </span>
                            </button>
                        }
                        <button className="btn btn-primary" disabled={ isSubmitting } type="submit" data-kt-indicator={ `${ isSubmitting ? 'on' : 'off' }` } data-kt-users-modal-action="submit">
                            <span className="indicator-label">{ t(initialValues._id ? 'Update' : 'Create') }</span> <span className="indicator-progress">
                                { t('PleaseWait') }
                                <span className="spinner-border spinner-border-sm align-middle ms-2" />
                            </span>
                        </button>
                    </div>
                </Form>
            )
            }
        </Formik >
    )
}

export default UserForm

import React, { useEffect, useRef, useState } from 'react'
import { io } from 'socket.io-client'
import Select from 'react-select'
import useConversation from '../../utils/hooks/useConversation'
import { ObjectToSelect } from '../../utils/transformer/Select'

import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { selectStyles } from '../custom/FieldsStyles'
import useOrganizations from '../../utils/hooks/useOrganizations'
import ConversationList from './ConversationList'
import MessagesComposeContainer from './MessagesComposeContainer'
import useChatConnection from '../../utils/hooks/useChatConnection'
import useMessages from '../../utils/hooks/useMessages'

const socket = io(process.env.REACT_APP_API_URL + '/ws')
function Chat() {
    const { t } = useTranslation()
    const loggedInUser = useSelector(state => state.authData.loggedInUser)
    const [activeOrganization, setActiveOrganization] = useState(null)
    const [activeChatConnection, setActiveChatConnection] = useState(null)
    const [activeConversation, setActiveConversation] = useState(null)
    const [organizationOptions, setOrganizationOptions] = useState([])
    const [chatConnectionOptions, setChatConnectionOptions] = useState([])
    const [conversations, setConversations] = useState(null)
    const [archivedConversations, setArchivedConversations] = useState(null)
    const [searchedMessages, setSearchedMessages] = useState(null)
    const [view, setView] = useState({
        navigation: 'leftPanel',
        displayConversation: true
    })
    const [isLoading, setIsLoading] = useState(false)
    const [conversationParams, setConversationParams] = useState({
        page: 1,
        limit: 10,
        sort: 'updatedAt:desc',
        organization: loggedInUser.organization,
        isArchive: false
    })
    const [searchQueryParams, setSearchQueryParams] = useState({
        page: 1,
        limit: 10,
        sort: 'updatedAt:desc',
        organization: loggedInUser.organization,
        message: ''
    })
    const { getConversationsByParams, updateConversation } = useConversation()
    const { getMessagesByParams } = useMessages()
    const { getChatConnectionByParams } = useChatConnection()
    const { getOrganizationByParams } = useOrganizations()
    const searchQueryMessageRef = useRef()

    useEffect(() => {
        socket.on(loggedInUser.organization ? 'list_' + JSON.stringify(loggedInUser.organization) : 'conversations', () => getConversationsData())
        getOrganizationData()
        getChatConnectionData()
        return () => {
            socket.off(loggedInUser.organization ? 'list_' + JSON.stringify(loggedInUser.organization) : 'conversations')
        }
    }, [])

    useEffect(() => {
        getConversationsData()
    }, [conversationParams])

    useEffect(() => {
        if (searchQueryMessageRef.current.value) {
            getMessagesData()
        }
    }, [searchQueryParams])

    const getConversationsData = async () => {
        setIsLoading(true)
        const response = await getConversationsByParams(conversationParams)
        if (conversationParams.isArchive) {
            setArchivedConversations(response.data)
        } else {
            setConversations(response.data)
        }
        setIsLoading(false)
    }

    const getMessagesData = async () => {
        setView(oldState => ({
            ...oldState,
            displayConversation: false
        }))
        setIsLoading(true)
        const response = await getMessagesByParams({
            ...searchQueryParams,
            message: searchQueryMessageRef.current.value
        })
        setSearchedMessages(response.data)
        setIsLoading(false)
    }

    const showMore = (view) => {
        if (view.displayConversation) {
            setConversationParams(oldParams => {
                return {
                    ...oldParams,
                    limit: oldParams.limit + 10
                }
            })
        } else {
            setSearchQueryParams(oldParams => {
                return {
                    ...oldParams,
                    limit: oldParams.limit + 10
                }
            })
        }
    }

    const getOrganizationData = async () => {
        const response = await getOrganizationByParams({
            page: 1,
            limit: 100
        })
        if (response.data && response.data.docs.length > 0) {
            const options = response.data.docs.map(doc => ObjectToSelect(`${ doc.name }`, doc))
            setOrganizationOptions(options)
        }
    }

    const getChatConnectionData = async () => {
        const response = await getChatConnectionByParams({
            page: 1,
            limit: 100,
            organization: loggedInUser.organization
        })
        if (response.data && response.data.docs.length > 0) {
            const options = response.data.docs.map(doc => ObjectToSelect(`${ doc.name }`, doc))
            setChatConnectionOptions(options)
        }
    }

    const handleSetData = (data) => {
        setActiveConversation(data)
        setView(oldState => ({
            ...oldState,
            navigation: 'rightPanel'
        }))
    }

    const handleCloseConversation = () => {
        setView(oldState => ({
            oldState,
            navigation: 'leftPanel'
        }))
    }

    const handleUpdateConversationList = (conversation) => {
        const oldDatas = conversationParams.isArchive ? {   ...archivedConversations } : {   ...conversations }
        const elementIndex = oldDatas.docs.findIndex(doc => doc._id === conversation._id)
        elementIndex >= 0 ? oldDatas.docs[elementIndex] = conversation : oldDatas.docs.push(conversation)
        oldDatas.docs = oldDatas.docs.sort((a, b) => (a.updatedAt > b.updatedAt) ? -1 : ((b.updatedAt > a.updatedAt) ? 1 : 0))
        if (conversationParams.isArchive) {
            setArchivedConversations(oldDatas)
        } else {
            setConversations(oldDatas)
        }
    }

    const handleMoveConversation = (conversation, isArchive) => {
        const elementIndex = conversations.docs.findIndex(doc => doc._id === conversation._id)
        const oldDatas = { ...conversations }
        oldDatas.docs.splice(elementIndex, 1)
        setConversations(oldDatas)
        setActiveConversation(null)
        updateConversation({
            ...conversation,
            chatConnection: conversation.chatConnection._id,
            lastMessage: conversation?.lastMessage?._id,
            organization: conversation.organization?._id,
            updatedBy: conversation?.updatedBy?._id,
            user: conversation?.user?._id,
            isArchive: isArchive,
            skipChangeUpdatedAt: true
        })
    }

    return (
        <div id='kt_content' className='content d-flex flex-column flex-column-fluid'>
            <div id='kt_content_container' className='container-xxl'>
                <div className='card'>
                    <div className='card-header border-0 pt-6'>
                        <div className='card-title w-100 me-0'>
                            <div className='row gx-0 gx-md-3 w-100 align-items-center my-1'>
                                {
                                    loggedInUser.type === 'ADMIN' &&
                                    <div className='col-12 col-md-3 mb-2 mb-md-0'>
                                        <Select
                                            styles={ selectStyles }
                                            className={ 'form-control py-1 form-control-solid me-2' }
                                            onChange={ data => {
                                                setActiveOrganization(data)
                                                setConversationParams(state => ({
                                                    ...state,
                                                    asset: 1,
                                                    organization: data?.value?._id
                                                }))
                                            } }
                                            placeholder={ t('SelectOrganization') }
                                            value={ activeOrganization }
                                            options={ organizationOptions }
                                            isClearable={ true }
                                            isSearchable={ true }
                                        />
                                    </div>
                                }
                                <div className='col-12 col-md-3 mb-2 mb-md-0'>
                                    <Select
                                        styles={ selectStyles }
                                        className={ 'form-control py-1 form-control-solid me-2' }
                                        onChange={ data => {
                                            setActiveChatConnection(data)
                                            setConversationParams(state => ({
                                                ...state,
                                                asset: 1,
                                                chatConnection: data?.value?._id
                                            }))
                                        } }
                                        placeholder={ t('SelectChatConnection') }
                                        value={ activeChatConnection }
                                        options={ chatConnectionOptions }
                                        isClearable={ true }
                                        isSearchable={ true }
                                    />
                                </div>
                                <div className='col-12 col-md-4 mb-5 mb-md-0'>
                                    <div className='input-group'>
                                        <input 
                                            ref={ searchQueryMessageRef }
                                            type='text' 
                                            disabled={ isLoading }
                                            className='form-control' 
                                            placeholder={ t('SearchMessages') }
                                            aria-label={ t('SearchMessages') }
                                            aria-describedby='search-messages'
                                            onKeyDown={ e => {
                                                if (e.code === 'Enter' && !e.shiftKey && !e.nativeEvent.isComposing) {
                                                    e.preventDefault()
                                                    getMessagesData()
                                                }
                                            } }
                                        />
                                        <span className='input-group-text' id='search-messages'>
                                            <i className='las la-times fs-1 cursor-pointer me-2' disabled={ isLoading } onClick={ () => {
                                                searchQueryMessageRef.current.value = ''
                                                setView(oldState => ({
                                                    ...oldState,
                                                    displayConversation: true
                                                }))
                                            } } />
                                            <i 
                                                className='las la-search fs-1 cursor-pointer' 
                                                disabled={ isLoading }
                                                onClick={ () => getMessagesData() }
                                            />
                                        </span>
                                    </div>
                                </div>
                                { process.env.REACT_APP_ENVIRONMENT === 'development' &&
                                    <div className={ `col-12 ${loggedInUser.type !== 'ADMIN' ? 'col-md-5' : 'col-md-2'} d-flex justify-content-end align-item-end`} >
                                        <div className='dropdown d-flex align-items-center'>
                                            <i
                                                className='las la-cog fs-1 cursor-pointer'
                                                id='chatOption'
                                                data-bs-toggle='dropdown'
                                                aria-expanded='false'
                                            />
                                            <ul className='dropdown-menu' aria-labelledby='chatOption'>
                                                <li className='cursor-pointer' onClick={ () => {
                                                    setConversations(null)
                                                    setActiveConversation(null)
                                                    setConversationParams(oldParams => ({
                                                        ...oldParams,
                                                        isArchive: !oldParams.isArchive,
                                                        page: 1,
                                                        size: 10,
                                                        limit: 10
                                                    }))
                                                    setView(oldState => ({
                                                        ...oldState,
                                                        displayConversation: true,
                                                        navigation: 'leftPanel'
                                                    }))
                                                } }>
                                                    <span className='dropdown-item'>{ t(conversationParams.isArchive ? 'ViewActiveConversations' : 'ViewArchiveConversations') }</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div >
                    <div className='card-body pt-0'>
                        <div className='row'>
                            <div className={ `${ view.navigation === 'leftPanel' ? 'd-block' : 'd-none d-md-block' } col-12 col-md-3` }>
                                <ConversationList
                                    activeConversation={ activeConversation }
                                    conversations={ conversations }
                                    archivedConversations={ archivedConversations }
                                    handleSetData={ handleSetData }
                                    handleUpdateList={ handleUpdateConversationList }
                                    handleMoveConversation={ handleMoveConversation }
                                    showMore={ showMore }
                                    isLoading={ isLoading }
                                    searchedMessages={ searchedMessages }
                                    view={ view }
                                    conversationParams={ conversationParams }
                                />
                            </div>
                            <div className={ `${ view.navigation === 'rightPanel' ? 'd-block' : 'd-none d-md-block' } col-12 col-md-9` }>
                                <MessagesComposeContainer
                                    activeConversation={ activeConversation }
                                    handleCloseConversation={ handleCloseConversation }
                                    conversationParams={ conversationParams }
                                />
                            </div>
                        </div>
                    </div>
                </div >
            </div >
        </div >
    )
}

export default Chat
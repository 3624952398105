import React, { useEffect, useState } from 'react'
import { encodeURI } from 'js-base64'
import { Field, Form, Formik } from 'formik'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import CopyToClipboard from 'react-copy-to-clipboard'
import Fields from '../custom/Fields'
import { PageValidation } from '../../utils/validations/formValidations'
import { toast } from '../../utils/toast/toast'
import { AssetTableMini } from '../assetManagement/AssetTable'
import useAssets from '../../utils/hooks/useAssets'
import useChatConnection from '../../utils/hooks/useChatConnection'
import { ObjectToSelect } from '../../utils/transformer/Select'


function PageForm(props) {
    const loggedInUser = useSelector(state => state.authData.loggedInUser)
    const { t } = useTranslation()
    const { getAssetsByParams } = useAssets()
    const { getChatConnectionByParams } = useChatConnection()
    const { initialValues, handleSubmit, handleDelete, organizationOptions, cancelForm } = props
    const [assetData, setAssetData] = useState(null)
    const [assetDataIsLoading, setAssetDataIsLoading] = useState(false)
    const [assetParams, setAssetParams] = useState({
        page: 1,
        limit: 50,
        sort: 'createdAt:desc',
        organization: initialValues?.organization?.value?._id,
    })
    const [chatConnectionOptions, setChatConnectionOptions] = useState(null)
    const chatConnectionParams = {
        page: 1,
        limit: 50,
        sort: 'createdAt:desc',
        organization: initialValues?.organization?.value?._id,
    }
    const url = `${ process.env.REACT_APP_RAYLEE_CLIENT_BASE_URL }/pages/${ initialValues.organization?.value?.code }/${ initialValues?.uniqueTitle }`

    useEffect(() => {
        if (assetParams.organization) {
            getAssetsData()
            getChatConnectionData()
        } else {
            setAssetData(null)
            setChatConnectionOptions(null)
        }
    }, [assetParams])

    const deleteData = async (formikProps) => {
        window.Swal.fire({
            text: t('AreYouSureYouWantToDeleteThisPage'),
            icon: 'warning',
            buttonsStyling: false,
            showCancelButton: true,
            confirmButtonText: t('OkGotIt'),
            cancelButtonText: t('Cancel'),
            customClass: {
                confirmButton: 'btn btn-danger',
                cancelButton: 'btn btn-primary'
            }
        }).then(async response => {
            if (response.isConfirmed) {
                formikProps.setSubmitting(true)
                await handleDelete(initialValues._id, formikProps)
                formikProps.setSubmitting(false)
            }
        })
    }

    const getAssetsData = async () => {
        setAssetDataIsLoading(true)
        const response = await getAssetsByParams(assetParams)
        setAssetData(response.data)
        setAssetDataIsLoading(false)
    }

    const getChatConnectionData = async () => {
        const response = await getChatConnectionByParams(chatConnectionParams)
        const options = response.data.docs.map(doc => ObjectToSelect(doc.name, doc))
        setChatConnectionOptions(options)
    }

    const previewData = async (values) => {
        const data = {
            title: values?.title,
            category: values?.category,
            metadataDescription: values?.metadataDescription,
            metadataKeywords: values?.metadataKeywords?.map(data => data.value + ' '),
            bodyContent: values?.bodyContent,
        }
        const props = JSON.stringify(data)
        const encodedProps = encodeURI(props)
        localStorage.setItem('pagePreviewData', encodedProps)
        const win = window.open(`${ process.env.PUBLIC_URL }/page/preview`, '_blank')
        win.focus()
    }

    const getSpecificAssetPageData = (page) => {
        setAssetParams(oldParams => {
            return {
                ...oldParams,
                page: page.selected + 1
            }
        })
    }

    return (
        <Formik
            initialValues={ initialValues }
            enableReinitialize
            validationSchema={ PageValidation(loggedInUser.type) }
            onSubmit={ async (fields, { ...formikProps }) => {
                return await handleSubmit(fields, formikProps)
            } }
        >
            { ({ isSubmitting, values, ...formikProps }) => (
                <Form className='form col-11 fv-plugins-bootstrap5 fv-plugins-framework'>
                    <div className='d-flex flex-column scroll-y me-n7 px-7' style={ { maxHeight: '637px' } } data-kt-scroll='true' data-kt-scroll-activate='{default: false, lg: true}' data-kt-scroll-max-height='auto' data-kt-scroll-offset='300px'>
                        {
                            initialValues._id &&
                            <div className='fv-row mb-7 fv-plugins-icon-container'>
                                <span className='form-label fs-6 fw-bolder text-dark'>
                                    { `URL: ${ url }` }
                                </span>
                                <CopyToClipboard text={ url } onCopy={ () => toast('info', '', 'Copied!') }>
                                    <button className="btn btn-light ms-3" type="button">
                                        Copy
                                        <img src="assets/media/icons/duotune/communication/com008.svg" />
                                    </button>
                                </CopyToClipboard>
                            </div>
                        }
                        <div className='d-flex row'>
                            <div className='col-8'>
                                {
                                    initialValues._id &&
                                    <div className='fv-row mb-7 fv-plugins-icon-container'>
                                        <Field
                                            { ...formikProps }
                                            type='select'
                                            placeholder={ t('ChatConnection') }
                                            label={ t('ChatConnection') }
                                            name='chatConnection'
                                            as={ Fields }
                                            options={ chatConnectionOptions }
                                        />
                                    </div>
                                }
                                {
                                    loggedInUser.type === 'ADMIN' &&
                                    <div className='fv-row mb-7 fv-plugins-icon-container'>
                                        <Field
                                            { ...formikProps }
                                            type='select'
                                            placeholder={ t('Organization') }
                                            label={ t('Organization') }
                                            name='organization'
                                            as={ Fields }
                                            options={ organizationOptions }
                                            isDisabled={ initialValues._id }
                                            customOnChangeHandler={ (data) => {
                                                setAssetParams(state => ({
                                                    ...state,
                                                    organization: data?.value?._id
                                                }))
                                            } }
                                        />
                                    </div>
                                }
                                <div className='fv-row mb-7 fv-plugins-icon-container'>
                                    <Field { ...formikProps } type='text' placeholder={ t('Title') } label={ t('Title') } name='title' as={ Fields } />
                                </div>
                                <div className='fv-row mb-7 fv-plugins-icon-container'>
                                    <Field { ...formikProps } type='text' placeholder={ t('Category') } label={ t('Category') } name='category' as={ Fields } />
                                </div>
                                <div className='fv-row mb-7 fv-plugins-icon-container'>
                                    <Field { ...formikProps } type='text' placeholder={ t('MetadataDescription') } label={ t('MetadataDescription') } name='metadataDescription' as={ Fields } />
                                </div>
                                <div className='fv-row mb-7 fv-plugins-icon-container'>
                                    <Field { ...formikProps } type='tag' placeholder={ t('MetadataKeywords') } label={ t('MetadataKeywords') } name='metadataKeywords' as={ Fields } />
                                </div>
                                <div className="fv-row mb-7 fv-plugins-icon-container">
                                    <Field { ...formikProps } type='checkbox' label={ t('IsPrivate') } name='isPrivate' as={ Fields } />
                                </div>
                                <div className="fv-row mb-7 fv-plugins-icon-container">
                                    <Field { ...formikProps } type='password' label={ t('Password') } placeholder={ t('Password') } name='password' as={ Fields } />
                                </div>
                                <div className="fv-row mb-7 fv-plugins-icon-container">
                                    <Field { ...formikProps } type='tag' placeholder={ t('EmailRecipients') } label={ t('EmailRecipients') } name='emailRecipients' as={ Fields } />
                                </div>
                                <div className="fv-row mb-7 fv-plugins-icon-container">
                                    <Field { ...formikProps } type='checkbox' label={ t('SendEmailNotificationIfGainVisitor') } name='sendEmailNotificationIfGainVisitor' as={ Fields } />
                                </div>
                            </div>
                            <div className='col'>
                                <div className='w-100 position-sticky' style={ { top: 0 } }>
                                    <div className='d-flex justify-content-between'>
                                        <span className='form-label fs-6 fw-bolder text-dark'>
                                            { t('AssetManagement') }
                                        </span>
                                    </div>
                                    <AssetTableMini
                                        datas={ assetData }
                                        params={ assetParams }
                                        getSpecificPageData={ getSpecificAssetPageData }
                                        isLoading={ assetDataIsLoading }
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='fv-row mb-7 fv-plugins-icon-container'>
                            <Field { ...formikProps } type='rte-editor' placeholder={ t('Content') } label={ t('Content') } name='bodyContent' as={ Fields } />
                        </div>
                    </div>
                    <div className='text-end pt-15'>
                        <button className='btn btn-light me-3' disabled={ isSubmitting } type='reset' onClick={ cancelForm }>{ t('Cancel') }</button>
                        { initialValues._id &&
                            <button className="btn btn-danger mx-3" disabled={ isSubmitting } type="button" onClick={ () => deleteData(formikProps) } data-kt-indicator={ `${ isSubmitting ? 'on' : 'off' }` }>
                                <span className="indicator-label">{ t('Delete') }</span> <span className="indicator-progress">
                                    { t('PleaseWait') }
                                    <span className="spinner-border spinner-border-sm align-middle ms-2" />
                                </span>
                            </button>

                        }
                        <button className='btn btn-primary' disabled={ isSubmitting } type='submit' data-kt-indicator={ `${ isSubmitting ? 'on' : 'off' }` }>
                            <span className='indicator-label'>{ initialValues._id ? t('Update') : t('Create') }</span> <span className='indicator-progress'>
                                { t('PleaseWait') }
                                <span className='spinner-border spinner-border-sm align-middle ms-2' />
                            </span>
                        </button>
                        <button className="btn btn-secondary ms-2" disabled={ isSubmitting } type="button" onClick={ () => previewData(values) } data-kt-indicator={ `${ isSubmitting ? 'on' : 'off' }` }>
                            <span className="indicator-label">{ t('Preview') }</span> <span className="indicator-progress">
                                { t('PleaseWait') }
                                <span className="spinner-border spinner-border-sm align-middle ms-2" />
                            </span>
                        </button>
                    </div>
                </Form>
            ) }
        </Formik>
    )
}

export default PageForm

import { useField } from 'formik'
import { useEffect } from 'react'
import reactCSS from 'reactcss'
import { SketchPicker } from 'react-color'
import CreatableSelect from 'react-select/creatable'
import Select from 'react-select'
import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { selectStyles, tagStyles } from './FieldsStyles'

export default function Fields(props) {
    const { t } = useTranslation()
    const { type, placeholder, label, isDisabled, customOnChangeHandler } = props
    const inputRef = useRef()
    const [field, meta, helpers] = useField(props)
    const [colorPickerState, setColorPickerState] = useState({
        displayColorPicker: false,
        hex: field.value
    })

    useEffect(() => {
        if (type === 'rte-editor' && window.tinymce) {
            if (window.tinymce) {
                window.tinymce.remove()
            }
            window.tinymce.init({
                selector: '#rte-editor',
                plugins: ['code', 'link', 'image', 'lists'],
                toolbar: 'code | undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist | forecolor backcolor casechange permanentpen formatpainter removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media pageembed template link anchor codesample | a11ycheck ltr rtl | showcomments addcomment',
                allow_script_urls: true,
                extended_valid_elements: 'style,link[href|rel],script[src|async|defer|type|charset|language],div[*],button[*]',
                custom_elements: 'style,link,~link,script,~script',
                setup: (editor) => {
                    editor.on('change', function () {
                        helpers.setValue(editor.getBody().innerHTML)
                        helpers.setTouched(true)
                    })
                }
            })
            setTimeout(() => {
                window?.tinymce?.activeEditor?.setContent(field.value)
            }, 1000)
        }
    }, [])

    switch (type) {
    case 'text':
    case 'password':
        return (
            <>
                <label className='form-label fs-6 fw-bolder text-dark'>{ label }</label>
                <input
                    { ...field }
                    className={ 'form-control form-control-solid' }
                    autoComplete='off'
                    placeholder={ placeholder }
                    type={ type }
                    disabled={ isDisabled }
                />
                {
                    meta.touched && meta.error &&
                        <div className="text-danger">
                            { t(meta.error) }
                        </div>
                }
            </>
        )
    case 'select':
        return (
            <>
                <label className='form-label fs-6 fw-bolder text-dark'>{ label }</label>
                <Select
                    { ...field }
                    styles={ selectStyles }
                    className={ 'form-control py-1 form-control-solid' }
                    onChange={ data => {
                        helpers.setValue(data)
                        customOnChangeHandler && customOnChangeHandler(data, props)
                    } }
                    onBlur={ () => helpers.setTouched(true) }
                    placeholder={ placeholder }
                    options={ props.options }
                    isClearable={ true }
                    isSearchable={ true }
                    isDisabled={ isDisabled }
                />
                {
                    meta.touched && meta.error &&
                        <div className="text-danger">
                            { t(meta.error) }
                        </div>
                }
            </>
        )
    case 'tag':
        return (
            <>
                <label className='form-label fs-6 fw-bolder text-dark'>{ label }</label>
                <CreatableSelect
                    { ...field }
                    onChange={ data => helpers.setValue(data) }
                    isMulti
                    styles={ tagStyles }
                    placeholder={ placeholder }
                    className={ 'form-control py-1 form-control-solid' }
                    onBlur={ () => helpers.setTouched(true) }
                />
                {
                    meta.touched && meta.error &&
                        <div className="text-danger">
                            { meta.error }
                        </div>
                }
            </>
        )
    case 'input-video': {
        const handleChange = (e) => {
            const file = e.target.files[0]
            if (file) {
                const validFileTypes = 'video/mp4,video/x-m4v,video/quicktime'.split(',')
                const isFileTypeValid = validFileTypes.includes(file.type)
                const value = {
                    objectUrl: URL.createObjectURL(file),
                    file: file
                }
                if (!isFileTypeValid) {
                    inputRef.current.value = ''
                } else {
                    helpers.setError('')
                    helpers.setValue(value)
                }
            }
            setTimeout(() => {
                helpers.setTouched(true)
                props.validateField(field.name)
            }, 100)
        }

        const runValidation = (e) => {
            const element = e.target
            const duration = element.duration

            if (e.target.videoWidth > e.target.videoHeight) {
                element.pause()
                element.removeAttribute('src')
                element.load()
                helpers.setValue('')
                window.Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: t('RecommendedRatio'),
                })
            } else if (duration > props.secondsDurationLimit) {
                element.pause()
                element.removeAttribute('src')
                element.load()
                helpers.setValue('')
                window.Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: t('MaxVideoDurationIsAMinuteAnd30Seconds'),
                })
            }
        }

        return (
            <>
                {
                    field.value && field.value.objectUrl ?
                        <video src={ field.value.objectUrl } className="w-100" controls onLoadedMetadata={ runValidation } />
                        :
                        <div className="dropzone" onClick={ () => inputRef.current.click() }>
                            <div className="dz-message needsclick">
                                <i className="bi bi-file-earmark-arrow-up text-primary fs-3x"></i>
                                <div className="ms-4">
                                    <h3 className="fs-5 fw-bolder text-gray-900 mb-1">{ t('ClickToUpload') }</h3>
                                    <span className="fs-7 fw-bold text-gray-400">{ t('RecommendedRatio') }</span>
                                </div>
                            </div>
                        </div>
                }
                <input
                    { ...field }
                    className="invisible d-none"
                    ref={ inputRef }
                    type="file"
                    accept="video/mp4,video/x-m4v,video/*"
                    onChange={ handleChange }
                    value=""
                />
                {
                    meta.touched && meta.error &&
                        <div className="text-danger">
                            { t(meta.error) }
                        </div>
                }
            </>
        )
    }
    case 'input-video-landscape': {
        const handleChange = (e) => {
            const file = e.target.files[0]
            if (file) {
                const validFileTypes = 'video/mp4,video/x-m4v,video/quicktime'.split(',')
                const isFileTypeValid = validFileTypes.includes(file.type)
                const value = {
                    objectUrl: URL.createObjectURL(file),
                    file: file
                }
                if (!isFileTypeValid) {
                    inputRef.current.value = ''
                } else {
                    helpers.setError('')
                    helpers.setValue(value)
                }
            }
            setTimeout(() => {
                helpers.setTouched(true)
                props.validateField(field.name)
            }, 100)
        }

        const runValidation = (e) => {
            const element = e.target
            const duration = element.duration

            if (e.target.videoHeight > e.target.videoWidth) {
                element.pause()
                element.removeAttribute('src')
                element.load()
                helpers.setValue('')
                window.Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: t('RecommendedRatioLandscape'),
                })
            } else if (duration > props.secondsDurationLimit) {
                element.pause()
                element.removeAttribute('src')
                element.load()
                helpers.setValue('')
                window.Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: t('MaxVideoDurationIsThreeMinutes'),
                })
            }
        }

        return (
            <>
                {
                    field.value && field.value.objectUrl ?
                        <video id={ `${ field.name }-reference` } src={ field.value.objectUrl } className="w-100" controls onLoadedMetadata={ runValidation } />
                        :
                        <div className="dropzone" onClick={ () => inputRef.current.click() }>
                            <div className="dz-message needsclick">
                                <i className="bi bi-file-earmark-arrow-up text-primary fs-3x"></i>
                                <div className="ms-4">
                                    <h3 className="fs-5 fw-bolder text-gray-900 mb-1">{ t('ClickToUpload') }</h3>
                                    <span className="fs-7 fw-bold text-gray-400">{ t('RecommendedRatioLandscape') }</span>
                                </div>
                            </div>
                        </div>
                }
                <input
                    { ...field }
                    className="invisible d-none"
                    ref={ inputRef }
                    type="file"
                    accept="video/mp4,video/x-m4v,video/*"
                    onChange={ handleChange }
                    value=""
                />
                {
                    meta.touched && meta.error &&
                        <div className="text-danger">
                            { t(meta.error) }
                        </div>
                }
            </>
        )
    }
    case 'input-image': {
        const handleChange = (e) => {
            const file = e.target.files[0]
            if (file) {
                const validFileTypes = 'image/png,image/svg,image/jpeg'.split(',')
                const isFileTypeValid = validFileTypes.includes(file.type)
                const value = {
                    objectUrl: URL.createObjectURL(file),
                    file: file
                }
                if (!isFileTypeValid) {
                    inputRef.current.value = ''
                } else {
                    helpers.setError('')
                    helpers.setValue(value)
                }
            }
        }

        return (
            <>
                {
                    field.value && field.value.objectUrl ?
                        <img src={ field.value.objectUrl } className="w-100" />
                        :
                        <div className="dropzone" onClick={ () => inputRef.current.click() }>
                            <div className="dz-message needsclick">
                                <i className="bi bi-file-earmark-arrow-up text-primary fs-3x"></i>
                                <div className="ms-4">
                                    <h3 className="fs-5 fw-bolder text-gray-900 mb-1">{ t('ClickToUpload') }</h3>
                                    <span className="fs-7 fw-bold text-gray-400">{ t('RecommendedRatio') }</span>
                                </div>
                            </div>
                        </div>
                }
                <input
                    { ...field }
                    className="invisible d-none"
                    ref={ inputRef }
                    type="file"
                    accept="image/png,image/svg,image/jpeg,image/*"
                    onChange={ handleChange }
                    value=""
                />
                {
                    meta.touched && meta.error &&
                        <div className="text-danger">
                            { t(meta.error) }
                        </div>
                }
            </>
        )
    }
    case 'checkbox':
        return (
            <>
                <span className='form-label fs-6 fw-bolder text-dark'>{ `${ label }` }</span>
                <div className="form-check form-check-custom form-check-solid me-10">
                    <input { ...field } className="form-check-input h-30px w-30px" type="checkbox" id={ field.name } checked={ field.value } />
                    <label className="form-check-label fs-6 fw-bolder text-dark" htmlFor={ field.name }>
                        { t('Yes') }
                    </label>
                </div>
                {
                    meta.touched && meta.error &&
                        <div className="text-danger">
                            { meta.error }
                        </div>
                }
            </>
        )
    case 'color-picker': {
        const colorPickerStyles = reactCSS({
            default: {
                swatch: {
                    padding: '0px',
                    background: field.value,
                    height: '42.5px',
                    cursor: 'pointer',
                },
                popover: {
                    position: 'absolute',
                    zIndex: '2',
                    bottom: '50px'
                },
                cover: {
                    position: 'fixed',
                    top: '0px',
                    right: '0px',
                    bottom: '0px',
                    left: '0px',
                },
            },
        })

        const handleClick = () => {
            setColorPickerState(state => ({
                ...state,
                displayColorPicker: !state.displayColorPicker
            }))
        }

        const handleClose = () => {
            setColorPickerState({ displayColorPicker: false })
        }

        const handleColorPickerChange = (color) => {
            helpers.setValue(color.hex)
        }

        return (
            <>
                <label className='form-label fs-6 fw-bolder text-dark'>{ label }</label>
                <div className="form-control form-control-solid" style={ colorPickerStyles.swatch } onClick={ handleClick } />
                { colorPickerState.displayColorPicker && <div style={ colorPickerStyles.popover }>
                    <div style={ colorPickerStyles.cover } onClick={ handleClose } />
                    <SketchPicker
                        { ...field }
                        color={ field.value }
                        onChange={ handleColorPickerChange }
                    />
                </div> }
            </>
        )
    }
    case 'rte-editor': {
        return (
            <>
                <label className='form-label fs-6 fw-bolder text-dark'>{ label }</label>
                <div id='rte-editor-toolbar' />
                <textarea id='rte-editor' className='tox-target' />
                {
                    meta.touched && meta.error &&
                        <div className="text-danger">
                            { t(meta.error) }
                        </div>
                }
            </>
        )
    }
    default:
        return <></>
    }
}

export const ArrayStringToSelectMulti = (array) => {
    return array.map(data => ({
        label: data,
        value: data
    }))
}

export const ObjectToSelect = (label, value) => {
    return {
        label: label,
        value: value
    }
}

export const SelectMultiToArrayString = (selectObject) => {
    return selectObject.map(data => data.value)
}
export const tagStyles = {
    control: (provided) => ({
        ...provided,
        border: 0,
        backgroundColor: 'transparent',
        boxShadow: 'none',
        color: '#5e6278'
    }),
    indicatorSeparator: () => ({
        display: 'none'
    }),
    dropdownIndicator: () => ({
        display: 'none'
    }),
    placeholder: (provided) => ({
        ...provided,
        color: '#5e6278'
    }),
    menu: () => ({
        display: 'none'
    }),
    valueContainer: (provided) => ({
        ...provided,
        padding: 0,
        color: '#5e6278'
    }),
    multiValue: (provided) => ({
        ...provided,
        backgroundColor: '#EFF2F5'
    }),
    multiValueRemove: (provided) => ({
        ...provided,
        color: '#000'
    })
}

export const selectStyles = {
    control: (provided) => ({
        ...provided,
        border: 0,
        backgroundColor: 'transparent',
        boxShadow: 'none',
    }),
    placeholder: (provided) => ({
        ...provided,
        color: '#a1a5b7'
    }),
    menu: (provided) => ({
        ...provided,
        transform: 'translate(-10px, 0px)'
    }),
    valueContainer: (provided) => ({
        ...provided,
        padding: 0,
    })
}
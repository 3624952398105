import axios from 'axios'
import { ENDPOINTS } from '../constants/endpoint'
import { toast } from '../toast/toast'

function useRaqs() {
    const getRaqsByParams = async (params) => {
        return axios.get(process.env.REACT_APP_API_URL + ENDPOINTS.RAQS_QUERY, {
            params: params
        }).catch(error => toast('error', '', error.response.data.message))
    }

    const updateRaq = async (requestBody) => {
        return axios.put(process.env.REACT_APP_API_URL + ENDPOINTS.RAQS + '/' + requestBody._id, requestBody).catch(error => toast('error', '', error.response.data.message))
    }

    return { getRaqsByParams, updateRaq }
}

export default useRaqs

import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FORM_INITIAL_VALUES } from '../../utils/constants/formValues'
import useComments from '../../utils/hooks/useComments'
import CommentsForm from './CommentsForm'
import CommentsTable from './CommentsTable'

function Comments() {
    const { t } = useTranslation()
    const [params, setParams] = useState({
        page: 1,
        limit: 10
    })
    const [data, setData] = useState(FORM_INITIAL_VALUES.COMMENT)
    const [datas, setDatas] = useState(null)
    const { getCommentsByParams, deleteComment } = useComments()
    const modalCloseBtnRef = useRef()

    useEffect(() => {
        getData()
    }, [params])

    const getData = async () => {
        const response = await getCommentsByParams(params)
        setDatas(response.data)
    }

    const getSpecificPageData = (page) => {
        setParams(oldParams => {
            return {
                ...oldParams,
                page: page.selected + 1
            }
        })
    }

    const handleCreateData = async () => {
        // TODO: Create comment
        // const data = {
        //     name: formData.name,
        //     CommentsCluster: formData.CommentsCluster.value,
        //     websiteUrl: formData.websiteUrl,
        //     owner: loggedInUser._id
        // }
        // const response = await createComments(data)
        // if (response.data._id) {
        //     formikProps.resetForm()
        //     modalCloseBtnRef.current.click()
        // }
        // getData()
        // return response
    }

    const handleDelete = async (id, formikProps) => {
        await deleteComment(id)
        formikProps.resetForm()
        modalCloseBtnRef.current.click()
        getData()
    }

    const handleSetData = (data) => {
        let tempData = { ...data }
        setData(tempData)
    }

    return (
        <div id="kt_content" className="content d-flex flex-column flex-column-fluid">
            <div id="kt_content_container" className="container-xxl">
                <div className="card">
                    <div className="card-header border-0 pt-6">
                        <div className="card-title">
                            <div className="d-flex align-items-center position-relative my-1">
                                <span className="svg-icon svg-icon-1 position-absolute ms-6">
                                    <img src="assets/media/icons/duotune/general/gen004.svg" />
                                </span>
                                <input className="form-control form-control-solid w-250px ps-14" type="text" placeholder={t('SearchComments')} data-kt-user-table-filter="search" />
                            </div>
                        </div>
                        <div className="card-toolbar">
                            <div className="d-flex justify-content-end" data-kt-user-table-toolbar="base">
                                <button className="btn btn-primary" type="button" onClick={() => setData(FORM_INITIAL_VALUES.COMMENT)} data-bs-toggle="modal" data-bs-target="#kt_modal_add_Comments">{t('AddComments')}</button>
                            </div>
                            <div id="kt_modal_add_Comments" className="modal fade" tabIndex={-1}>
                                <div className="modal-dialog modal-dialog-centered mw-650px">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h2 className="fw-bolder">{`${data._id ? t('Comment') : t('CreateComments')}`}</h2>
                                            <div className="btn btn-icon btn-sm btn-active-icon-primary" ref={modalCloseBtnRef} data-bs-dismiss="modal" data-kt-users-modal-action="close">
                                                <span className="svg-icon svg-icon-1">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                        <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="black"></rect>
                                                        <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="black"></rect>
                                                    </svg>
                                                </span>
                                            </div>
                                        </div>
                                        <div className="modal-body scroll-y mx-5 mx-xl-15 my-7">
                                            <CommentsForm initialValues={data} handleSubmit={handleCreateData} handleDelete={handleDelete} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card-body pt-0">
                        <CommentsTable datas={datas} handleSetData={handleSetData} getSpecificPageData={getSpecificPageData} params={params} />
                    </div>
                </div>
            </div>
        </div >
    )
}

export default Comments

import axios from 'axios'
import { toast } from '../../utils/toast/toast'
import { setupAxiosInterceptors } from '../../config/axios'
import { ENDPOINTS } from '../../utils/constants/endpoint'
import {
    LOGIN_FAILED,
    LOGIN_LOADING,
    LOGIN_SUCCESS,
    REGISTER_FAILED,
    REGISTER_LOADING,
    LOGOUT,
} from './actionTypes'

export const login = (formData, successCb, t) => {
    return async dispatch => {
        dispatch({
            type: LOGIN_LOADING
        })
        return await axios.post(process.env.REACT_APP_API_URL + ENDPOINTS.LOGIN, formData)
            .then(response => {
                localStorage.setItem('access_tkn', response.data.accessToken)
                setupAxiosInterceptors()
                dispatch({
                    type: LOGIN_SUCCESS,
                    payload: response.data
                })
                if (successCb) {
                    successCb()
                }
            })
            .catch(error => {
                console.log(error.response)
                toast('error', '', t(error.response.data.message))
                dispatch({
                    type: LOGIN_FAILED
                })
            })
    }
}

export const register = (formData, successCB, t) => {
    return async dispatch => {
        dispatch({
            type: REGISTER_LOADING
        })
        return await axios.post(process.env.REACT_APP_API_URL + ENDPOINTS.REGISTER, formData)
            .then(() => {
                successCB()
            })
            .catch(error => {
                console.log(error.response)
                toast('error', '', t(error.response.data.message))
                dispatch({
                    type: REGISTER_FAILED
                })
            })
    }
}

export const logout = () => {
    return dispatch => {
        localStorage.removeItem('access_tkn')
        dispatch({
            type: LOGOUT
        })
    }
}
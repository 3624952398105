import React from 'react'
import { Field, Form, Formik } from 'formik'
import Fields from '../custom/Fields'
import { CommentValidation } from '../../utils/validations/formValidations'

import { useTranslation } from 'react-i18next'

function CommentsForm(props) {
    const { t } = useTranslation()
    const { initialValues, handleSubmit, handleDelete } = props

    const deleteData = async (formikProps) => {
        formikProps.setSubmitting(true)
        await handleDelete(initialValues._id, formikProps)
        formikProps.setSubmitting(false)
    }

    return (
        <Formik
            initialValues={ initialValues }
            enableReinitialize
            validationSchema={ CommentValidation }
            onSubmit={ async (fields, { ...formikProps }) => {
                return await handleSubmit(fields, formikProps)
            } }
        >
            { ({ isSubmitting, ...formikProps }) => (
                <Form className='form fv-plugins-bootstrap5 fv-plugins-framework'>
                    { initialValues._id &&
                        <video src={ process.env.REACT_APP_AWS_CLOUDFRONT_DOMAIN + '/' + initialValues.video.key } className="w-100 mb-4" controls style={ { maxHeight: '640px' } } />
                    }
                    <div className="d-flex flex-column scroll-y" style={ { maxHeight: '637px' } } data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_add_comment_header" data-kt-scroll-wrappers="#kt_modal_add_comment_scroll" data-kt-scroll-offset="300px">
                        <div className="fv-row mb-7 fv-plugins-icon-container">
                            <Field { ...formikProps } type='text' placeholder={ t('Comment') } label={ t('Comment') } name='text' as={ Fields } isDisabled />
                        </div>
                    </div>
                    <div className="text-end pt-15">
                        <button className="btn btn-light me-3" disabled={ isSubmitting } type="reset" data-bs-dismiss="modal" data-kt-users-modal-action="close">{ t('Cancel') }</button>
                        { initialValues._id &&
                            <button className="btn btn-danger mx-3" disabled={ isSubmitting } type="button" onClick={ () => deleteData(formikProps) } data-kt-indicator={ `${ isSubmitting ? 'on' : 'off' }` }>
                                <span className="indicator-label">{ t('Delete') }</span> <span className="indicator-progress">
                                    { t('PleaseWait') }
                                    <span className="spinner-border spinner-border-sm align-middle ms-2" />
                                </span>
                            </button>
                        }
                    </div>
                </Form>
            ) }
        </Formik>
    )
}

export default CommentsForm

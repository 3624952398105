import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useParams, useHistory } from 'react-router-dom'
import useAuth from '../../utils/hooks/useAuth'

function Activate() {
    const { t } = useTranslation()
    const params = useParams()
    const history = useHistory()
    const [isLoading, setIsLoading] = useState(true)
    const { activateAccount } = useAuth()

    useEffect(async () => {
        if (params) {
            await handleActivateAccount(params)
        }
    }, [])

    const handleActivateAccount = async () => {
        setIsLoading(true)
        const response = await activateAccount(params)
        setIsLoading(false)
        if (!response) {
            history.replace('/')
        }
    }

    if (isLoading) {
        return <></>
    }

    return (
        <div className='d-flex flex-column flex-root' >
            <div className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed' style={ { backgroundImage: 'url(\'https://raylee-test.s3.ap-northeast-1.amazonaws.com/assets/icons/sigma/14.png\')' } }>
                <div className='d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20'>
                    <div className='mb-12'>
                        <img className='h-60px' src='https://raylee-test.s3.ap-northeast-1.amazonaws.com/assets/icons/Raylee+Logo.svg' alt='Logo' />
                    </div>
                    <div className='pt-lg-10 mb-10'>
                        <h1 className='fw-bolder fs-2qx text-dark mb-7'>{ t('WelcomeToRaylee') }</h1>
                        <div className='fw-bold fs-3 text-gray-400 mb-15'>{ t('StartUploadingVideosNow') }
                            <br />{ t('LoginYourCredentialsInTheLoginPageToStart') }</div>
                        <div className='text-center'>
                            <Link to='/' className='btn btn-lg btn-primary fw-bolder'>{ t('GoToLoginPage') }</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Activate

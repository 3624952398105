import React, { useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import { useSelector } from 'react-redux'
import { io } from 'socket.io-client'
import useMessages from '../../utils/hooks/useMessages'
import ComposeMessage from './ComposeMessage'
import MessageList from './MessageList'

const socket = io(process.env.REACT_APP_API_URL + '/ws')
function MessagesComposeContainer({ activeConversation, handleCloseConversation, conversationParams }) {
    const loggedInUser = useSelector(state => state.authData.loggedInUser)
    const { ref, inView } = useInView({
        threshold: 0,
    })
    const { getMessagesByParams } = useMessages()
    const [isLoading, setIsLoading] = useState(false)
    const [messages, setMessages] = useState([])
    const params = {
        conversation: activeConversation?._id,
        sort: 'createdAt:desc',
        limit: 15,
        groupBy: 'createdAt'
    }

    useEffect(() => {
        if (activeConversation) {
            getMoreData()
        }
    }, [inView])

    useEffect(() => {
        setMessages([])
        if (activeConversation) {
            getData()
            socket.on('conversations')
            socket.on(JSON.stringify(activeConversation._id), data => {
                // ignore messages that came from admins
                if (data.user.type === 'ANONYMOUS') {
                    setMessages(oldState => {
                        const isOld = oldState.find(state => state.date === 'Latest')
                        if (isOld) {
                            oldState[0].data.push(data)
                            return oldState
                        } else {
                            const data = {
                                date: 'Latest',
                                data: [data]
                            }
                            oldState.unshift(data)
                            return oldState
                        }
                    })
                    const el = document.getElementById('message-list-container')
                    el.scrollTo({ top: 0, behavior: 'smooth' })
                }
            })
        }
        return () => {
            if (activeConversation) {
                socket.off(activeConversation._id)
            }
            socket.off('conversations')
        }
    }, [activeConversation])

    const getData = async () => {
        setIsLoading(true)
        const response = await getMessagesByParams({
            ...params,
            conversation: activeConversation._id,
            localeTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
        })
        setMessages(response.data.docs)
        setIsLoading(false)
    }

    const getMoreData = async () => {
        const response = await getMessagesByParams({
            ...params,
            limit: params.limit + 10,
            conversation: activeConversation._id
        })
        setMessages(response.data.docs)
    }

    const sendMessage = async (text) => {
        socket.emit('conversations', {
            user: loggedInUser._id,
            organization: params.organization,
            conversation: activeConversation._id,
            message: text
        })
        setMessages(oldState => {
            const isOld = oldState.find(state => state.date === 'Latest')
            const data = {
                message: text,
                user: loggedInUser,
                conversation: activeConversation,
                createdAt: new Date()
            }
            if (isOld) {
                oldState[0].data[oldState[0].data.length + 1] = data
                return oldState
            } else {
                oldState.unshift({
                    date: 'Latest',
                    data: [data]
                })
                return oldState
            }
        })
    }

    return (
        <>
            <MessageList
                lastMessageRef={ ref }
                messages={ messages }
                isLoading={ isLoading }
                conversationParams={ conversationParams }
            />
            <ComposeMessage
                activeConversation={ activeConversation }
                handleCloseConversation={ handleCloseConversation }
                sendMessage={ sendMessage }
            />
        </>
    )
}

export default MessagesComposeContainer
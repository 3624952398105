import axios from 'axios'
import { ENDPOINTS } from '../constants/endpoint'
import { toast } from '../toast/toast'

function useUsers() {
    const getUsersByParams = async (params) => {
        return axios.get(process.env.REACT_APP_API_URL + ENDPOINTS.GET_USERS, {
            params: params
        }).catch(error => toast('error', '', error.response.data.message))
    }

    const createUser = async (requestBody, t) => {
        return axios.post(process.env.REACT_APP_API_URL + ENDPOINTS.CREATE_USER, requestBody).catch(error => toast('error', '', t(error?.response?.data?.message)))
    }

    const updateUser = async (requestBody) => {
        return axios.put(process.env.REACT_APP_API_URL + ENDPOINTS.USERS + '/' + requestBody._id, requestBody).catch(error => toast('error', '', error.response.data.message))
    }

    const deleteUser = async (id) => {
        return axios.delete(process.env.REACT_APP_API_URL + ENDPOINTS.AUTH + '/' + id).catch(error => toast('error', '', error.response.data.message))
    }

    return { getUsersByParams, createUser, updateUser, deleteUser }
}

export default useUsers

import React, { useEffect, useState } from 'react'
import TimeAgo from 'react-timeago'
import { io } from 'socket.io-client'
import { useInView } from 'react-intersection-observer'
import { useTranslation } from 'react-i18next'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
const socket = io(process.env.REACT_APP_API_URL + '/ws')

function ConversationList({ 
    conversations, 
    archivedConversations,
    conversationParams,
    isLoading, 
    handleSetData, 
    handleUpdateList, 
    handleMoveConversation, 
    showMore, 
    activeConversation, 
    searchedMessages,
    view
}) {
    const { ref, inView } = useInView({
        threshold: 0,
    })
    const [activeMessage, setActiveMessage] = useState(null)

    useEffect(() => {
        if (inView) {
            showMore(view)
        }
    }, [inView])

    return (
        <div style={ { height: '70vh', overflowY: 'scroll', overflowX: 'hidden' } } className='w-100'>
            { view.displayConversation ? conversationParams.isArchive ? 
                archivedConversations?.docs?.map(doc => {
                    return (
                        <Conversation
                            key={ doc.updatedAt }
                            doc={ doc }
                            handleSetData={ handleSetData }
                            handleUpdateList={ handleUpdateList }
                            handleMoveConversation={ handleMoveConversation }
                            isActive={ activeConversation?._id === doc._id }
                        />
                    )
                }) :
                conversations?.docs?.map(doc => {
                    return (
                        <Conversation
                            key={ doc.updatedAt }
                            doc={ doc }
                            handleSetData={ handleSetData }
                            handleUpdateList={ handleUpdateList }
                            handleMoveConversation={ handleMoveConversation }
                            isActive={ activeConversation?._id === doc._id }
                        />
                    )
                }) 
                : searchedMessages?.docs?.map(doc => {
                    return (
                        <SearchMessage 
                            key={ doc.updatedAt }
                            doc={ doc }
                            handleSetData={ handleSetData }
                            setActiveMessage={ setActiveMessage }
                            isActive={ activeMessage?._id === doc._id }
                        />
                    )
                })
            }
            <div ref={ ref } style={ { height: '10px' } } />
            { isLoading && <Skeleton count={ 1 } /> }
        </div>
    )
}

export default ConversationList

const Conversation = ({ doc, handleSetData, handleUpdateList, handleMoveConversation, isActive }) => {
    const { t } = useTranslation()

    useEffect(() => {
        socket.on('conversation_' + JSON.stringify(doc._id), (data) => {
            handleUpdateList(data)
        })
        return () => {
            socket.off('conversation_' + JSON.stringify(doc._id))
        }
    }, [])

    return (
        <div
            className={ `${ isActive ? 'bg-secondary' : 'bg-light' } rounded p-2 my-2 d-flex flex-column position-relative cursor-pointer` }
        >
            <div onClick={ () => {
                handleSetData(doc)
            } }>
                <span>{ doc.chatConnection.name }</span>
                { doc.isArchive &&
                    <div>
                        <span className='w-auto badge badge-info'>{ t('ArchivedConversation') }</span>
                    </div>
                }
                <div>
                    <div className='conversation-text-preview'>
                        <i>
                            <span>{ doc.lastMessage?.message }</span>
                        </i>
                    </div>
                    <span>
                        <i>
                            <TimeAgo live={ false } date={ new Date(doc.updatedAt) } />
                        </i>
                    </span>
                </div>
            </div>
            { process.env.REACT_APP_ENVIRONMENT === 'development' &&
                <div className='position-absolute h-100 d-flex align-items-center dropdown' style={ { right: 0 } }>
                    <img
                        src='/assets/media/icons/duotune/general/gen053.svg'
                        className='me-2 dropdown-toggle conversation-option-icon'
                        id={`conversationOption-${doc._id}`}
                        data-bs-toggle='dropdown'
                        aria-expanded='false'
                    />
                    <ul className='dropdown-menu' aria-labelledby={`conversationOption-${doc._id}`}>
                        <li onClick={ () => handleMoveConversation(doc, !doc.isArchive) }>
                            <span className='dropdown-item'>{ t(doc.isArchive ? 'MoveToActive' : 'MoveToArchive') }</span>
                        </li>
                    </ul>
                </div>
            }
        </div>
    )
}

const SearchMessage = ({doc, isActive, handleSetData, setActiveMessage}) => {
    return (
        <div
            className={ `${ isActive ? 'bg-secondary' : 'bg-light' } rounded p-2 my-2 d-flex flex-column position-relative cursor-pointer` }
            onClick={ () => {
                handleSetData(doc.conversation)
                setActiveMessage(doc)
            } }
        >
            <span>{ doc.conversation.chatConnection.name }</span>
            <div>
                <div className='conversation-text-preview'>
                    <i>
                        <span>{ doc.message }</span>
                    </i>
                </div>
                <span>
                    <i>
                        <TimeAgo live={ false } date={ new Date(doc.updatedAt) } />
                    </i>
                </span>
            </div>
        </div>
    )
}
import React from 'react'
import Linkify from 'react-linkify'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

function MessageList({ messages, lastMessageRef, isLoading }) {

    return (
        <div id='message-list-container' className='d-flex flex-column-reverse border position-relative' style={ { height: '55vh', overflowY: 'scroll', overflowX: 'hidden' } }>
            { isLoading
                ? <Skeleton count={ 30 } />
                : <>
                    { messages && messages.map((groupMessages, index) => {
                        return (
                            <div key={ index }>
                                <div className='d-flex justify-content-center'>
                                    <span className='my-2'>
                                        { groupMessages.date }
                                    </span>
                                </div>
                                { groupMessages.data.map(message => {
                                    if (message && message.user) {
                                        return (
                                            <div
                                                key={ message.createdAt }
                                                className={ `d-flex ${ message.user.type === 'ANONYMOUS' ? 'justify-content-start' : 'justify-content-end' }` }
                                            >
                                                {
                                                    message.user.type !== 'ANONYMOUS' &&
                                            <div className='d-flex align-items-end mb-2 me-1'>
                                                <i>
                                                    { new Date(message.createdAt).toLocaleTimeString('en-US', {
                                                        hour12: false,
                                                        hour: 'numeric',
                                                        minute: 'numeric'
                                                    }) }
                                                </i>
                                            </div>
                                                }
                                                <div
                                                    className={ `p-2 my-2 rounded text-white ${ message.user.type === 'ANONYMOUS' ? 'bg-secondary text-dark ms-2' : 'bg-info me-2' }` }
                                                    style={ {
                                                        maxWidth: '50%',
                                                        whiteSpace: 'pre-line',
                                                        wordWrap: 'break-word'
                                                    } }
                                                >
                                                    <Linkify target='_blank'>
                                                        { message.message }
                                                    </Linkify>
                                                </div>
                                                {
                                                    message.user.type === 'ANONYMOUS' &&
                                            <div className='d-flex align-items-end mb-2 ms-1'>
                                                <i>
                                                    { new Date(message.createdAt).toLocaleTimeString('en-US', {
                                                        hour12: false,
                                                        hour: 'numeric',
                                                        minute: 'numeric'
                                                    }) }
                                                </i>
                                            </div>
                                                }
                                            </div>
                                        )
                                    }
                                })
                                }
                            </div>
                        )
                    })
                    }
                    <div ref={ lastMessageRef } style={ { height: '10px' } } />
                </>
            }
        </div >
    )
}

export default MessageList
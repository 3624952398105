import React, { useEffect, useState } from 'react'
import { decode } from 'js-base64'
import { Helmet } from 'react-helmet'

function PagePreview() {
    const [data, setData] = useState(null)

    useEffect(() => {
        const props = localStorage.getItem('pagePreviewData')
        document.body.style.backgroundColor = '#fff'
        if (props) {
            const decodedProps = decode(props)
            setData(JSON.parse(decodedProps))
        }
    }, [])


    return (
        <>
            {
                data &&
                <Helmet>
                    <meta charSet='UTF-8' />
                    <meta httpEquiv='X-UA-Compatible' content='IE=edge' />
                    <meta name='viewport' content='initial-scale=1.0,user-scalable=no' />
                    <meta name='format-detection' content='telephone=no' />
                    <title>{ data.title }</title>
                    <meta name='description' content={ data.metadataDescription } />
                    <meta name='keywords' content={ data.metadataKeywords.join(',') } />
                    <meta name='copyright' content='x-climb株式会社' />
                </Helmet>
            }
            <div dangerouslySetInnerHTML={ { __html: data?.bodyContent } } />
        </>
    )
}

export default PagePreview
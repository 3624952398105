import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'

function LandingPage() {
    const [ready, setReady] = useState(false)

    useEffect(() => {
        window.rayleeChatConnection = '633c52ba42781bfb44209e50',
        window.organization = '6197711d4229ea8a3a949a07'
        setTimeout(() => {
            setReady(true)
        }, 1000)
        return () => setReady(false)
    }, [])

    return (
        <div className={ `${ !ready && 'invisible' }` }>
            <Helmet>
                <meta charSet='UTF-8' />
                <meta httpEquiv='X-UA-Compatible' content='IE=edge' />
                <meta name='viewport' content='initial-scale=1.0,user-scalable=no' />
                <meta name='format-detection' content='telephone=no' />
                <title>
                    Raylee-レイリー｜縦型ショート動画をWebサイトに！ストーリーテリングサービス
                </title>
                <meta name='keywords' content />
                <meta name='copyright' content='x-climb株式会社' />
                <link
                    href='https://use.fontawesome.com/releases/v5.6.1/css/all.css'
                    rel='stylesheet'
                />
                <link href={ `${ process.env.PUBLIC_URL }/external-assets/css/style.css` } rel='stylesheet' />
                <script defer src={ `${ process.env.PUBLIC_URL }/external-assets/js/toggle.js` }></script>
                <script defer src={ `${ process.env.PUBLIC_URL }/external-assets/js/pagetop.js` }></script>
                <script src='https://cdn.socket.io/4.5.0/socket.io.min.js' integrity='sha384-7EyYLQZgWBi67fBtVxw60/OWl1kjsfrPFcaU0pp0nAh+i8FD068QogUvg85Ewy1k' crossOrigin='anonymous'></script>
                <script src='https://www.raylee.page/raylee-init-chat.js' />
            </Helmet>
            <div className='top_mv'>
                <header id='header'>
                    <div className='header_inner'>
                        <div className='logo'>
                            <a href='#'>
                                <img src='./external-assets/images/logo01.svg' alt='Raylee' />
                            </a>
                        </div>
                        <div className='sp_menu'>
                            <div className='hamburger'>
                                <span />
                                <span />
                                <span />
                            </div>
                            <nav className='top_nav'>
                                <ul>
                                    <li><a href='#merit'>導入メリット</a></li>
                                    <li><a href='#comparison'>他ツールとの比較</a></li>
                                    <li><a href='#case'>事例</a></li>
                                    <li><a href='#plan'>費用・プラン</a></li>
                                    <li><a href='#flow'>導入の流れ</a></li>
                                    <li><a href='#faq'>よくある質問</a></li>
                                    <li className='sp_on'>
                                        <a href='https://www.x-climb.com/contact.html' target='_blank' rel='noreferrer'>
                        お問い合わせ
                                        </a>
                                    </li>
                                    <li className='sp_on'>
                                        <a href='https://www.x-climb.com/' target='_blank' rel='noreferrer'>
                        運営会社
                                        </a>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                        <ul className='top_btn'>
                            <li>
                                <a className='font-size-16px' href='https://www.raylee.one/' target='_blank' rel='noreferrer'>
                    ログイン
                                </a>
                            </li>
                            <li>
                                <a className='font-size-16px' href='https://www.raylee.one/register' target='_blank' rel='noreferrer'>
                    新規登録
                                </a>
                            </li>
                        </ul>
                    </div>
                </header>
                <div className='maintitle'>
                    <div className='txtbox'>
                        <p>縦型動画xチャットで売上拡大</p>
                        <p className='last'>
                    世界初のマーケティングDX
                        </p>
                        <div className='linkbtn'>
                            <a href='https://www.raylee.one/register' target='_blank' rel='noreferrer'>
                  無料ではじめる
                            </a>
                        </div>
                    </div>
                </div>
                <div className='txt_message'>
                    <img src='./external-assets/images/message.svg' alt='世界初のマーケティングDX' />
                </div>
            </div>
            <iframe style={{height: '670px', width: '100%'}} src='https://raylee-client.herokuapp.com/business/619ef329c03e1e1e5ddeb528' />
            <section className='box01'>
                <div className='inner'>
                    <div className='txtbox'>
                        <h2>
                Webサイト上に縦型ショート動画を表示し、
                            <br />
                            <em> 縦型動画xチャット</em>で  <br />
                リード獲得率や受注率を劇的に高める<br />
                世界初のマーケティングDXツールです
                        </h2>
                        <p>
                自社のECサイトやLP、企業ページや採用ページに<br />
                簡単に縦型ショート動画を表示し活用することが可能です
                        </p>
                    </div>
                    <div className='imgbox'>
                        <img src='./external-assets/images/top_img02.png' width={658} height={377} />
                    </div>
                </div>
            </section>
            <section className='problem'>
                <div className='inner'>
                    <h2>マーケティングにおける、これまでの課題</h2>
                    <ul className='problem_list'>
                        <li><img src='./external-assets/images/problem_img01.jpg' width={330} height={115} /><div className='mark'><p>Problem<em>1</em></p></div><div className='txt'><p>フォーム営業やメルマガの<br />
                            <em>返信率が低い(0.3%未満)</em></p></div></li>
                        <li><img src='./external-assets/images/problem_img02.jpg' width={330} height={115} /><div className='mark'><p>Problem<em>2</em></p></div><div className='txt'><p><em>初回返信後も<br />商談獲得率が低い(10%未満)</em></p></div></li>
                        <li><img src='./external-assets/images/problem_img03.jpg' width={330} height={115} /><div className='mark'><p>Problem<em>3</em></p></div><div className='txt'><p>動画訴求は効果的だが、<br />制作に時間や費用をかけられない</p></div></li>
                        <li><img src='./external-assets/images/problem_img04.jpg' width={330} height={115} /><div className='mark'><p>Problem<em>4</em></p></div><div className='txt'><p>製品ごとにLPで訴求したいが、<br />企画から制作までディレクションするのは困難</p></div></li>
                        <li><img src='./external-assets/images/problem_img05.jpg' width={330} height={115} /><div className='mark'><p>Problem<em>5</em></p></div><div className='txt'><p>営業資料を添付して送っているが、閲覧数を計測できない</p></div></li>
                        <li><img src='./external-assets/images/problem_img06.jpg' width={330} height={115} /><div className='mark'><p>Problem<em>6</em></p></div><div className='txt'><p>一方的に情報を送るだけで、<br />即座にやり取りする手段がない</p></div></li>
                    </ul>
                </div>{/*//.inner*/}
            </section>{/*//.problrem*/}
            <section className='resolution'>
                <h2><img src='./external-assets/images/esolution_ttl.svg' alt='その問題を全てRayleeで解決できます！' /></h2>
                <div className='inner'>
                    <div className='img'><img src='./external-assets/images/resolution_img01.jpg' width={800} height={470} alt='' /></div>
                    <div className='txt'><ul className='resolution'>
                        <li>フォーム営業やメルマガの<em>返信率が上がる(10倍改善の実績あり)</em></li>
                        <li><em>初回返信後も商談獲得率が上がる(3倍改善の実績あり)</em></li>
                        <li>縦型動画はスマホ撮影のため、制作に時間や費用がかからない</li>
                        <li>製品ごとに販促ページをブログ感覚で、簡単に作成できる</li>
                        <li>ドメイン・サーバー不要で手間もかからない</li>
                        <li>販促ページの閲覧数を計測できる</li>
                        <li>販促ページ内のチャット機能により、即座にやり取りできる</li>
                    </ul></div>
                </div>{/*//.inner*/}
            </section>  {/*//.resolution*/}
            <section className='box02'>
                <div className='inner'>
                    <div className='imgbox'>
                        <img src='./external-assets/images/top_img22.png' width={489} height={750} />
                    </div>
                    <div className='txtbox'>
                        <h2>簡単に動画+チャット付き<br />
                販促ページを作成</h2>
                        <ul className='box02_list'>
                            <li>ドメイン、サーバー不要で販促ページ作成<br />(ブログ感覚)</li>
                            <li>HTML、CSSコーディングも可能</li>
                            <li>販促動画埋め込みが簡単に可能</li>
                            <li>動画は縦型と横型に対応</li>
                            <li>チャット機能が実装可能</li>
                            <li>ページのアクセス分析機能も充実</li>
                        </ul>
                    </div>
                </div>
            </section>
            <section className='merit' id='merit'>
                <div className='inner'>
                    <h2>Raylee 4つの導入メリット</h2>
                    <i className='fas fa-video' />
                    <p>
              現在、TikTokやInstagramのリール、YouTubeのショート動画等、
                        <br />
              15秒の縦型ショート動画が最も見られやすく、YouTube動画の埋め込みとは異なる価値を提供します
                    </p>
                    <ul className='list01'>
                        <li>
                            <img src='./external-assets/images/icon01.png' width={215} height={215} />
                            <h3>モバイルファースト</h3>
                            <p>
                  モバイルファーストな縦型動画なのでスマホページとの相性抜群
                            </p>
                        </li>
                        <li>
                            <img src='./external-assets/images/icon02.png' width={215} height={215} />
                            <h3>フィードバックがある</h3>
                            <p>
                  いいねやQ＆A機能、チャット機能があるためユーザーが反応しやすい
                            </p>
                        </li>
                        <li>
                            <img src='./external-assets/images/icon03.png' width={215} height={215} />
                            <h3>コンバージョンアップ</h3>
                            <p>
                  CTAボタンとチャットを設置できるため、コンバージョンアップに繋がる
                            </p>
                        </li>
                        <li>
                            <img src='./external-assets/images/icon04.png' width={215} height={215} />
                            <h3>再生のしやすさ</h3>
                            <p>
                  カジュアルなショート動画を想起させ、圧倒的に再生されやすい
                            </p>
                        </li>
                    </ul>
                </div>
            </section>
            <section className='comparison' id='comparison'>
                <div className='inner'>
                    <h2>他ツールとの比較</h2>
                    <div className='imgbox'>
                        <img src='./external-assets/images/top_img05_03.png' width={1049} />
                    </div>
                </div>
                <br />
                <iframe style={{width: '100%', height: '665px', border: 'none'}} src='https://raylee-client.herokuapp.com/business/62c3d8126ddc6f057ced2204' />
            </section>
            <section className='achievement' id='achievement'>
                <h2>Raylee機能紹介</h2>
                <div className='inner'>
                    <div className='img'>
                        <img src='./external-assets/images/top_img21.jpg' width={510} height={417} alt='' />
                    </div>
                    <div className='txt'>
                        <h3>動画が最後まで視聴された<br />
                回数もカウント</h3>
                        <p>
                Rayleeは、分析機能も充実しています。
                            <br />
                            <em>
                  ●動画再生数<br />
                  ●CTAクリック回数<br />
                  ●視聴完了回数<br />
                  ●ページセッション数<br />
                  ●ページ合計滞在時間 </em>
                            <br />
                など、様々なデータを細かく分析することができます。
                        </p>
                    </div>
                </div>{/*//.inner*/}
                <div className='inner right'>
                    <div className='img'><img src='./external-assets/images/top_img23.png' width={610} height={456} alt='' /></div>
                    <div className='txt'><h3>Q&amp;Aで理解を促す！</h3><p>Rayleeは、能動的なアクションで<br />
                理解を深めます。<br />
                        <br />
                        <em>●コメントのような馴染みあるUI<br />
                  ●複数のQ＆Aを設置可能<br />
                  ●管理画面から簡単編集</em></p>
                    </div>
                </div>
                <div className='inner'>
                    <div className='img'><img src='./external-assets/images/top_img24.png' width={526} height={338} alt='' /></div>
                    <div className='txt'><h3>横型動画にも対応！</h3>
                        <p>Rayleeは、横型動画にも対応しています。<br />
                既にお持ちのYouTube動画を活かすことができます。<br />
                            <em>PCでは横長に、スマホでは縦長に自動変換！</em></p>
                    </div>
                </div>{/*//.inner*/}
                <div className='inner right'>
                    <div className='img'><img src='./external-assets/images/top_img25.png' width={485} height={351} alt='' /></div>
                    <div className='txt'><h3>縦型動画ポップアップまで</h3><p>Rayleeには、ポップアップ機能も。<br />
                ECサイトの販促施策として活用いただけます。<br />
                        <em>表示するタイミングも設定することが可能。</em></p>
                    </div>
                </div>
            </section>
            <section className='case' id='case'>
                <h2>
                    <img src='./external-assets/images/top_img06.svg' />
                </h2>
                <p className='txt01'>
            様々な業界の企業様がすでに導入されています！<br className='sp_on' />（一部ご紹介）
                </p>
                <ul className='linklist'>
                    <li>
                        <a href='https://the-chic.jp/' target='_blank' rel='noreferrer'>
                            <img src='./external-assets/images/link_icon02.png' width={170} height={80} alt='' />
                        </a>
                    </li>
                    <li>
                        <img src='./external-assets/images/link_icon03.png' width={93} height={80} alt='' />
                    </li>
                    <li>
                        <a href='https://whitehouse-village.jp/' target='_blank' rel='noreferrer'>
                            <img src='./external-assets/images/link_icon04.png' width={176} height={80} alt='' />
                        </a>
                    </li>
                    <a href='https://whitehouse-village.jp/' target='_blank' rel='noreferrer' />
                    <li>
                        <a href='https://whitehouse-village.jp/' target='_blank' rel='noreferrer' />
                        <a href='https://nosh.jp/recruit' target='_blank' rel='noreferrer'>
                            <img src='./external-assets/images/link_icon05.png' width={144} height={80} alt='' />
                        </a>
                    </li>
                </ul>
                <div className='inner'>
                    <div className='leftarea'>
                        <ul className='list01'>
                            <li>
                                <img src='./external-assets/images/top_img07.jpg' width={253} height={253} />
                                <p>
                    トラベル
                                    <br />
                    旅館ホテル
                                    <br />
                    レジャー施設
                                </p>
                            </li>
                            <li>
                                <img src='./external-assets/images/top_img08.jpg' width={253} height={253} />
                                <p>
                    不動産
                                    <br />
                    戸建て
                                    <br />
                    マンション紹介
                                </p>
                            </li>
                            <li>
                                <img src='./external-assets/images/top_img09.jpg' width={253} height={253} />
                                <p>
                    リフォーム工事
                                    <br />
                    内装水回り
                                </p>
                            </li>
                            <li>
                                <img src='./external-assets/images/top_img10.jpg' width={253} height={253} />
                                <p>
                    美容院
                                    <br />
                    美容師の紹介など
                                </p>
                            </li>
                        </ul>
                    </div>
                    <div className='centerarea'>
                        <p>
                Rayleeは、目的・用途・業種問わず
                            <br />
                様々な業界で活用いただけます！
                            <br />
                            <br />
                現在活用している
                            <br />
                ホームページやECサイト、LPに
                            <br />
                タグを貼り付けるだけで
                            <br />
                ご利用いただけます。
                            <br />
                            <br />
                ぜひRayleeで
                            <br />
                ショート動画を活用し、
                            <br />
                新たな可能性をもたらしませんか？
                        </p>
                        <div className='linkbtn'>
                            <a href='https://www.raylee.one/register' target='_blank' rel='noreferrer'>
                  無料ではじめる
                            </a>
                        </div>
                    </div>
                    <div className='rightarea'>
                        <ul className='list01'>
                            <li>
                                <img src='./external-assets/images/top_img11.jpg' width={253} height={253} />
                                <p>
                    クリニックの紹介
                                    <br />
                    症例やドクター紹介
                                </p>
                            </li>
                            <li>
                                <img src='./external-assets/images/top_img12.jpg' width={253} height={253} />
                                <p>求人サイト</p>
                            </li>
                            <li>
                                <img src='./external-assets/images/top_img13.jpg' width={253} height={253} />
                                <p>
                    経営者の挨拶動画
                                    <br />
                    会社のPRに
                                </p>
                            </li>
                            <li>
                                <img src='./external-assets/images/top_img14.jpg' width={253} height={253} />
                                <p>
                    飲食業界
                                    <br />
                    新しいメニュー紹介
                                </p>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>
            <section className='plan' id='plan'>
                <h2>Raylee 費用・プラン</h2>
                <ul className='list01'>
                    <li>
                        <h3>フリープラン</h3>
                        <p className='price'>
                0
                            <span>円</span>
                        </p>
                        <p className='txt01'>まずは無料でお試しください！</p>
                        <hr />
                        <ul className='check'>
                            <li>動画１本までアップロード可能</li>
                            <li>累計500再生まで動画編集が可能</li>
                            <li>ユーザー追加５人まで</li>
                            <li>サイト追加５個まで</li>
                            <li>ページ作成1つまで</li>
                            <li>チャット作成1つまで</li>
                            <li>いいね・Q&amp;A機能のオンオフ</li>
                            <li>CTAボタン、チャット機能の設置</li>
                            <li>CTAボタンのカスタマイズ</li>
                        </ul>
                        <div className='linkbtn'>
                            <a href='https://www.raylee.one/register' target='_blank' rel='noreferrer'>
                  無料ではじめる
                            </a>
                        </div>
                    </li>
                    <li>
                        <h3>スタンダード</h3>
                        <p className='price'>お問い合わせ</p>
                        <p className='txt01'>
                お客様のご利用用途に合わせて最適なプランを
                            <br />
                提案させていただきます！
                        </p>
                        <hr />
                        <ul className='check'>
                            <li>全ての機能が利用可能</li>
                            <li>
                  月額50,000円〜
                                <br />
                                <span>
                    (サイトのアクセス数などに応じてお見積もり致します)
                                </span>
                            </li>
                            <li>動画３０本までアップロード可能</li>
                            <li>ユーザー追加５人まで</li>
                            <li>サイト追加１０個まで</li>
                            <li>ページ作成５個まで</li>
                            <li>チャット作成５つまで</li>
                            <li>デザインや機能カスタマイズ可能</li>
                            <li>
                  各種SaaSとの連携(MA, SFA/CRM, Web接客,
                  DMP..etc)
                            </li>
                        </ul>
                    </li>
                </ul>
            </section>
            <section className='flow' id='flow'>
                <h2>導入までの流れ</h2>
                <ul className='list01'>
                    <li>
                        <div className='txtarea'>
                            <div className='number'>01</div>
                            <p>フリープランお申込み</p>
                        </div>
                        <img src='./external-assets/images/top_img15.jpg' width={330} height={180} />
                    </li>
                    <li>
                        <div className='txtarea'>
                            <div className='number'>02</div>
                            <p>管理画面の発行</p>
                        </div>
                        <img src='./external-assets/images/top_img16.jpg' width={330} height={180} />
                    </li>
                    <li>
                        <div className='txtarea'>
                            <div className='number'>03</div>
                            <p>
                  タグをWebサイトに
                                <br />
                  貼り付けて利用開始
                            </p>
                        </div>
                        <img src='./external-assets/images/top_img17.jpg' width={330} height={180} />
                    </li>
                </ul>
                <div className='flow_line'>
                    <p>
              フリープランは
                        <br />
              即ご利用いただけます
                    </p>
                </div>
            </section>
            <section className='faq' id='faq'>
                <h2>よくある質問</h2>
                <div className='faq_box'>
                    <input type='checkbox' id='acd-check1' className='acd-check' />
                    <label className='acd-label' htmlFor='acd-check1'>
              フリープランはどのくらいの期間使えますか？
                    </label>
                    <div className='acd-content'>
                        <p>
                期間ではなく、公開された動画の再生回数が累計500再生に達するまで動画編集いただけます。<br />
                また、公開できる動画は1本までとなります。
                        </p>
                    </div>
                    <input type='checkbox' id='acd-check2' className='acd-check' />
                    <label className='acd-label' htmlFor='acd-check2'>
              設定するのは難しいですか？
                    </label>
                    <div className='acd-content'>
                        <p>
                管理画面でタグを発行し、WebサイトやECに貼り付けていただくだけの簡単設定です。<br />
                (所用時間10分程度)
                        </p>
                    </div>
                    <input type='checkbox' id='acd-check3' className='acd-check' />
                    <label className='acd-label' htmlFor='acd-check3'>
              設定できるボタンにはどのような種類がありますか？
                    </label>
                    <div className='acd-content'>
                        <p>
                CTAボタンには、電話番号やURLの設定が可能です。（タップされると電話がかかったり、指定のページへ飛びます）<br />
                また、ボタンやテキストの色も変更することができ、ABテストによる継続的な改善が可能です。
                        </p>
                    </div>
                    <input type='checkbox' id='acd-check4' className='acd-check' />
                    <label className='acd-label' htmlFor='acd-check4'>
              誤って認証メールを、認証前に削除してしまい次に進めません。
                    </label>
                    <div className='acd-content'>
                        <p>
                https://raylee.one/verify/自身のメールアドレス
                            <br />
                をアドレスバーに打ち込んでいただくと、認証メールを再送信するページにアクセスできますので、そちらから進めてください。
                        </p>
                    </div>
                </div>
            </section>
            <section className='customer'>
                <h2>ご利用者様満足度</h2>
                <p className='impact'>
            94.2
                    <span>%</span>
                </p>
                <p>Raylee利用に関するアンケート調査（2022年9月）</p>
            </section>
            <section className='message'>
                <div className='inner'>
                    <div className='txtbox'>
                        <h2>
                Rayleeのちからを、
                            <br />
                信じています
                        </h2>
                        <h3>
                心踊る商品や、革新的サービスの魅力を
                            <br />
                本気で伝えたい。
                        </h3>
                        <p>
                企業の大小に関わらず、人々の胸を打つ素晴らしいものは、
                            <br />
                その魅力を正しく知られるべきです。
                            <br />
                            <br />
                私たちのRayleeを通じて、
                            <br />
                より多くの人々の心が動き、
                            <br />
                インスパイアされることを願って。
                        </p>
                        <p className='ceo'>CEO 飯降敦史</p>
                    </div>
                    <div className='imgbox'>
                        <img src='./external-assets/images/top_img20.png' width={298} height={601} />
                    </div>
                </div>
                <div className='linkbtn'>
                    <a href='https://www.raylee.one/register' target='_blank' rel='noreferrer'>
              無料ではじめる
                    </a>
                </div>
            </section>
            <footer>
                <div className='footer_inner'>
                    <div className='logo'>
                        <img src='./external-assets/images/logo03.svg' alt='レイリー' />
                    </div>
                    <nav className='footer_nav'>
                        <ul>
                            <li>
                                <a href='https://www.x-climb.com/contact.html' target='_blank' rel='noreferrer'>
                    お問い合わせ
                                </a>
                            </li>
                            <li>
                                <a href='https://www.x-climb.com/' target='_blank' rel='noreferrer'>
                    運営会社
                                </a>
                            </li>
                            <li>
                                <a href='/termsAndConditions'>利用規約</a>
                            </li>
                            <li>
                                <a href='https://www.x-climb.com/privacypolicy.html' target='_blank' rel='noreferrer'>
                    プライバシーポリシー
                                </a>
                            </li>
                        </ul>
                    </nav>
                </div>
                <div className='copyright'>
                    <p>Copyrights©Raylee All rights reserved.</p>
                </div>
            </footer>
            <ul className='footer_btn'>
                <li>
                    <a href='https://www.raylee.one/' target='_blank' rel='noreferrer'>
              ログイン
                    </a>
                </li>
                <li>
                    <a href='https://www.raylee.one/register' target='_blank' rel='noreferrer'>
              新規登録
                    </a>
                </li>
            </ul>
        </div>
    )
}

export default LandingPage

import React, { useEffect } from 'react'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { persistor, store } from './config/redux'
import Routes from './Routes'
import './App.css'
import { setupAxiosInterceptors } from './config/axios'
import useLanguage from '../src/utils/hooks/useLanguage'

function App() {
    useLanguage('en')

    useEffect(() => {
        setupAxiosInterceptors()
    }, [])
    return (
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <Routes />
            </PersistGate>
        </Provider>
    )
}

export default App

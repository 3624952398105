import axios from 'axios'
import { ENDPOINTS } from '../constants/endpoint'
import { toast } from '../toast/toast'

function useConversation() {
    const getConversationsByParams = async (params) => {
        return await axios.get(process.env.REACT_APP_API_URL + ENDPOINTS.CONVERSATIONS + ENDPOINTS.QUERY, {
            params: params
        }).catch(error => toast('error', '', error.response.data.message))
    }

    const updateConversation = async (requestBody) => {
        return await axios.put(process.env.REACT_APP_API_URL + ENDPOINTS.CONVERSATIONS + '/' + requestBody._id, requestBody)
            .catch(error => toast('error', '', error.response.data.message))
    }

    return { getConversationsByParams, updateConversation }
}

export default useConversation

import { Field, Form, Formik } from 'formik'
import Fields from '../custom/Fields'
import React from 'react'

import { useTranslation } from 'react-i18next'
import { ChangePasswordValidation } from '../../utils/validations/formValidations'
import { FORM_INITIAL_VALUES } from '../../utils/constants/formValues'

function ChangePasswordForm(props) {
    const { t } = useTranslation()
    const { handleSubmit } = props
    return (
        <Formik
            initialValues={ FORM_INITIAL_VALUES.CHANGE_PASSWORD }
            validationSchema={ ChangePasswordValidation }
            onSubmit={ async (fields, { ...formikProps }) => {
                return await handleSubmit(fields, formikProps)
            } }
        >
            { ({ isSubmitting }) => (
                <Form className='form w-100'>
                    <div className="fv-row my-8">
                        <div className="d-flex flex-stack">
                            <label className="form-label text-dark fs-6 mb-0">{ t('OldPassword') }</label>
                        </div>
                        <Field type='password' placeholder={ t('OldPassword') } name='oldPassword' as={ Fields } />
                    </div>
                    <div className="fv-row mb-8">
                        <div className="d-flex flex-stack">
                            <label className="form-label text-dark fs-6 mb-0">{ t('NewPassword') }</label>
                        </div>
                        <Field type='password' placeholder={ t('NewPassword') } name='newPassword' as={ Fields } />
                    </div>
                    <div className="fv-row mb-8">
                        <div className="d-flex flex-stack">
                            <label className="form-label text-dark fs-6 mb-0">{ t('ConfirmPassword') }</label>
                        </div>
                        <Field type='password' placeholder={ t('ConfirmPassword') } name='confirmPassword' as={ Fields } />
                    </div>
                    <div className='text-center'>
                        <button disabled={ isSubmitting } className='btn btn-lg btn-info w-50 mb-5' type='submit' data-kt-indicator={ `${ isSubmitting ? 'on' : 'off' }` }>
                            <span className='indicator-label'>{ t('Continue') }</span>
                            <span className='indicator-progress'>
                                Please wait...
                                <span className="spinner-border spinner-border-sm align-middle ms-2" />
                            </span>
                        </button>
                    </div>
                </Form>
            ) }
        </Formik>
    )
}

export default ChangePasswordForm

import axios from 'axios'
import { ENDPOINTS } from '../constants/endpoint'
import { toast } from '../toast/toast'

function useMessages() {
    const getMessagesByParams = async (params) => {
        return await axios.get(process.env.REACT_APP_API_URL + ENDPOINTS.MESSAGES + ENDPOINTS.QUERY, {
            params: params
        }).catch(error => toast('error', '', error.response.data.message))
    }

    return { getMessagesByParams }
}

export default useMessages

import React, { useEffect, useRef, useState } from 'react'
import S3FileUpload from 'react-s3'
import { v4 as uuidv4 } from 'uuid'
import Select from 'react-select'
import usePopups from '../../utils/hooks/usePopups'
import PopUpForm from './PopUpForm'
import { FORM_INITIAL_VALUES } from '../../utils/constants/formValues'
import { ArrayStringToSelectMulti, ObjectToSelect, SelectMultiToArrayString } from '../../utils/transformer/Select'
import { getObjectId } from '../../utils/transformer/Object'
import { useTranslation } from 'react-i18next'
import PopUpTable from './PopUpTable'
import PopUpPreview from './PopUpPreview'
import useBusiness from '../../utils/hooks/useBusiness'
import { useSelector } from 'react-redux'
import { selectStyles } from '../custom/FieldsStyles'
import CopyToClipboard from 'react-copy-to-clipboard'
import { toast } from '../../utils/toast/toast'

function PopUp() {
    const { t } = useTranslation()
    const loggedInUser = useSelector(state => state.authData.loggedInUser)
    const [selectedActiveBusiness, setActiveBusiness] = useState(null)
    const [data, setData] = useState(FORM_INITIAL_VALUES.VIDEO)
    const [businessOptions, setBusinessOptions] = useState([])
    const [datas, setDatas] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const [view, setView] = useState('table')
    const [params, setParams] = useState({
        page: 1,
        limit: view === 'table' ? 10 : 100,
        sort: view === 'table' ? 'createdAt:desc' : 'sequence:asc',
        organization: loggedInUser.organization,
        business: selectedActiveBusiness?.value._id
    })
    const { getPopupsByParams, uploadVideo, uploadImage, updatePopUp, deletePopUp, makePopUpActive, makePopUpInactive } = usePopups()
    const { getBusinessByParams } = useBusiness()
    const modalCloseBtnRef = useRef()
    const noRegisteredBusiness = businessOptions.length <= 0
    const rayleeClientUrl = process.env.REACT_APP_ENVIRONMENT === 'development' ? 'https://raylee-client-test.herokuapp.com' : 'https://raylee-client.herokuapp.com'
    const iFrameEl = `<script async src='${ rayleeClientUrl }/${ process.env.REACT_APP_ENVIRONMENT === 'development' ? 'raylee-init-test.js' : 'raylee-init.js' }'></script>
<script>window.rayleeBusinesses = ['${ selectedActiveBusiness?.value?._id }']</script>`

    useEffect(() => {
        getBusinessData()
    }, [])

    useEffect(() => {
        getData()
    }, [params])

    useEffect(() => {
        setParams(oldParams => ({
            ...oldParams,
            sort: view === 'table' ? 'createdAt:desc' : 'sequence:asc',
            limit: view === 'table' ? 10 : 100,
        }))
    }, [view])

    const getData = async () => {
        setIsLoading(true)
        const response = await getPopupsByParams(params)
        setDatas(response.data)
        setIsLoading(false)
    }

    const getSpecificPageData = (page) => {
        setParams(oldParams => {
            return {
                ...oldParams,
                page: page.selected + 1
            }
        })
    }

    const getBusinessData = async () => {
        const response = await getBusinessByParams({
            page: 1,
            limit: 100,
            organization: loggedInUser.organization
        })
        if (response.data && response.data.docs.length > 0) {
            const options = response.data.docs.map(doc => ObjectToSelect(`${ doc.name } - ${ doc.websiteUrl }`, doc))
            setBusinessOptions(options)
        }
    }

    const handleDelete = async (id, formikProps) => {
        await deletePopUp(id)
        formikProps.resetForm()
        modalCloseBtnRef.current.click()
        getData()
    }

    const handleUpload = async (formValues, formikProps) => {
        const date = new Date()
        const year = date.getFullYear()
        const month = date.getMonth() + 1
        const businessId = formValues.business.value._id
        const organizationId = loggedInUser.type !== 'ADMIN' ? loggedInUser.organization : getObjectId(formValues.business.value.organization)
        const config = {
            bucketName: process.env.REACT_APP_ENVIRONMENT === 'development' ? 'raylee-test' : 'raylee-production',
            dirName: `popup/${ organizationId }/${ businessId }/${ year }/${ month }`,
            region: 'ap-northeast-1',
            accessKeyId: 'AKIA4FUMDWP4KTKAPNVI',
            secretAccessKey: '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',
        }
        if (formValues._id) {
            const data = {
                ...formValues,
                organization: organizationId,
                business: businessId,
                user: getObjectId(formValues.user),
                tags: formValues.tags ? SelectMultiToArrayString(formValues.tags) : []
            }
            const uploadResponse = await handleUpdate(data)
            if (uploadResponse.data._id) {
                formikProps.resetForm()
                modalCloseBtnRef.current.click()
            }
        } else {
            if (formValues.fileType == 'video') {
                const video = formValues.video
                const fileName = 'web_' + uuidv4() + '.' + video.file.name.split('.').pop()
                const blob = video.file.slice(0, video.file.size, video.file.type)
                const newFile = new File([blob], fileName, { type: video.file.type })
                await S3FileUpload.uploadFile(newFile, config).then(async response => {
                    if (response.result.ok) {
                        const data = {
                            ...formValues,
                            key: response.key,
                            organization: organizationId,
                            business: businessId,
                            isActive: false,
                            tags: formValues.tags ? SelectMultiToArrayString(formValues.tags) : []
                        }
                        const uploadResponse = await uploadVideo(data)
                        if (uploadResponse.data._id) {
                            formikProps.resetForm()
                            modalCloseBtnRef.current.click()
                            getData()
                        }
                    }
                })
            } else {
                const uploadResponse = await uploadImage(formValues, organizationId, businessId)
                if (uploadResponse.data._id) {
                    formikProps.resetForm()
                    modalCloseBtnRef.current.click()
                    getData()
                }
            }
        }
    }

    const handleUpdate = async (video) => {
        const response = await updatePopUp(video)
        getData()
        return response
    }

    const handleSetData = (data) => {
        let tempData = { ...data }
        tempData.business = ObjectToSelect(tempData.business?.name, tempData.business)
        tempData.tags = tempData.tags ? ArrayStringToSelectMulti(tempData.tags) : []
        setData(tempData)
    }

    const handleUploadVideoButtonForm = () => {
        let newData = { ...FORM_INITIAL_VALUES.VIDEO }
        newData.fileType = 'video'
        if (selectedActiveBusiness) {
            newData.business = selectedActiveBusiness
        }
        setData(newData)
    }

    const handleUploadImageButtonForm = () => {
        let newData = { ...FORM_INITIAL_VALUES.VIDEO }
        newData.fileType = 'image'
        if (selectedActiveBusiness) {
            newData.business = selectedActiveBusiness
        }
        setData(newData)
    }

    return (
        <div id='kt_content' className='content d-flex flex-column flex-column-fluid'>
            <div id='kt_content_container' className='container-xxl'>
                <div className='card'>
                    <div className='card-header border-0 pt-6'>
                        <div className='card-title w-50'>
                            <div className='d-flex align-items-center position-relative my-1 w-100'>
                                <Select
                                    styles={ selectStyles }
                                    className={ 'form-control py-1 form-control-solid' }
                                    onChange={ data => {
                                        setActiveBusiness(data)
                                        setParams(state => ({
                                            ...state,
                                            page: 1,
                                            business: data?.value?._id
                                        }))
                                    } }
                                    placeholder={ t('SelectBusiness') }
                                    value={ selectedActiveBusiness }
                                    options={ businessOptions }
                                    isClearable={ true }
                                    isSearchable={ true }
                                />
                            </div>
                        </div>
                        <div className='card-toolbar'>
                            <div className='d-flex me-3' data-kt-user-table-toolbar='base'>
                                <div className='btn-group' role='group' aria-label='Basic example'>
                                    <button className={ `btn btn-${ view === 'table' ? 'secondary' : 'light' }` } type='button' onClick={ () => setView('table') } >
                                        <img src='assets/media/icons/duotune/text/txt009.svg' />
                                    </button>
                                    <button className={ `btn btn-${ view === 'preview' ? 'secondary' : 'light' }` } type='button' onClick={ () => setView('preview') } >
                                        <img src='assets/media/icons/duotune/general/gen019.svg' />
                                    </button>
                                    <button className='btn btn-light' type='button' disabled={ !selectedActiveBusiness } data-bs-toggle='modal' data-bs-target='#kt_modal_embed_business_videos'>
                                        <img src='assets/media/icons/duotune/coding/cod003.svg' />
                                    </button>
                                    <div id='kt_modal_embed_business_videos' className='modal fade' tabIndex={ -1 }>
                                        <div className='modal-dialog modal-dialog-centered mw-650px'>
                                            <div className='modal-content'>
                                                <div className='modal-header'>
                                                    <h2 className='fw-bolder'>{ `Embed PopUp from ${ selectedActiveBusiness && selectedActiveBusiness.label }` }</h2>
                                                    <div className='btn btn-icon btn-sm btn-active-icon-primary' data-bs-dismiss='modal' data-kt-users-modal-action='close'>
                                                        <span className='svg-icon svg-icon-1'>
                                                            <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
                                                                <rect opacity='0.5' x='6' y='17.3137' width='16' height='2' rx='1' transform='rotate(-45 6 17.3137)' fill='black'></rect>
                                                                <rect x='7.41422' y='6' width='16' height='2' rx='1' transform='rotate(45 7.41422 6)' fill='black'></rect>
                                                            </svg>
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className='modal-body scroll-y mx-5 mx-xl-15 my-7'>
                                                    <div className='row'>
                                                        <div className='form-floating col-9'>
                                                            <textarea className='form-control' disabled defaultValue={ iFrameEl } value={ iFrameEl } style={ { height: '100px', fontSize: '10px' } } />
                                                            <label>PopUp Code</label>
                                                        </div>
                                                        <div className='col d-flex align-items-end'>
                                                            <CopyToClipboard text={ iFrameEl } onCopy={ () => toast('info', '', 'Copied!') }>
                                                                <button className='btn btn-light w-100' type='button'>
                                                                    Copy
                                                                    <img src='assets/media/icons/duotune/communication/com008.svg' />
                                                                </button>
                                                            </CopyToClipboard>
                                                        </div>
                                                    </div>
                                                    <span>{ t('NotePutTheCodeInsideTheTag') }</span>
                                                </div >
                                            </div >
                                        </div >
                                    </div >
                                </div >
                            </div >
                            <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
                                <button className='btn btn-primary' type='button' disabled={ noRegisteredBusiness } onClick={ handleUploadVideoButtonForm } data-bs-toggle='modal' data-bs-target='#kt_modal_add_video'>{ t('AddVideo') }</button>
                            </div>
                            <div style={ { width: '10px', height: '20px' } }></div>
                            <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
                                <button className='btn btn-primary' type='button' disabled={ noRegisteredBusiness } onClick={ handleUploadImageButtonForm } data-bs-toggle='modal' data-bs-target='#kt_modal_add_video'>{ t('AddImage') }</button>
                            </div>
                            <div id='kt_modal_add_video' className='modal fade' tabIndex={ -1 }>
                                <div className='modal-dialog modal-dialog-centered mw-650px'>
                                    <div className='modal-content'>
                                        <div id='kt_modal_add_video_header' className='modal-header'>
                                            <h2 className='fw-bolder'>{ `${ data._id ? t('UpdatePopUp') : t('CreatePopUp') }` }</h2>
                                            <div className='btn btn-icon btn-sm btn-active-icon-primary' ref={ modalCloseBtnRef } data-bs-dismiss='modal' data-kt-users-modal-action='close'>
                                                <span className='svg-icon svg-icon-1'>
                                                    <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
                                                        <rect opacity='0.5' x='6' y='17.3137' width='16' height='2' rx='1' transform='rotate(-45 6 17.3137)' fill='black'></rect>
                                                        <rect x='7.41422' y='6' width='16' height='2' rx='1' transform='rotate(45 7.41422 6)' fill='black'></rect>
                                                    </svg>
                                                </span>
                                            </div>
                                        </div>
                                        <div className='modal-body scroll-y mx-5 mx-xl-15 my-7'>
                                            <PopUpForm
                                                initialValues={ data }
                                                handleSubmit={ handleUpload }
                                                handleDelete={ handleDelete }
                                                businessOptions={ businessOptions }
                                                modalCloseBtnRef={ modalCloseBtnRef }
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div >
                    </div >
                    <div className='card-body pt-0'>
                        {
                            view === 'table' ?
                                <PopUpTable
                                    datas={ datas }
                                    handleSetData={ handleSetData }
                                    getSpecificPageData={ getSpecificPageData }
                                    params={ params }
                                    isLoading={ isLoading }
                                    modalCloseBtnRef={ modalCloseBtnRef }
                                />
                                :
                                <PopUpPreview
                                    datas={ datas }
                                    handleUpdate={ handleUpdate }
                                    getSpecificPageData={ getSpecificPageData }
                                    makePopUpActive={ makePopUpActive }
                                    makePopUpInactive={ makePopUpInactive }
                                    params={ params }
                                    businessOptions={ businessOptions }
                                    selectedActiveBusiness={ selectedActiveBusiness }
                                    setActiveBusiness={ setActiveBusiness }
                                    view={ view }
                                    setView={ setView }
                                    isLoading={ isLoading }
                                    getData={ getData }
                                />
                        }
                    </div>
                </div >
            </div >
        </div >
    )
}

export default PopUp

import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FORM_INITIAL_VALUES } from '../../utils/constants/formValues'
import useRaqs from '../../utils/hooks/useRaqs'
import RAQForm from './RAQForm'
import RAQTable from './RAQTable'

function Raqs() {
    const { t } = useTranslation()
    const [params, setParams] = useState({
        page: 1,
        limit: 10
    })
    const [datas, setDatas] = useState(null)
    const [data, setData] = useState(FORM_INITIAL_VALUES.RAQ)
    const { getRaqsByParams, updateRaq } = useRaqs()
    const modalCloseBtnRef = useRef()

    useEffect(() => {
        getData()
    }, [params])

    const getData = async () => {
        const response = await getRaqsByParams(params)
        setDatas(response.data)
    }

    const getSpecificPageData = (page) => {
        setParams(oldParams => {
            return {
                ...oldParams,
                page: page.selected + 1
            }
        })
    }

    const handleCreateData = async (formData, formikProps) => {
        const response = await updateRaq(formData)
        if (response.data._id) {
            formikProps.resetForm()
            modalCloseBtnRef.current.click()
        }
        getData()
        return response
    }

    const handleSetData = (data) => {
        let tempData = { ...data }
        setData(tempData)
    }

    return (
        <div id='kt_content' className='content d-flex flex-column flex-column-fluid'>
            <div id='kt_content_container' className='container-xxl'>
                <div className='card'>
                    <div className='card-header border-0 pt-6'>
                        <div className='card-title'>
                            <div className='d-flex align-items-center position-relative my-1'>
                            </div>
                        </div>
                        <div className='card-toolbar'>
                            <div id='kt_modal_request_a_quote' className='modal fade' tabIndex={ -1 }>
                                <div className='modal-dialog modal-dialog-centered mw-650px'>
                                    <div className='modal-content'>
                                        <div className='modal-header'>
                                            <h2 className='fw-bolder'>{ t('RequestAQuote') }</h2>
                                            <div className='btn btn-icon btn-sm btn-active-icon-primary' ref={ modalCloseBtnRef } data-bs-dismiss='modal' data-kt-users-modal-action='close'>
                                                <span className='svg-icon svg-icon-1'>
                                                    <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
                                                        <rect opacity='0.5' x='6' y='17.3137' width='16' height='2' rx='1' transform='rotate(-45 6 17.3137)' fill='black'></rect>
                                                        <rect x='7.41422' y='6' width='16' height='2' rx='1' transform='rotate(45 7.41422 6)' fill='black'></rect>
                                                    </svg>
                                                </span>
                                            </div>
                                        </div>
                                        <div className='modal-body scroll-y mx-5 mx-xl-15 my-7'>
                                            <RAQForm initialValues={ data } handleSubmit={ handleCreateData } />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='card-body pt-0'>
                        <RAQTable datas={ datas } handleSetData={ handleSetData } getSpecificPageData={ getSpecificPageData } params={ params } />
                    </div>
                </div>
            </div>
        </div >
    )
}

export default Raqs

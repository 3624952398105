import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import useAuth from '../../utils/hooks/useAuth'
import useLanguage from '../../utils/hooks/useLanguage'
import ForgotPasswordForm from './ForgotPasswordForm'

function ForgotPassword() {
    const { i18n, t } = useTranslation()
    const { updateLanguage } = useLanguage()
    const history = useHistory()
    const { requestForgotPasswordEmail } = useAuth()

    const handleForgotPassword = async (values) => {
        const data = {
            email: values.email,
            language: i18n.resolvedLanguage
        }
        await requestForgotPasswordEmail(data, t)
    }

    return (
        <div className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed' style={ { backgroundImage: 'url(/assets/media/illustrations/sigma-1/14.png)' } }>
            <div className='d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20'>
                <div className='mb-12'>
                    <img className='h-60px' onClick={ () => history.push('/') } src='https://raylee-test.s3.ap-northeast-1.amazonaws.com/assets/icons/Raylee+Logo.svg' alt='Logo' />
                </div>
                <div className='w-lg-500px bg-body rounded shadow-sm p-10 p-lg-15 mx-auto'>
                    <div className='d-flex justify-content-end'>
                        <div className='menu-item px-3'>
                            <span
                                className={ i18n.resolvedLanguage === 'jp' ? 'menu-link d-flex px-5 active' : 'menu-link d-flex px-5' }
                                onClick={ () => updateLanguage(i18n.resolvedLanguage === 'jp' ? 'en' : 'jp') }
                            >
                                <span className='symbol symbol-20px me-4'>
                                    <img className='rounded-1' src={ `assets/media/flags/${ i18n.resolvedLanguage === 'jp' ? 'japan.svg' : 'united-states.svg' }` } alt='' />
                                </span>{ t(i18n.resolvedLanguage === 'jp' ? 'Japanese' : 'English') }</span>
                        </div>
                    </div>
                    <ForgotPasswordForm handleSubmit={ handleForgotPassword } />
                </div>
            </div>
        </div>
    )
}

export default ForgotPassword

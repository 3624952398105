import React from 'react'
import { useTranslation } from 'react-i18next'
import Pagination from '../custom/Pagination'

function CommentsTable({ datas, handleSetData, params, getSpecificPageData }) {
    const { t } = useTranslation()
    return (
        <div id="kt_table_users_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
            <div className="table-responsive">
                <table id="kt_table_users" className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer">
                    <thead>
                        <tr className="text-start text-muted fw-bolder fs-7 text-uppercase gs-0">
                            <th className="min-w-125px sorting" style={{ whiteSpace: 'pre' }} tabIndex={0} colSpan={1} rowSpan={1}>{t('Text')}</th>
                            <th className="min-w-125px sorting" style={{ whiteSpace: 'pre' }} tabIndex={0} colSpan={1} rowSpan={1}>{t('UpdatedBy')}</th>
                            <th className="min-w-125px sorting" style={{ whiteSpace: 'pre' }} tabIndex={0} colSpan={1} rowSpan={1}>{t('CreatedDate')}</th>
                        </tr>
                    </thead>
                    <tbody className="text-gray-600 fw-bold">
                        {
                            datas && datas.docs.map((doc, index) => (
                                <tr key={doc._id} className={`${index % 2 == 0 ? 'odd' : 'even'}`} onClick={() => handleSetData(doc)} data-bs-toggle="modal" data-bs-target="#kt_modal_add_Comments">
                                    <td>{doc.text}</td>
                                    <td>{doc.updatedBy ? doc.updatedBy?.firstName : 'n/a'}</td>
                                    <td>{new Date(doc.createdAt).toLocaleString()}</td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            </div>
            <div className="row">
                <div className="col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start">&nbsp;</div>
                <div className="col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end">
                    <div id="kt_table_users_paginate" className="dataTables_paginate paging_simple_numbers">
                        {datas && <Pagination pageCount={datas.totalPages} selectedPage={params.page} handlePageClick={getSpecificPageData} />}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CommentsTable

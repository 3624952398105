import React from 'react'
import { useTranslation } from 'react-i18next'
import ReactPlayer from 'react-player'
import { useHistory } from 'react-router'
import Pagination from '../custom/Pagination'

function VideoTable({ datas, handleSetData, params, getSpecificPageData, isLoading, modalCloseBtnRef }) {
    const history = useHistory()
    const { t } = useTranslation()
    return (
        <div id="kt_table_users_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
            <div className="table-responsive">
                <table id="kt_table_users" className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer">
                    <thead>
                        <tr className="text-start text-muted fw-bolder fs-7 text-uppercase gs-0">
                            <th className="min-w-125px sorting" style={ { width: '500px' } } tabIndex={ 0 } colSpan={ 1 } rowSpan={ 1 }>{ t('Preview') }</th>
                            <th className="min-w-125px sorting" style={ { whiteSpace: 'pre' } } tabIndex={ 0 } colSpan={ 1 } rowSpan={ 1 }>{ t('Title') }</th>
                            <th className="min-w-125px sorting" style={ { whiteSpace: 'pre' } } tabIndex={ 0 } colSpan={ 1 } rowSpan={ 1 }>{ t('Description') }</th>
                            <th className="min-w-125px sorting" style={ { whiteSpace: 'pre' } } tabIndex={ 0 } colSpan={ 1 } rowSpan={ 1 }>{ t('Tags') }</th>
                            <th className="min-w-125px sorting" style={ { whiteSpace: 'pre' } } tabIndex={ 0 } colSpan={ 1 } rowSpan={ 1 }>{ t('ViewCount') }</th>
                            <th className="min-w-125px sorting" style={ { whiteSpace: 'pre' } } tabIndex={ 0 } colSpan={ 1 } rowSpan={ 1 }>{ t('CTACount') }</th>
                            <th className="min-w-125px sorting" style={ { whiteSpace: 'pre' } } tabIndex={ 0 } colSpan={ 1 } rowSpan={ 1 }>{ t('LikeCount') }</th>
                            <th className="min-w-125px sorting" style={ { whiteSpace: 'pre' } } tabIndex={ 0 } colSpan={ 1 } rowSpan={ 1 }>{ t('Uploader') }</th>
                            <th className="min-w-125px sorting" style={ { whiteSpace: 'pre' } } tabIndex={ 0 } colSpan={ 1 } rowSpan={ 1 }>{ t('UpdatedBy') }</th>
                            <th className="min-w-125px sorting" style={ { whiteSpace: 'pre' } } tabIndex={ 0 } colSpan={ 1 } rowSpan={ 1 }>{ t('CreatedDate') }</th>
                            <th className="min-w-125px sorting" style={ { whiteSpace: 'pre' } } tabIndex={ 0 } colSpan={ 1 } rowSpan={ 1 }>{ t('UpdatedDate') }</th>
                        </tr>
                    </thead>
                    <tbody className="text-gray-600 fw-bold">
                        {
                            isLoading ?
                                <tr key={ 0 }>
                                    <td className="text-center" colSpan="11">{ t('Loading') }</td>
                                </tr>
                                :
                                datas && datas.docs.length > 0 ? datas.docs.map(doc => (
                                    <tr key={ doc._id }>
                                        <td className="d-flex align-items-center justify-content-center">
                                            <div data-bs-toggle="modal" data-bs-target="#kt_modal_add_video" onClick={ () => handleSetData(doc) }>
                                                <ReactPlayer
                                                    url={ process.env.REACT_APP_AWS_CLOUDFRONT_DOMAIN + '/' + doc.key }
                                                    className='react-player'
                                                    width='66px'
                                                    height='62px'
                                                />
                                            </div>
                                            <img
                                                src='/assets/media/icons/duotune/general/gen032.svg'
                                                className="ms-2 cursor-pointer"
                                                data-bs-toggle="tooltip"
                                                data-bs-placement="top"
                                                title={ t('GoToAnalysis') }
                                                alt=''
                                                height="20px"
                                                width="20px"
                                                onClick={ () => {
                                                    history.push('/analysis/' + doc._id + '?type=video')
                                                    modalCloseBtnRef.current.click()
                                                } }
                                            />
                                        </td>
                                        <td>{ doc.title }</td>
                                        <td>{ doc.description }</td>
                                        <td>{ doc.tags.map(tag => `#${ tag } `) }</td>
                                        <td>{ doc && doc.stats ? doc.stats.viewCount : 0 }</td>
                                        <td>{ doc && doc.stats ? doc.stats.leadCount : 0 }</td>
                                        <td>{ doc && doc.stats ? doc.stats.likeCount : 0 }</td>
                                        <td>{ doc.user.email }</td>
                                        <td>{ doc.updatedBy.email }</td>
                                        <td>{ new Date(doc.createdAt).toLocaleString() }</td>
                                        <td>{ new Date(doc.updatedAt).toLocaleString() }</td>
                                    </tr>
                                ))
                                    :
                                    <tr key={ 0 }>
                                        <td className="text-center" colSpan="11">{ t('NoRecordFound') }</td>
                                    </tr>
                        }
                    </tbody>
                </table>
            </div>
            <div className="row">
                <div className="col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start">&nbsp;</div>
                <div className="col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end">
                    <div id="kt_table_users_paginate" className="dataTables_paginate paging_simple_numbers">
                        { datas && <Pagination pageCount={ datas.totalPages } selectedPage={ params.page } handlePageClick={ getSpecificPageData } /> }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default VideoTable

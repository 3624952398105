import React, { useRef } from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'
import { Field, Form, Formik, useField } from 'formik'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import Fields from '../custom/Fields'
import { AssetValidation } from '../../utils/validations/formValidations'
import { bytesToReadableSize } from '../../utils/transformer/Number'
import { toast } from '../../utils/toast/toast'


function AssetForm(props) {
    const loggedInUser = useSelector(state => state.authData.loggedInUser)
    const { t } = useTranslation()
    const { initialValues, handleSubmit, handleDelete, organizationOptions, cancelForm } = props

    const deleteData = async (formikProps) => {
        window.Swal.fire({
            text: t('AreYouSureYouWantToDeleteThisAsset'),
            icon: 'warning',
            buttonsStyling: false,
            showCancelButton: true,
            confirmButtonText: t('OkGotIt'),
            cancelButtonText: t('Cancel'),
            customClass: {
                confirmButton: 'btn btn-danger',
                cancelButton: 'btn btn-primary'
            }
        }).then(async response => {
            if (response.isConfirmed) {
                formikProps.setSubmitting(true)
                await handleDelete(initialValues._id, formikProps)
                formikProps.setSubmitting(false)
            }
        })
    }

    return (
        <Formik
            initialValues={ initialValues }
            enableReinitialize
            validationSchema={ AssetValidation(loggedInUser.type, initialValues) }
            onSubmit={ async (fields, { ...formikProps }) => {
                return await handleSubmit(fields, formikProps)
            } }
        >
            { ({ isSubmitting, ...formikProps }) => (
                <Form className='form fv-plugins-bootstrap5 fv-plugins-framework'>
                    <div className='d-flex flex-column scroll-y me-n7 px-7' style={ { height: '637px', maxHeight: '637px' } } data-kt-scroll='true' data-kt-scroll-activate='{default: false, lg: true}' data-kt-scroll-max-height='auto' data-kt-scroll-offset='300px'>
                        {
                            initialValues._id &&
                            <div className='fv-row mb-7 fv-plugins-icon-container'>
                                <span className='form-label fs-6 fw-bolder text-dark'>
                                    { `URL: ${ process.env.REACT_APP_AWS_CLOUDFRONT_DOMAIN + '/' + initialValues.key }` }
                                </span>
                                <CopyToClipboard text={ process.env.REACT_APP_AWS_CLOUDFRONT_DOMAIN + '/' + initialValues.key } onCopy={ () => toast('info', '', 'Copied!') }>
                                    <button className="btn btn-light ms-3" type="button">
                                        Copy
                                        <img src="assets/media/icons/duotune/communication/com008.svg" />
                                    </button>
                                </CopyToClipboard>
                            </div>
                        }
                        {
                            loggedInUser.type === 'ADMIN' &&
                            <div className='fv-row mb-7 fv-plugins-icon-container'>
                                <Field
                                    { ...formikProps }
                                    type='select'
                                    placeholder={ t('Organization') }
                                    label={ t('Organization') }
                                    name='organization'
                                    as={ Fields }
                                    options={ organizationOptions }
                                    isDisabled={ initialValues._id } />
                            </div>
                        }
                        {
                            initialValues._id ?
                                <>
                                    <div className='d-flex justify-content-between align-items-center my-1'>
                                        {
                                            'image/png, image/gif, image/jpeg'.includes(initialValues.type) ?
                                                <img src={ process.env.REACT_APP_AWS_CLOUDFRONT_DOMAIN + '/' + initialValues.key } className='react-player' height={ '100px' } width={ '100px' } alt="uploader-image" />
                                                : 'video/mp4,video/x-m4v,video/quicktime'.includes(initialValues.type) ?
                                                    <video src={ process.env.REACT_APP_AWS_CLOUDFRONT_DOMAIN + '/' + initialValues.key } className='react-player' height={ '100px' } width={ '100px' } controls />
                                                    :
                                                    <span className='form-label fs-6 fw-bolder text-dark'>{ initialValues.name }</span>
                                        }
                                        <span className='form-label fs-6 fw-bolder text-dark'>{ bytesToReadableSize(initialValues.size) }</span>
                                    </div>
                                    <div className="fv-row mb-4">
                                        <Field { ...formikProps } type='text' placeholder={ t('Name') } label={ t('Name') } name='name' as={ Fields } />
                                    </div>
                                    <div className="fv-row mb-4">
                                        <Field
                                            { ...formikProps }
                                            type='tag'
                                            placeholder={ t('Tags') }
                                            label={ t('Tags') }
                                            name='tags'
                                            as={ Fields }
                                        />
                                    </div>
                                </>
                                :
                                <div className="fv-row mb-4">
                                    <Field
                                        { ...formikProps }
                                        initialValues={ initialValues }
                                        name='files'
                                        as={ CustomField }
                                    />
                                </div>
                        }
                    </div>
                    <div className='text-end pt-15'>
                        <button className='btn btn-light me-3' disabled={ isSubmitting } type='reset' onClick={ cancelForm }>{ t('Cancel') }</button>
                        { initialValues._id &&
                            <button className="btn btn-danger mx-3" disabled={ isSubmitting } type="button" onClick={ () => deleteData(formikProps) } data-kt-indicator={ `${ isSubmitting ? 'on' : 'off' }` }>
                                <span className="indicator-label">{ t('Delete') }</span> <span className="indicator-progress">
                                    { t('PleaseWait') }
                                    <span className="spinner-border spinner-border-sm align-middle ms-2" />
                                </span>
                            </button>

                        }
                        <button className='btn btn-primary' disabled={ isSubmitting } type='submit' data-kt-indicator={ `${ isSubmitting ? 'on' : 'off' }` }>
                            <span className='indicator-label'>{ initialValues._id ? t('Update') : t('Create') }</span> <span className='indicator-progress'>
                                { t('PleaseWait') }
                                <span className='spinner-border spinner-border-sm align-middle ms-2' />
                            </span>
                        </button>
                    </div>
                </Form>
            ) }
        </Formik>
    )
}

const CustomField = (props) => {
    const { t } = useTranslation()
    const inputRef = useRef()
    const [field, meta, helpers] = useField(props)

    const handleChange = (e) => {
        const files = e.target.files
        if (files.length > 0) {
            const values = Array.from(files).map((file, index) => {
                props.setFieldValue(`names[${ index }]`, file.name.replace(/\.[^/.]+$/, ''))
                return {
                    objectUrl: URL.createObjectURL(file),
                    file: file
                }
            })
            helpers.setError('')
            helpers.setValue(values)
        }
    }

    return (
        <>
            {
                field.value && field.value.length > 0 ?
                    field.value.map((data, index) => {
                        return (
                            <div key={ index }>
                                <div className='d-flex justify-content-between align-items-center my-1'>
                                    {
                                        'image/png, image/gif, image/jpeg'.includes(data.file.type) ?
                                            <img src={ data.objectUrl } className='react-player' height={ '100px' } width={ '100px' } alt="uploader-image" />
                                            : 'video/mp4,video/x-m4v,video/quicktime'.includes(data.file.type) ?
                                                <video src={ data.objectUrl } className='react-player' height={ '100px' } width={ '100px' } controls />
                                                :
                                                <span className='form-label fs-6 fw-bolder text-dark'>{ data.file.name }</span>
                                    }
                                    <span className='form-label fs-6 fw-bolder text-dark'>{ bytesToReadableSize(data.file.size) }</span>
                                </div>
                                <div className="fv-row mb-7 fv-plugins-icon-container">
                                    <Field
                                        { ...props }
                                        type='text'
                                        placeholder={ t('Name') }
                                        label={ t('Name') }
                                        name={ `names[${ index }]` }
                                        as={ Fields }
                                    />
                                </div>
                                <div className="fv-row mb-7 fv-plugins-icon-container">
                                    <Field
                                        { ...props }
                                        type='tag'
                                        placeholder={ t('Tags') }
                                        label={ t('Tags') }
                                        name={ `tags[${ index }]` }
                                        as={ Fields }
                                    />
                                </div>
                            </div>
                        )
                    })
                    :
                    <div className="dropzone" onClick={ () => inputRef.current.click() }>
                        <div className="dz-message needsclick">
                            <i className="bi bi-file-earmark-arrow-up text-primary fs-3x"></i>
                            <div className="ms-4 d-flex align-items-center">
                                <h3 className="fs-5 fw-bolder text-gray-900">{ t('SelectAssets') }</h3>
                            </div>
                        </div>
                    </div>
            }
            <input
                { ...field }
                className="invisible d-none"
                ref={ inputRef }
                type="file"
                multiple
                onChange={ handleChange }
                value=""
            />
            {
                meta.touched && meta.error &&
                <div className="text-danger">
                    { t(meta.error) }
                </div>
            }
        </>
    )
}

export default AssetForm

import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'

function TermsAndConditions() {
    const [ready, setReady] = useState(false)
    useEffect(() => {
        setTimeout(() => {
            setReady(true)
        }, 1000)
        return () => setReady(false)
    }, [])

    return (
        <div className={ `${ !ready && 'invisible' }` }>
            <Helmet>
                <meta charSet='UTF-8' />
                <meta httpEquiv='X-UA-Compatible' content='IE=edge' />
                <meta name='viewport' content='initial-scale=1.0,user-scalable=no' />
                <meta name='format-detection' content='telephone=no' />
                <title>
                    Raylee-レイリー｜縦型ショート動画をWebサイトに！ストーリーテリングサービス
                </title>
                <meta name='keywords' content />
                <meta name='copyright' content='x-climb株式会社' />
                <link
                    href='https://use.fontawesome.com/releases/v5.6.1/css/all.css'
                    rel='stylesheet'
                />
                <link href={ `${ process.env.PUBLIC_URL }/external-assets/css/style.css` } rel='stylesheet' />
                <script src={ `${ process.env.PUBLIC_URL }/external-assets/js/toggle.js` }></script>
                <script src={ `${ process.env.PUBLIC_URL }/external-assets/js/pagetop.js` }></script>
            </Helmet>
            <div className='subpage'>
                <header id='header'>
                    <div className='header_inner'>
                        <div className='logo'>
                            <a href='#'>
                                <img src={ `${ process.env.PUBLIC_URL }/external-assets/images/logo01.svg` } alt='Raylee' />
                            </a>
                        </div>
                        <div className='sp_menu'>
                            <div className='hamburger'>
                                <span />
                                <span />
                                <span />
                            </div>
                            {/* / .hamburger */ }
                            <nav className='top_nav'>
                                <ul>
                                    <li>
                                        <a href='/lp#merit'>導入メリット</a>
                                    </li>
                                    <li>
                                        <a href='/lp#comparison'>他ツールとの比較</a>
                                    </li>
                                    <li>
                                        <a href='/lp#case'>事例</a>
                                    </li>
                                    <li>
                                        <a href='/lp#plan'>費用・プラン</a>
                                    </li>
                                    <li>
                                        <a href='/lp#flow'>導入の流れ</a>
                                    </li>
                                    <li>
                                        <a href='/lp#faq'>よくある質問</a>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                        {/* / .sp_menu */ }
                        <ul className='top_btn'>
                            <li>
                                <a className='font-size-16px' href='https://www.raylee.one/' target='_blank' rel='noreferrer'>
                                    ログイン
                                </a>
                            </li>
                            <li>
                                <a className='font-size-16px' href='https://www.raylee.one/register' target='_blank' rel='noreferrer'>
                                    新規登録
                                </a>
                            </li>
                        </ul>
                    </div>
                    {/*//.header_inner*/ }
                </header>
            </div>
            {/*//.top_mv*/ }
            <section className='terms'>
                <div className='inner'>
                    <h2>利用規約</h2>
                    <p>
                        Raylee利用規約（以下「本利用規約」といいます。）には、x-climb株式会社（以下「当社」といいます。）の提供するサービス「Raylee」のご利用にあたり、遵守すべき基本的事項および当社と契約者との権利義務関係が定められております。
                    </p>
                    <h3>第1条（用語の定義）</h3>
                    <p>
                        本利用規約における用語の定義は、次の各号に掲げるとおりとします。
                        <br />
                        1）「本サービス」とは、当社がインターネット上で提供する縦型動画サービス「Raylee」（サービスの名称または内容が変更された場合は、当該変更後のサービスを含みます。）及び当社が提供する「Raylee」運用（その具体的内容、実施方法、業務委託料については当社と契約者との間で別途合意するものとする。）の総称をいいます。
                        <br />
                        2）「本ソフトウェア」とは、本サービスの一部の機能を利用するためにクラウド上でのアクセスが必要になるソフトウェアのことをいいます。なお、特に指定のない限り、本サービスとは、本ソフトウェアを含むものとします。
                        <br />
                        3）「本サイト」とは、そのドメインが「https://raylee.one」である、本サービスに関して当社が運営するウェブサイト（ウェブサイトのドメインが変更された場合は、当該変更後のウェブサイトを含みます。）のことをいいます。
                        <br />
                        4）「利用契約」とは、当社から本サービスの提供を受けるための本利用規約に基づいて成立する契約をいいます。
                        <br />
                        5）「契約者」とは、本利用規約に基づく利用契約を当社と契約している個人または法人をいいます。
                        <br />
                        6）「契約者サイト」とは、契約者が自らまたは第三者を通じて管理するウェブサイトをいいます。
                        <br />
                        7）「ユーザー」とは、契約者サイト上で本サービスを使用し、契約者（その業務委託先を含みます。）サイトの動画閲覧やそれに付随する行為を行う個人または法人をいいます。
                        <br />
                        8）「反社会的勢力等」とは、暴力団、暴力団員、暴力団準構成員、暴力団関係企業、総会屋等、社会運動等標ぼうゴロ、特殊知能暴力集団等、右翼団体その他の反社会的勢力およびこれに準ずる者をいいます。
                    </p>
                    <h3>第2条（規約の適用および変更）</h3>
                    <p>
                        1.
                        本利用規約は、全ての契約者に適用されます。本利用規約に同意いただけない場合、本サービスを利用することはできません。
                        <br />
                        2.
                        本サイト上で、本サービスに関するその他の規則、ルール、ガイドライン等（以下「その他の規則等」といいます。）が存在する場合に、その他の規則等は、本利用規約の一部を構成するものとします。本利用規約の規定とその他の規則等の内容に矛盾または抵触がある場合は、その他の規則等に優先関係に関する別段の定めがある場合を除き、本利用規約が優先して適用されます。
                        <br />
                        3.
                        当社は、本利用規約をいつでも任意に変更することができ、契約者はこれを承諾するものとします。当社が別途定める場合を除き、本利用規約の変更は、本サイトへの掲載によって随時契約者に公表するものとし、当該掲載をもって効力が生じます。当社は、本利用規約を変更した場合には、契約者に当該変更内容を通知するものとし、かかる通知後に本サービスの使用を継続する契約者または当社の定める期間内（特段の期間を定めなかった場合には1ヶ月以内）に解約の手続をとらなかった契約者は、全て変更後の本利用規約に同意したものとみなします。
                    </p>
                    <h3>第3条（本サービスの提供）</h3>
                    <p>
                        1.
                        当社は、契約者に対し、本利用規約に定める条件に従い、本サービスを提供致します。本サービスの具体的内容は、別途定めます。
                        <br />
                        2.
                        契約者は、自らの責任と費用において、ハードウェア、インターネット回線、セキュリティの確保等、本サービスの利用に必要な環境（以下「利用環境」といいます。）を整備します。
                    </p>
                    <h3>第4条（知的財産権等）</h3>
                    <p>
                        1.
                        プログラム、サービス提供画面、本ソフトウェア、本サービスに関する一切の特許権、実用新案権、意匠権、商標権、著作権、不正競争防止法上の権利、その他一切の財産的もしくは人格的権利（以下「知的財産権等」といいます。）は、全て当社または当社に権利を許諾する第三者に帰属します。
                        <br />
                        2.
                        契約者は、利用契約に基づいて、本サービスを利用することができますが、提供される本サービスに関するいかなる知的財産権等も取得するものではありません。契約者は、本サービスの一部または全部を、リバースエンジニアリング、逆コンパイル、または逆アセンブラ、その他本サービスを解析しようと試みてはならないものとし、また、その他当社の知的財産権等を侵害するいかなる行為も行ってはならないものとします。
                        <br />
                        3.
                        契約者は、ユーザーに対しても前項の内容を理解させ、同意させる義務を負います。
                        <br />
                        4.
                        契約者は、「Raylee」にて利用する動画等のクリエイティブ及びその他素材（以下併せて単に「クリエイティブ」といいます。）について、自らがクリエイティブを投稿その他の方法により送信することについての適法な権利を有していること、クリエイティブが第三者の権利を侵害していないこと、クリエイティブの内容が法令に違反していないことについて、当社に対し表明し、保証するものとします。
                    </p>
                    <h3>第5条（登録および契約期間）</h3>
                    <p>
                        1.
                        本サービスの利用を希望する者（以下「登録希望者」といいます。）は、本利用規約を遵守することに同意し、かつ当社の定める一定の情報（メールアドレス、パスワード、組織名、担当者名等。以下総称して、「登録情報」といいます。）を当社の定める方法で当社に提供することにより、当社に対し、本サービスの利用の登録を申請することができます。
                        <br />
                        2.
                        登録希望者は、登録の申請にあたり、真実、正確かつ最新の情報を当社に提供しなければなりません。
                        <br />
                        3.
                        登録の完了時に、本利用規約の諸規定に従った本サービスの利用契約が契約者と当社の間に成立し、契約者は本サービスを当社の定める方法で利用することができるようになります。
                        <br />
                        4.
                        当社は、第1項に基づき登録を申請した者が、以下の各号のいずれかの事由に該当する場合は、登録を拒否することがあります。
                        <br />
                        1）本利用規約に違反するおそれがあると当社が判断した場合
                        <br />
                        2）当社に提供された登録情報の全部または一部につき虚偽、誤記または記載漏れがあった場合
                        <br />
                        3）過去に本サービスの利用の登録を取り消された者である場合
                        <br />
                        4）未成年者、成年被後見人、被保佐人または被補助人のいずれかであり、法定代理人、後見人､保佐人または補助人の同意等を得ていることを当社が確認できなかった場合
                        <br />
                        5）反社会的勢力等である、または資金提供その他を通じて反社会的勢力等の維持、運営もしくは経営に協力もしくは関与する等反社会的勢力等との何らかの交流もしくは関与を行っていると当社が判断した場合
                        <br />
                        6）その他、当社が登録を適当でないと判断した場合
                        <br />
                        5.
                        利用契約は、契約者について本条に基づく登録が完了した日に効力を生じ、解除もしくは解約がなされた日または本サービスの提供が終了した日のいずれか早い日まで、当社と契約者との間で有効に存続するものとします。
                    </p>
                    <h3>第6条（認証・パスワード管理等）</h3>
                    <p>
                        1.
                        登録情報の管理不十分、使用上の過誤、第三者の使用等による損害等の責任は契約者が負うものとし、当社は一切の責任を負いません。
                    </p>
                    <h3>第7条（利用料金）</h3>
                    <p>
                        1. 本サービスの具体的な利用料金は、別途定めます。
                        <br />
                        2.
                        当社は、随時利用料金を改定し、または部分的変更を行うことができるものとします。契約者は、改定または部分的変更後の利用料金を支払うことによって、本サービスを継続的に利用することができますが、支払いが行われない場合、当社は本サービスの利用停止、本サービス上のデータの削除などの対応をとることがあるものとし、当社はかかる対応によって契約者に発生した損害等について一切の責任を負わないものとします。
                        <br />
                        3.
                        契約者は、利用料金等の支払を遅延した場合、支払期限の翌日から完済に至るまで、年14.6％の遅延損害金を当社または当社が指定する代理店等に支払うものとします。
                        <br />
                        4.
                        利用料金の改定または部分的変更は、1ヶ月前までに本サイトまたは当社が適当と判断するその他の方法により契約者にお知らせするものとし、かかるお知らせ後に本サービスの使用を継続する契約者または当社の定める期間内に解約の手続をとらなかった契約者は、全て変更後の利用料金に同意したものとみなします。
                    </p>
                    <h3>第8条（連絡について）</h3>
                    <p>
                        1.
                        当社は、電子メールの発信、本サイトへの掲載等、当社が適当と判断する方法により、契約者に随時必要な事項の通知、連絡等を行うものとします。当該連絡が、電子メールの送信または本サイトへの掲載によって行われる場合は、インターネット上に配信された時点で契約者に到達したものとみなします。
                    </p>
                    <h3>第9条（個人情報の取扱い）</h3>
                    <p>
                        1.
                        当社は、契約者の個人情報を、当社のプライバシーポリシーに基づき、適切に取り扱うものとし、契約者はプライバシーポリシーにもとづく当社による個人情報または個人データの取得および利用に同意します。
                        <br />
                        2.
                        当社は、契約者から収集した当該契約者に関する一切の情報につき、本サービスの提供及び運営に必要な範囲において、当社と提携する事業者に提供することがあり、契約者はこれに同意するものとします。
                        <br />
                        3.
                        契約者は、ユーザーの個人情報を個人情報保護法（契約者に適用される個人情報保護に関するガイドラインを含みます。）に従い適切に取り扱うことに同意します。
                    </p>
                    <h3>第10条（データおよびコンテンツの取扱い）</h3>
                    <p>
                        1.
                        契約者サイト上で本サービスを利用することにより得られるユーザーに関するデータ（以下「対象データ」といいます。）および契約者（契約者の業務委託先を含みます。）の担当者ならびにユーザーが本サービスを利用して投稿したコメント、その他のコンテンツ（以下「対象コンテンツ」といいます。）は、いずれも当社の管理するサーバに保存され、当社によって管理されるものとします。
                        <br />
                        2.
                        当社は、本利用規約（秘密保持義務を含みます。）およびプライバシーポリシーに従って、対象データおよび対象コンテンツにつき、これらを解析、翻案、利用、要約、編集、複製、開示、自動公衆送信およびそのために必要な送信可能化することに関する、全世界における非独占的、無償、移転可能かつ再許諾可能の権利の許諾を受けるものとし、契約者は、本サービスをユーザーに利用させるにあたり、そのために必要な当社に対する許諾を取得するものとします。また、契約者およびユーザーによる本サービスの利用は、対象コンテンツに関する著作者人格権を行使しないことに承諾することを条件として、当社により許諾されるものとします。ただし、当社は、対象コンテンツに個人情報が含まれる場合には、プライバシーポリシーに定められた範囲に限り、当該対象コンテンツを利用するものとし、予め本人の同意を得た場合、法令に基づく場合など個人情報保護法に定める場合、または裁判所・警察等の公的機関から法律に基づく要請を受けた場合を除き、当該対象コンテンツを開示しないものとします。
                        <br />
                        3.
                        前二項にもとづき当社が保持することになる対象データおよび対象コンテンツに関する諸権利は、利用契約の終了後も引き続き有効に存続するものとします。
                        <br />
                        4.
                        第1項の規定は、当社の契約者以外の第三者（ユーザーを含みます。）に対し、個人情報保護法またはプライバシーポリシーに基づき当社が負うことになる責任の範囲を超えるいかなる義務を構成するものでもないものとします。
                    </p>
                    <h3>第11条（本サービス内容の変更）</h3>
                    <p>
                        1.
                        当社は、契約者への事前の通知なくして、本サービス利用の諸条件、本サービスの部分的な改廃など、本サービスの内容を変更することができるものとし、契約者は、これを承諾するものとします。
                        <br />
                        2.
                        本サービス内容の大幅な変更が行われる場合、当社は本サイトまたは当社が適当と判断するその他の方法により契約者にお知らせいたします。
                    </p>
                    <h3>第12条（サービスの中断）</h3>
                    <p>
                        当社は、以下の各号の一に該当する場合には、契約者に事前に連絡することなく、一時的に本サービスの提供を中断する場合があります。契約者は、このことを了解の上で本サービスをご利用ください。
                        <br />
                        1）本サービスのシステムの保守を定期的に、または緊急に行う場合
                        <br />
                        2）火災、停電、事故などにより本サービスの提供ができなくなった場合
                        <br />
                        3）地震、噴火、洪水、津波などの天災により本サービスの提供ができなくなった場合
                        <br />
                        4）戦争、変乱、暴動、騒乱、労働争議などにより本サービスの提供ができなくなった場合
                        <br />
                        5）予想外の技術的問題が生じた場合
                        <br />
                        6）その他、運用上、技術上等の理由により当社が本サービスの一時的な中断を必要と判断した場合
                    </p>
                    <h3>第13条（禁止事項）</h3>
                    <p>
                        契約者は、本サービスの利用に際して、故意または過失の有無にかかわらず、自らまたは第三者（業務委託先、担当者およびユーザーを含みますがこれらに限られません。）をして、以下各号に該当する行為を行いまたは行わせてはならないものとします。
                        <br />
                        1）承諾を得ることなく他人の著作物やその複製物を送信する行為、他人のプライバシーや企業秘密に属する事項を送信する行為など、当社もしくは第三者の著作権、商標権等の知的財産権、財産、プライバシー、肖像権もしくはパブリシティ権を侵害する行為、またはそのおそれのある行為。
                        <br />
                        2）当社もしくは第三者に不利益もしくは損害を与える行為、またはそのおそれのある行為。
                        <br />
                        3）第三者の人権その他の権利を侵害する行為ないし公序良俗に反する行為、またはそのおそれのある行為。
                        <br />
                        4）詐欺・脅迫など犯罪実行の手段や、犯罪の教唆・扇動のために本サービスを利用するなど、犯罪的行為もしくは犯罪的行為に結びつく行為、またはそのおそれのある行為。
                        <br />
                        5）極度に当社の管理するサーバに負荷をかけるような態様で本サービスを使用するなど、当社もしくは本サービスの運営を妨げる行為、またはそのおそれのある行為。
                        <br />
                        6）当社もしくは本サービスの信用を毀損する行為、またはそのおそれのある行為。
                        <br />
                        7）当社に対して虚偽の申告、届出を行う行為。
                        <br />
                        8）本サービスを通じて、または本サービスに関連してコンピュータウィルス等、有害なプログラムを使用もしくは提供する行為、またはそのおそれのある行為。
                        <br />
                        9）法令等または利用契約に違反する行為。
                        <br />
                        10）当社の事前の書面による同意なく第三者へ利用契約上の地位を貸与または譲渡等処分する行為。
                        <br />
                        11）本サービスの一部または全部をリバースエンジニアリング、逆コンパイル、または逆アセンブラその他本サービスを解析する行為。
                        <br />
                        12）反社会的勢力等となり、または反社会的勢力等の維持、運営もしくは経営に協力もしくは関与する等反社会的勢力等との何らかの交流もしくは関与をする行為。
                        <br />
                        13）当社もしくは契約者が所属する業界団体の内部規則に違反する行為。
                        <br />
                        14）前各号の行為を直接または間接に惹起しまたは容易にする行為。
                        <br />
                        15）その他、当社が不適当であると判断する行為。
                        <br />
                        16）以下の対象コンテンツを投稿する行為。
                        <br />
                        ・自己もしくは他人または動物に対して危害、損失、身体的または精神的な傷害、精神的苦痛、死亡、障害、外貌醜状、身体または精神の疾病をもたらすおそれのあるもの
                        <br />
                        ・他人またはその財産に対してその他のあらゆる損失または損害をもたらすおそれのあるもの
                        <br />
                        ・法令等に違反するもの、または法令等の違反を助長するもの
                        <br />
                        ・当社において、差別的、違法、有害、濫用もしくは倫理的に問題であると判断し、または名誉を毀損し、知的財産権等、個人のプライバシーまたは公的権利を侵害し、他者に対していやがらせ、侮辱、威迫、冒涜その他不愉快な思いをさせるもの
                        <br />
                        ・違法な情報その他のコンテンツを含むもの（内部者情報や他者の営業秘密の開示、漏洩等を含みます。）
                        <br />
                        ・第三者の知的財産権等、プライバシー権、パブリシティ権その他の人格上または財産上の権利を侵害するもの
                        <br />
                        ・法令等、契約等または信義則に照らして利用する権限を持たない情報またはコンテンツを含むもの
                        <br />
                        ・詐欺的な、虚偽または誤解を招く、または欺瞞的なもの
                        <br />
                        ・猥褻なもの又は青少年に有害なもの
                        <br />
                        ・異性交際に関する性的表現が過激なもの
                        <br />
                        ・その他当社が不適切と判断するもの
                    </p>
                    <h3>第14条（本サービスの廃止）</h3>
                    <p>
                        1.
                        当社は、やむをえない事由が発生した場合には、利用契約を終了させ、または本サービスを廃止することがあります。
                        <br />
                        2.
                        本サービスを廃止する場合には、あらかじめ、本サイトにおける告知その他適宜の方法により契約者に通知いたします。ただし、緊急その他やむをえない事情がある場合はこの限りでありません。
                    </p>
                    <h3>第15条（契約者による解約）</h3>
                    <p>
                        契約者は、当社に対して、当社が別途指定する方法に基づき申し出ることにより、本サービスの利用契約を解約できるものとします。解約日は解約の申し込みが当社に到達してから1か月後の日付とし、その間に生じる利用料金は契約者が負担するものとします。また、銀行振込、クレジットカード等、支払の方法を問わず、解約日以前に支払いが完了した利用料金については、解約日以降にサービス利用期間が残っていた場合にも返金は行いません。また、契約プランにより、解約日以降もサービス利用期間が残っている場合、解約月に当社の計算に基づき、当社は利用料金を一括請求します。
                    </p>
                    <h3>第16条（利用契約の譲渡等）</h3>
                    <p>
                        1.
                        当社は、契約者に対する債権を第三者に譲渡できるものとし、契約者は、そのために契約者の個人情報等が当該第三者に提供されることを承諾するものとします。
                        <br />
                        2.
                        当社は本サービスにかかる事業を他社に譲渡した場合には、当該事業譲渡に伴い利用契約上の地位、本利用規約に基づく権利および義務ならびに契約者の登録情報その他の契約者またはユーザーの情報、対象データならびに対象コンテンツを当該事業譲渡の譲受人に譲渡することができるものとし、契約者は、かかる譲渡につき本項においてあらかじめ同意したものとします。なお、本項に定める事業譲渡には、通常の事業譲渡のみならず、当社が消滅会社または分割会社となる合併または会社分割その他事業の移転または承継をもたらすあらゆる場合を含むものとします。
                        <br />
                        3.
                        契約者は、当社の事前の書面による承諾なく、利用契約上の地位または本利用規約に基づく権利義務につき、第三者に対し、譲渡、移転、担保設定、その他の処分をすることはできないこととします。ただし、当社が本サービスの内容として具体的に定めている場合は、この限りでありません。
                    </p>
                    <h3>第17条（当社による解除）</h3>
                    <p>
                        1.
                        当社は、契約者が、以下の各号のいずれかの事由に該当する場合は、契約者の帰責性の有無にかかわらず、あらかじめ契約者に通知することなく、即時に当該利用契約を解除することができます。
                        <br />
                        1）本利用規約のいずれかの条項に違反した場合
                        <br />
                        2）登録情報に虚偽の事実があることが判明した場合
                        <br />
                        3）当社、他の契約者その他の第三者に損害等を生じさせるおそれのある目的または態様で本サービスを利用した、または利用しようとした場合
                        <br />
                        4）手段の如何を問わず、本サービスの運営を妨害した場合
                        <br />
                        5）支払停止もしくは支払不能となり、または破産手続開始、民事再生手続開始、会社更生手続開始、特別清算開始もしくはこれらに類する手続の開始の申立てがあった場合
                        <br />
                        6）自ら振出し、もしくは引受けた手形もしくは小切手につき、不渡りの処分を受けた場合、または手形交換所の取引停止処分その他これに類する措置を受けたとき
                        <br />
                        7）差押、仮差押、仮処分、強制執行または競売の申立てがあった場合
                        <br />
                        8）その他、当社が契約者としての登録の継続を適当でないと判断した場合
                        <br />
                        2.
                        前項各号のいずれかの事由に該当した場合、契約者は、当社に対して負っている債務の一切について当然に期限の利益を失い、直ちに当社に対して全ての債務の支払を行わなければならないものとします。
                        <br />
                        3.
                        当社は、本条に基づき当社が行った行為により契約者に生じた損害等について一切の責任を負いません。
                        <br />
                        4.
                        本条に基づき利用契約が解除された場合、契約者は、当社の指示に基づき、当社から提供を受けた本サービスに関連するソフトウェア、マニュアルその他のデータまたはその他の資料（複製したものを含みます。）につき、返還、廃棄その他の処分を行うものとします。
                    </p>
                    <h3>第18条（対象データおよび対象コンテンツに関する合意事項）</h3>
                    <p>
                        1.
                        本サービスが本質的に情報の喪失、改変、破壊等の危険が内在するインターネット通信網を介したサービスであることに鑑みて、契約者は、本サービスの利用にあたり自ら（契約者の業務委託先を含みます。）またはその担当者が送信した情報（以下「送信情報」といいます。）を自らの責任においてバックアップするものとします。また、このような限定的な条件のもとで本サービスが提供されることによる制約を合理的に軽減するため、ユーザーの対象コンテンツにつき当社が許諾を受けた権限の範囲内で、契約者はユーザーの対象コンテンツをバックアップすることができるものとします。当該バックアップを怠ったことによって契約者が被った損害等について、当社は、送信情報の復旧を含めて、一切責任を負いません。
                        <br />
                        2.
                        契約者は、対象コンテンツにつき当社が許諾を受けた権限の範囲内で、本サービス内において、当社が保存している対象コンテンツを閲覧することができるものとします。
                        <br />
                        3.
                        前二項に規定されるもの以外の対象コンテンツの利用は、送信情報その他契約者が権限を有している対象コンテンツを除き、当社の事前の明示的な承諾を得てこれを行うものとします。
                        <br />
                        4.
                        当社は、以下各号に該当する場合には、送信情報を閲覧・利用し、または第三者へ開示することがあります。但し、送信情報に個人情報が含まれる場合、予め本人の同意を得た場合、法令に基づく場合など個人情報保護法に定める場合、または裁判所・警察等の公的機関から法律に基づく要請を受けた場合を除き、当該対象コンテンツを開示しないものとします。
                        <br />
                        1）契約者の同意を得たとき
                        <br />
                        2）捜査機関の令状があるとき、裁判所からの調査嘱託等開示の要求があるとき、行政機関から開示要求があるとき
                        <br />
                        3）法律に従い開示の義務を負うとき
                        <br />
                        4）当社が、契約者が禁止事項に該当する行為を行っていると判断したとき
                        <br />
                        5）契約者や第三者の生命・身体・その他重要な権利を保護するために必要なとき
                        <br />
                        6）本サービスのメンテナンスのため緊急の必要があるとき
                        <br />
                        7）上記各号に準じる必要性があると当社が判断するとき
                        <br />
                        5.
                        当社は、以下各号に該当する場合には、送信情報について、その一部または全部を削除することがあります。当社は、削除された送信情報について、当該情報の復旧を含めて一切責任を負いません。
                        <br />
                        1）契約者の同意を得たとき
                        <br />
                        2）当社が、契約者が禁止行為を行っていると判断したとき
                        <br />
                        3）当該利用契約が、契約者による解約により終了したとき
                        <br />
                        4）当該利用契約が、第17条に定める当社による解除により終了したとき
                        <br />
                        5）第14条によって本サービスが廃止されたとき
                        <br />
                        6）契約者が本サービスに6か月以上ログインしなかったとき
                        <br />
                        7）上記各号に準じる必要性があると当社が判断したとき
                        <br />
                        6.
                        前項にかかわらず、当社は、本利用規約に違反する対象コンテンツにつき、当社の裁量によりいつでもこれを削除することができるものとします。
                    </p>
                    <h3>第19条（本サービス提供のあり方に関する合意事項）</h3>
                    <p>
                        1.
                        当社は、本サービスを、現状有姿の状態で提供します。当社は、次の各号につき、いかなる保証も行うものではありません。さらに、契約者が当社から直接または間接に、本サービスに関する情報を得た場合であっても、当社は、契約者に対し、本利用規約において規定されている内容を超えて、いかなる保証も行うものではありません。
                        <br />
                        1）本サービスの利用に起因して利用環境に不具合や障害が生じないこと
                        <br />
                        2）本サービスの正確性、完全性、永続性、目的適合性、有用性
                        <br />
                        3）契約者に適用のある法令、業界団体の内部規則等への適合性
                        <br />
                        4)
                        契約者の売上その他の業績が改善することその他契約者の望む一定の結果が達成されること
                        <br />
                        2. 当社は、対象コンテンツについて、一切監視の責任を負いません。
                        <br />
                        3.
                        本サイトから他のウェブサイトへのリンクまたは他のウェブサイトから本サイトへのリンクが提供されている場合でも、当社は、本サイト以外のウェブサイトおよびそこから得られる情報に関していかなる理由に基づいても一切の責任を負わないものとします。
                        <br />
                        4.
                        当社は、本サービスのうち、「Raylee」運用について、その全部または一部を第三者にその履行を委託することができるものとし、契約者はこれに同意するものとします。
                        <br />
                        5.
                        前項に定める委託を行う場合、当該第三者に対し、当社が契約者に対して負う秘密保持義務と同水準の秘密保持義務を課したうえで、「Raylee」運用の提供に必要な秘密情報及び個人情報を当該第三者に提供できるものとし、契約者はこれに同意するものとします。
                    </p>
                    <h3>第20条（免責事項）</h3>
                    <p>
                        1.
                        契約者のアカウントおよびパスワードが第三者によって使用されていた場合、契約者が被った損害等について、契約者の故意や過失の有無にかかわらず、当社は一切責任を負いません。
                        <br />
                        2.
                        第12条の規定によるサービス中断期間中、第14条の規定によって本サービスが廃止された場合に契約者が本サービスを利用できなかったことに関する損害等、作業が中断したことに関する損害等、データが失われたことに関する損害等、本サービスを利用することによって得られたであろう利益を得られなかった損害等など、本サービスの利用に際して発生した損害等については、直接損害・間接損害、現実に発生した損害か否かを問わず、当社は一切の責任を負わないものとし、契約者はこれを承諾するものとします。
                    </p>
                    <h3>第21条（責任の制限）</h3>
                    <p>
                        当社は、本サービスに関連して、当社が契約者に対して損害賠償責任を負う場合（契約者が個人であり消費者契約法が適用される場合を含みます。）においても、賠償すべき損害等の範囲は、契約者に現実に発生した直接かつ通常生じる範囲内の損害等に限るものとし、逸失利益を含むその他の特別損害等については責任を負いません。また、その賠償額は過去6か月以内に契約者が当社に支払った額の総額を上限とします。
                    </p>
                    <h3>第22条（紛争処理および損害賠償）</h3>
                    <p>
                        1.
                        契約者は、契約者が本サービスの利用により、当社もしくは第三者（ユーザーを含みます。以下本条において同じ。）が紛争の当事者となった場合、または当社若しくは第三者に対し損害等を与えた場合（契約者が本利用規約上の義務を履行しないことにより当社または第三者が損害等を被った場合を含みます。）、自己の責任と費用をもって、当該紛争を処理すると共に、かかる損害等を賠償するものとします。
                        <br />
                        2.
                        契約者及び当社は、本サービスの遂行に支障をきたすおそれのある事態が生じた場合は、速やかに相手方に連絡するとともに、相互に協力してその解決処理にあたるものとする。
                    </p>
                    <h3>第23条（秘密保持）</h3>
                    <p>
                        契約者及び当社は、本サービスに関連して情報開示者が情報受領者に対して秘密に扱うことを指定して開示した情報（開示の時において既に公知である情報、または開示後に情報受領者の責に帰すべき事由によらずに公知となった情報を除きます。）について、情報受領者の事前の書面による承諾がある場合を除き、開示目的以外に利用せず、また、第三者に開示しないものとします。
                    </p>
                    <h3>第24条（存続条項）</h3>
                    <p>第23条については、利用契約終了後3年間に限り存続するものとします。</p>
                    <h3>第25条（分離可能性）</h3>
                    <p>
                        本利用規約の規定の一部が法令または裁判所により違法、無効または不能であるとされた場合においても、本利用規約のその他の規定は有効に存続します。
                    </p>
                    <h3>第26条（準拠法）</h3>
                    <p>
                        本利用契約の成立、効力、履行および解釈には、日本法が適用されるものとします。
                    </p>
                    <h3>第27条（専属的合意管轄）</h3>
                    <p>
                        本利用契約に関わる紛争については、東京地方裁判所を第一審の専属的合意管轄裁判所とします。
                    </p>
                    <h3>第28条（協議）</h3>
                    <p>
                        本規約に定めのない事項または疑義が生じた事項については、当社と契約者が協議し、円満に解決を図るものとします。
                    </p>
                    <p className='right_txt'>2021年11月1日制定</p>
                </div>
            </section>
            {/*//.movie_area*/ }
            <footer>
                <div className='footer_inner'>
                    <div className='logo'>
                        <img src={ `${ process.env.PUBLIC_URL }/external-assets/images/logo03.svg` } alt='レイリー' />
                    </div>
                    <nav className='footer_nav'>
                        <ul>
                            <li>
                                <a href='https://www.x-climb.com/' target='_blank' rel='noreferrer'>
                                    運営会社
                                </a>
                            </li>
                            <li>
                                <a href='/termsAndConditions'>利用規約</a>
                            </li>
                            <li>
                                <a
                                    href='https://www.x-climb.com/privacypolicy.html'
                                    target='_blank' rel='noreferrer'
                                >
                                    プライバシーポリシー
                                </a>
                            </li>
                        </ul>
                    </nav>
                </div>
                {/*//.footer_inner*/ }
                <div className='copyright'>
                    <p>Copyrights©Raylee All rights reserved.</p>
                </div>
            </footer>
            <ul className='footer_btn'>
                <li>
                    <a href='https://www.raylee.one/' target='_blank' rel='noreferrer'>
                        ログイン
                    </a>
                </li>
                <li>
                    <a href='https://www.raylee.one/register' target='_blank' rel='noreferrer'>
                        新規登録
                    </a>
                </li>
            </ul>
        </div>
    )
}

export default TermsAndConditions

import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Redirect, useHistory, useParams } from 'react-router'
import { login } from '../../reducers'
import useAuth from '../../utils/hooks/useAuth'
import ResetPasswordForm from './ResetPasswordForm'

function ResetPassword() {
    const { t, i18n } = useTranslation()
    const params = useParams()
    const history = useHistory()
    const dispatch = useDispatch()
    const { resetPassword } = useAuth()

    const handleResetPassword = async (values) => {
        const data = {
            password: values.password,
            id: params.user,
            forgotPasswordCode: params.code,
            language: i18n.resolvedLanguage,
        }
        const response = await resetPassword(data, t)
        if (response?.data?.email) {
            const loginData = {
                email: response.data.email,
                password: values.password
            }
            dispatch(login(loginData, history.replace('/')))
        }
    }

    if (params.user && params.code) {
        return (
            <div className="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed" style={ { backgroundImage: 'url(/assets/media/illustrations/sigma-1/14.png)' } }>
                <div className="d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20">
                    <div className="mb-12">
                        <img className='h-60px' onClick={ () => history.push('/') } src='https://raylee-test.s3.ap-northeast-1.amazonaws.com/assets/icons/Raylee+Logo.svg' alt='Logo' />
                    </div>
                    <div className="w-lg-550px bg-body rounded shadow-sm p-10 p-lg-15 mx-auto">
                        <ResetPasswordForm handleSubmit={ handleResetPassword } />
                    </div>
                </div>
            </div>
        )
    } else {
        return <Redirect to="/" />
    }
}

export default ResetPassword

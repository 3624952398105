import { SET_KT_CONTENT_PAGE } from './actionTypes'

const initialState = {
    ktContentPage: 'VideoManagement',
}

export default function reducer(state = initialState, action) {
    switch (action.type) {
    case SET_KT_CONTENT_PAGE:
        return {
            ...state,
            ktContentPage: action.payload
        }
    default:
        return state
    }
}
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'

function PageTable({ datas, handleSetData, isLoading }) {
    const history = useHistory()
    const { t } = useTranslation()
    return (
        <div className='dataTables_wrapper dt-bootstrap4 no-footer'>
            <div className='table-responsive'>
                <table className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'>
                    <thead>
                        <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                            <th className='min-w-125px sorting' style={ { width: '50px' } } tabIndex={ 0 } colSpan={ 1 } rowSpan={ 1 }>{ t('Analysis') }</th>
                            <th className='min-w-125px sorting' style={ { whiteSpace: 'pre' } } tabIndex={ 0 } colSpan={ 1 } rowSpan={ 1 }>{ t('Organization') }</th>
                            <th className='min-w-125px sorting' style={ { whiteSpace: 'pre' } } tabIndex={ 0 } colSpan={ 1 } rowSpan={ 1 }>{ t('Title') }</th>
                            <th className='min-w-125px sorting' style={ { whiteSpace: 'pre' } } tabIndex={ 0 } colSpan={ 1 } rowSpan={ 1 }>{ t('Category') }</th>
                            <th className='min-w-125px sorting' style={ { whiteSpace: 'pre' } } tabIndex={ 0 } colSpan={ 1 } rowSpan={ 1 }>{ t('CreatedDate') }</th>
                            <th className='min-w-125px sorting' style={ { whiteSpace: 'pre' } } tabIndex={ 0 } colSpan={ 1 } rowSpan={ 1 }>{ t('UpdatedDate') }</th>
                        </tr>
                    </thead>
                    <tbody className='text-gray-600 fw-bold'>
                        {
                            isLoading ?
                                <tr key={ 0 }>
                                    <td className='text-center' colSpan='11'>{ t('Loading') }</td>
                                </tr>
                                :
                                datas && datas.docs.length > 0 ? datas.docs.map(doc => (
                                    <tr key={ doc._id } onClick={ () => handleSetData(doc) } data-bs-toggle='modal' data-bs-target='#kt_modal_add_Page'>
                                        <td className='d-flex align-items-center justify-content-left'>
                                            <img
                                                src='/assets/media/icons/duotune/general/gen032.svg'
                                                className='ms-2 cursor-pointer'
                                                data-bs-toggle='tooltip'
                                                data-bs-placement='top'
                                                title={ t('GoToAnalysis') }
                                                alt=''
                                                height='20px'
                                                width='20px'
                                                onClick={ () => {
                                                    history.push('/analysis/' + doc._id + '?type=page')
                                                } }
                                            />
                                        </td>
                                        <td>{ doc.organization.name }</td>
                                        <td>{ doc.title }</td>
                                        <td>{ doc.category }</td>
                                        <td>{ new Date(doc.createdAt).toLocaleString() }</td>
                                        <td>{ new Date(doc.updatedAt).toLocaleString() }</td>
                                    </tr>
                                ))
                                    :
                                    <tr key={ 0 }>
                                        <td className='text-center' colSpan='11'>{ t('NoRecordFound') }</td>
                                    </tr>
                        }
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default PageTable

import React from 'react'
import { Field, Form, Formik, FieldArray } from 'formik'
import { encodeURI } from 'js-base64'
import Fields from '../custom/Fields'
import { VideoFormValidation } from '../../utils/validations/formValidations'

import { useTranslation } from 'react-i18next'

function VideoForm(props) {
    const { t } = useTranslation()
    const { initialValues, handleSubmit, handleDelete, businessOptions } = props

    const deleteData = async (formikProps) => {
        window.Swal.fire({
            text: t('AreYouSureYouWantToDeleteThisVideo'),
            icon: 'warning',
            buttonsStyling: false,
            showCancelButton: true,
            confirmButtonText: t('OkGotIt'),
            cancelButtonText: t('Cancel'),
            customClass: {
                confirmButton: 'btn btn-danger',
                cancelButton: 'btn btn-primary'
            }
        }).then(async response => {
            if (response.isConfirmed) {
                formikProps.setSubmitting(true)
                await handleDelete(initialValues._id, formikProps)
                formikProps.setSubmitting(false)
            }
        })
    }

    const previewData = async (values) => {
        const video = {
            title: values?.title,
            tags: values?.tags?.map(tag => tag.value + ' '),
            description: values?.description,
            key: values?.key,
            layout: values?.layout,
            leadValue: values?.leadValue,
            qaList: values?.qaList
        }
        const props = JSON.stringify(video).replaceAll('#', '_number_sign_')
        const encodedProps = encodeURI(props)
        const win = window.open(`${ process.env.REACT_APP_RAYLEE_CLIENT_BASE_URL }/preview?props=${ encodedProps }`, '_blank')
        win.focus()
    }

    return (
        <Formik
            initialValues={ initialValues }
            enableReinitialize
            validationSchema={ VideoFormValidation(initialValues) }
            onSubmit={ async (fields, { ...formikProps }) => {
                return await handleSubmit(fields, formikProps)
            } }
        >
            { ({ isSubmitting, values, ...formikProps }) => (
                <Form id='kt_modal_add_video_form' className='form fv-plugins-bootstrap5 fv-plugins-framework'>
                    <div id='kt_modal_add_video_scroll' className='d-flex flex-column scroll-y me-n7 px-7' style={ { maxHeight: '637px' } } data-kt-scroll='true' data-kt-scroll-activate='{default: false, lg: true}' data-kt-scroll-max-height='auto' data-kt-scroll-dependencies='#kt_modal_add_user_header' data-kt-scroll-wrappers='#kt_modal_add_user_scroll' data-kt-scroll-offset='300px'>
                        {
                            initialValues._id ?
                                <video src={ process.env.REACT_APP_AWS_CLOUDFRONT_DOMAIN + '/' + initialValues.key } className='w-100 mb-4' controls style={ { maxHeight: '640px' } } />
                                :
                                <div className='fv-row mb-4'>
                                    <Field { ...formikProps } type='input-video-landscape' name='video' as={ Fields } secondsDurationLimit={ 180 } />
                                </div>
                        }
                        <div className='fv-row mb-7 fv-plugins-icon-container'>
                            <Field { ...formikProps } type='select' isDisabled={ initialValues.isActive } placeholder={ t('Business') } label={ t('Business') } name='business' as={ Fields } options={ businessOptions } />
                        </div>
                        <div className='fv-row mb-7 fv-plugins-icon-container'>
                            <Field { ...formikProps } type='text' placeholder={ t('Title') } label={ t('Title') } name='title' as={ Fields } />
                        </div>
                        <div className='fv-row mb-7 fv-plugins-icon-container'>
                            <Field { ...formikProps } type='text' placeholder={ t('Description') } label={ t('Description') } name='description' as={ Fields } />
                        </div>
                        <div className='fv-row mb-7 fv-plugins-icon-container'>
                            <FieldArray
                                name='qaList'
                                render={ arrayHelpers => (
                                    <div>
                                        { values.qaList && values.qaList.length > 0 ? (
                                            values.qaList.map((qa, index) => (
                                                <div key={ index }>
                                                    <Field { ...formikProps } type='text' placeholder={ t('Question') } label={ t('Question') } name={ `qaList.${ index }.question` } as={ Fields } />
                                                    <span style={ { width: '100%', display: 'inline-block', } } ></span>
                                                    <Field { ...formikProps } type='text' placeholder={ t('Answer') } label={ t('Answer') } name={ `qaList.${ index }.answer` } as={ Fields } />
                                                    <span style={ { width: '100%', display: 'inline-block', } } ></span>

                                                    <button className='btn btn-primary' disabled={ isSubmitting } type='button' onClick={ () => arrayHelpers.remove(index) }>
                                                        <span className='indicator-label'>-</span>
                                                        <span className='indicator-progress'>
                                                            { t('PleaseWait') }
                                                            <span className='spinner-border spinner-border-sm align-middle ms-2' />
                                                        </span>
                                                    </button>
                                                    <span style={ { width: '10px', display: 'inline-block', } } ></span>

                                                    <button className='btn btn-primary' disabled={ isSubmitting } type='button' onClick={ () => arrayHelpers.insert(index + 1, '') }>
                                                        <span className='indicator-label'>+</span>
                                                        <span className='indicator-progress'>
                                                            { t('PleaseWait') }
                                                            <span className='spinner-border spinner-border-sm align-middle ms-2' />
                                                        </span>
                                                    </button>
                                                    <span style={ { width: '100%', display: 'inline-block', } } ></span>
                                                </div>
                                            ))
                                        ) : (
                                            <button className='btn btn-primary' disabled={ isSubmitting } type='button' onClick={ () => arrayHelpers.push('') }>
                                                <span className='indicator-label'>{ t('AddAQuestion') }</span>
                                                <span className='indicator-progress'>
                                                    { t('PleaseWait') }
                                                    <span className='spinner-border spinner-border-sm align-middle ms-2' />
                                                </span>
                                            </button>
                                        ) }
                                    </div>
                                ) }
                            />
                        </div>
                        <div className='fv-row mb-7 fv-plugins-icon-container'>
                            <Field { ...formikProps } type='tag' placeholder={ t('Tags') } label={ t('Tags') } name='tags' as={ Fields } />
                        </div>
                        <div className='fv-row mb-7 fv-plugins-icon-container'>
                            <Field { ...formikProps } type='text' placeholder={ t('URLContactNumberEmail') } label='CTA' name='leadValue' as={ Fields } />
                        </div>
                        <img src='line.svg' className='w-100 my-5' />
                        <div className='row mb-7'>
                            <div className='col-12 col-md-4 mb-3 position-relative'>
                                <Field { ...formikProps } type='color-picker' placeholder={ t('CTALabelColor') } label={ t('CTALabelColor') } name='layout.leadButtonLabelColor' as={ Fields } />
                            </div>
                            <div className='col-12 col-md-4 mb-3 position-relative'>
                                <Field { ...formikProps } type='color-picker' placeholder={ t('CTABackgroundColor') } label={ t('CTABackgroundColor') } name='layout.leadButtonBackgroundColor' as={ Fields } />
                            </div>
                            <div className='col-12 col-md-4 mb-3'>
                                <Field { ...formikProps } type='text' placeholder={ t('CTALabel') } label={ t('CTALabel') } name='layout.leadButtonLabel' as={ Fields } />
                            </div>
                        </div>
                        <div className='fv-row mb-7 fv-plugins-icon-container'>
                            <Field { ...formikProps } type='checkbox' label={ t('IsCTAButtonVisible') } name='layout.isLeadButtonVisible' as={ Fields } />
                        </div>
                        <div className='fv-row mb-7 fv-plugins-icon-container'>
                            <Field { ...formikProps } type='checkbox' label={ t('IsLikeButtonVisible') } name='layout.isLikeButtonVisible' as={ Fields } />
                        </div>
                        <div className="fv-row mb-7 fv-plugins-icon-container">
                            <Field { ...formikProps } type='checkbox' label={ t('IsProgressBarVisible') } name='layout.isProgressBarVisible' as={ Fields } />
                        </div>
                        <div className="fv-row mb-7 fv-plugins-icon-container">
                            <Field { ...formikProps } type='checkbox' label={ t('IsTimeStampVisible') } name='layout.isTimeStampVisible' as={ Fields } />
                        </div>
                        {
                            process.env.REACT_APP_ENVIRONMENT === 'development' &&
                            <>
                                <div className='fv-row mb-7 fv-plugins-icon-container'>
                                    <Field { ...formikProps } type='checkbox' label={ t('IsCommentButtonVisible') } name='layout.isCommentButtonVisible' as={ Fields } />
                                </div>
                                <div className='fv-row mb-7 fv-plugins-icon-container'>
                                    <Field { ...formikProps } type='checkbox' label={ t('IsShareButtonVisible') } name='layout.isShareButtonVisible' as={ Fields } />
                                </div>
                                <div className='fv-row mb-7 fv-plugins-icon-container'>
                                    <Field { ...formikProps } type='checkbox' label={ t('IsProgressBarVisible') } name='layout.isProgressBarVisible' as={ Fields } />
                                </div>
                                <div className='fv-row mb-7 fv-plugins-icon-container'>
                                    <Field { ...formikProps } type='checkbox' label={ t('IsTimeStampVisible') } name='layout.isTimeStampVisible' as={ Fields } />
                                </div>
                            </>
                        }
                    </div>
                    <div className='d-flex justify-content-end text-end pt-15'>
                        <button className='btn btn-light me-3' disabled={ isSubmitting } type='reset' data-bs-dismiss='modal' data-kt-users-modal-action='close'>{ t('Cancel') }</button>
                        { initialValues._id &&
                            <button className='btn btn-danger mx-3' disabled={ isSubmitting } type='button' onClick={ () => deleteData(formikProps) } data-kt-indicator={ `${ isSubmitting ? 'on' : 'off' }` }>
                                <span className='indicator-label'>{ t('Delete') }</span> <span className='indicator-progress'>
                                    { t('PleaseWait') }
                                    <span className='spinner-border spinner-border-sm align-middle ms-2' />
                                </span>
                            </button>

                        }
                        <button className='btn btn-primary' disabled={ isSubmitting } type='submit' data-kt-indicator={ `${ isSubmitting ? 'on' : 'off' }` } data-kt-users-modal-action='submit'>
                            <span className='indicator-label'>{ t(initialValues._id ? t('Update') : t('Upload')) }</span> <span className='indicator-progress'>
                                { t('PleaseWait') }
                                <span className='spinner-border spinner-border-sm align-middle ms-2' />
                            </span>
                        </button>
                        {
                            initialValues?._id &&
                            <button className='btn btn-secondary ms-2' disabled={ isSubmitting } type='button' onClick={ () => previewData(values) } data-kt-indicator={ `${ isSubmitting ? 'on' : 'off' }` } data-kt-users-modal-action='submit'>
                                <span className='indicator-label'>{ t('Preview') }</span> <span className='indicator-progress'>
                                    { t('PleaseWait') }
                                    <span className='spinner-border spinner-border-sm align-middle ms-2' />
                                </span>
                            </button>
                        }
                    </div>
                </Form>
            ) }
        </Formik>
    )
}

export default VideoForm

import axios from 'axios'
import { ENDPOINTS } from '../constants/endpoint'
import { toast } from '../toast/toast'

function useOrganizations() {
    const getOrganizationByParams = async (params) => {
        return axios.get(process.env.REACT_APP_API_URL + ENDPOINTS.GET_BUSINESS_CLUSTERS, {
            params: params
        }).catch(error => toast('error', '', error.response.data.message))
    }

    const createOrganization = async (requestBody) => {
        return axios.post(process.env.REACT_APP_API_URL + ENDPOINTS.CREATE_ORGANIZATION, requestBody).catch(error => toast('error', '', error.response.data.message))
    }

    const updateOrganization = async (requestBody) => {
        return axios.put(process.env.REACT_APP_API_URL + ENDPOINTS.CREATE_ORGANIZATION + '/' + requestBody._id, requestBody).catch(error => toast('error', '', error.response.data.message))
    }

    return { getOrganizationByParams, createOrganization, updateOrganization }
}

export default useOrganizations

import axios from 'axios'
import { ENDPOINTS } from '../constants/endpoint'
import { toast } from '../toast/toast'

function usePages() {
    const getPagesByParams = async (params) => {
        return await axios.get(process.env.REACT_APP_API_URL + ENDPOINTS.PAGES + ENDPOINTS.QUERY, {
            params: params
        }).catch(error => toast('error', '', error.response.data.message))
    }

    const getPagesById = async (id) => {
        return await axios.get(process.env.REACT_APP_API_URL + ENDPOINTS.PAGES + '/' + id).catch(error => toast('error', '', error.response.data.message))
    }

    const createPage = async (pageData) => {
        return await axios.post(process.env.REACT_APP_API_URL + ENDPOINTS.PAGES, pageData).catch(error => toast('error', '', error.response.data.message))
    }

    const updatePage = async (pageData) => {
        return await axios.put(process.env.REACT_APP_API_URL + ENDPOINTS.PAGES + '/' + pageData._id, pageData).catch(error => toast('error', '', error.response.data.message))
    }

    const deletePage = async (id) => {
        return await axios.delete(process.env.REACT_APP_API_URL + ENDPOINTS.PAGES + '/' + id).catch(error => toast('error', '', error.response.data.message))
    }

    return { getPagesById, getPagesByParams, createPage, updatePage, deletePage }
}

export default usePages

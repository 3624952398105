import axios from 'axios'
import { ENDPOINTS } from '../constants/endpoint'
import { toast } from '../toast/toast'
import { SelectMultiToArrayString } from '../../utils/transformer/Select'

function usePopups() {
    const getPopupsByParams = async (params) => {
        return axios.get(process.env.REACT_APP_API_URL + ENDPOINTS.GET_POPUPS_QUERY, {
            params: params
        })
    }

    const getPopupById = async (id) => {
        return axios.get(process.env.REACT_APP_API_URL + ENDPOINTS.GET_POPUPS + '/' + id)
    }

    const uploadVideo = async (videoData) => {
        return axios.post(process.env.REACT_APP_API_URL + ENDPOINTS.UPLOAD_POPUP_VIDEO, videoData).catch(error => toast('error', '', error.response.data.message))
    }

    const uploadImage = async (formValues, organizationId, businessId) => {
        let formData = new FormData()
        formData.append('file', formValues.video.file)
        formData.append('fileType', 'image')
        formData.append('title', formValues.title)
        formData.append('description', formValues.description)
        formData.append('settings.delayBeforeDisplay', formValues.settings.delayBeforeDisplay)
        formData.append('settings.displayCount', formValues.settings.displayCount)
        formData.append('layout.leadButtonLabel', formValues.layout.leadButtonLabel)
        formData.append('layout.leadButtonLabelColor', formValues.layout.leadButtonLabelColor)
        formData.append('layout.leadButtonBackgroundColor', formValues.layout.leadButtonBackgroundColor)
        formData.append('layout.isLeadButtonVisible', formValues.layout.isLeadButtonVisible)
        formData.append('layout.isLikeButtonVisible', formValues.layout.isLikeButtonVisible)
        formData.append('layout.isCommentButtonVisible', formValues.layout.isCommentButtonVisible)
        formData.append('layout.isShareButtonVisible', formValues.layout.isShareButtonVisible)
        formData.append('leadValue', formValues.leadValue)
        formData.append('isActive', false)
        formData.append('business', businessId)
        formData.append('organization', organizationId)

        if (formValues.tags && formValues.tags.length > 0) {
            formData.append('tags[]', SelectMultiToArrayString(formValues.tags))
        }

        if (formValues.qaList && formValues.qaList.length > 0) {
            for (let index in formValues.qaList) {
                var q = 'qaList[' + index + '][question]'
                var a = 'qaList[' + index + '][answer]'
                formData.append(q, formValues.qaList[index].question)
                formData.append(a, formValues.qaList[index].answer)
            }
        }

        return axios.post(process.env.REACT_APP_API_URL + ENDPOINTS.UPLOAD_POPUP_IMAGE + '/' + organizationId + '/' + businessId, formData, {
            headers: { 'content-type': 'multipart/form-data' }
        }).catch(error => toast('error', '', error.response.data.message))
    }

    const updatePopUp = async (videoData) => {
        return axios.put(process.env.REACT_APP_API_URL + ENDPOINTS.UPLOAD_POPUP + '/' + videoData._id, videoData).catch(error => toast('error', '', error.response.data.message))
    }

    const makePopUpActive = async (id) => {
        return await axios.put(process.env.REACT_APP_API_URL + ENDPOINTS.POPUPS_ACTIVE + '/' + id).catch(error => toast('error', '', error?.response?.data?.message))
    }

    const makePopUpInactive = async (id) => {
        return await axios.put(process.env.REACT_APP_API_URL + ENDPOINTS.POPUPS_INACTIVE + '/' + id).catch(error => toast('error', '', error?.response?.data?.message))
    }

    const deletePopUp = async (id) => {
        return axios.delete(process.env.REACT_APP_API_URL + ENDPOINTS.UPLOAD_POPUP + '/' + id).catch(error => toast('error', '', error.response.data.message))
    }

    return { getPopupsByParams, getPopupById, uploadVideo, uploadImage, updatePopUp, deletePopUp, makePopUpActive, makePopUpInactive }
}

export default usePopups

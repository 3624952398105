import React from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import Business from '../businessManagement/Business'
import ChangePassword from '../changePassword/ChangePassword'
import Comments from '../commentsManagement/Comments'
import Organizations from '../organizationManagement/Organizations'
import Users from '../userManagement/Users'
import VideosVertical from '../videoManagementVertical/Videos'
import VideosLandscape from '../videoManagementLandscape/Videos'
import PopUp from '../popUpManagement/PopUp'
import Raqs from '../raqManagement/RAQ'
import Pages from '../PageManagement/Pages'
import Assets from '../assetManagement/Assets'
import Chat from '../chatManagement/Chat'
import ChatConnection from '../chatConnectionManagement/ChatConnection'

function KTContent() {
    const { t } = useTranslation()
    const ktContentPage = useSelector(state => state.appData.ktContentPage)

    const getContent = () => {
        switch (ktContentPage) {
        case 'Dashboard':
            return <></>
        case 'PopUpManagement':
            return <PopUp />
        case 'VerticalVideoManagement':
            return <VideosVertical />
        case 'LandscapeVideoManagement':
            return <VideosLandscape />
        case 'PageManagement':
            return <Pages />
        case 'AssetManagement':
            return <Assets />
        case 'OrganizationManagement':
            return <Organizations />
        case 'BusinessManagement':
            return <Business />
        case 'UserManagement':
            return <Users />
        case 'CommentsManagement':
            return <Comments />
        case 'ChatConnectionManagement':
            return <ChatConnection />
        case 'RAQManagement':
            return <Raqs />
        case 'Chat':
            return <Chat />
        case 'ChangePassword':
            return <ChangePassword />
        default:
            return <></>
        }
    }

    return (
        <>
            <Helmet>
                <meta charSet="UTF-8" />
                <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
                <meta name="viewport" content="initial-scale=1.0,user-scalable=no" />
                <meta name="format-detection" content="telephone=no" />
                <title>{ 'Raylee Admin - ' + t(ktContentPage) }</title>
                <meta name="keywords" content />
                <meta name="copyright" content="x-climb株式会社" />
            </Helmet>
            { getContent() }
        </>
    )
}

export default KTContent

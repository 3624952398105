import React from 'react'
import { useSelector } from 'react-redux'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import Activate from './components/activate/Activate'
import ForgotPassword from './components/forgotPassword/ForgotPassword'
import Home from './components/home/Home'
import LandingPage from './components/landingPage/LandingPage'
import Login from './components/login/Login'
import Register from './components/register/Register'
import ResetPassword from './components/resetPassword/ResetPassword'
import TermsAndConditions from './components/termsAndConditions/TermsAndConditions'
import Manuals from './components/manuals/Manuals'
import Verify from './components/verify/Verify'
import Analysis from './components/analysis/Analysis'
import PaymentFailed from './components/payment/PaymentFailed'
import PaymentSuccess from './components/payment/PaymentSuccess'
import PagePreview from './components/pagePreview/PagePreview'

function Routes() {
    const authData = useSelector(state => state.authData)
    return (
        <Router>
            <Switch>
                <Route exact path='/lp' component={ LandingPage } />
                <Route exact path='/termsAndConditions' component={ TermsAndConditions } />
                <Route exact path='/manuals' component={ Manuals } />
                <Route exact path='/payment/failed' component={ PaymentFailed } />
                <Route exact path='/payment/success' component={ PaymentSuccess } />
                {
                    authData.isAuthenticated ?
                        <>
                            <Route exact path='/' component={ Home } />
                            <Route exact path='/page/preview' component={ PagePreview } />
                            <Route exact path='/analysis/:id' component={ Analysis } />
                        </>
                        :
                        <>
                            <Route exact path='/' component={ Login } />
                            <Route exact path='/register' component={ Register } />
                            <Route exact path='/verify/:email' component={ Verify } />
                            <Route exact path='/activate/user/:user/:code' component={ Activate } />
                            <Route exact path='/forgot-password' component={ ForgotPassword } />
                            <Route exact path='/reset-password/:user/:code' component={ ResetPassword } />
                        </>
                }
            </Switch>
        </Router>
    )
}

export default Routes

import React from 'react'
import ReactPaginate from 'react-paginate'
import { useTranslation } from 'react-i18next'

export default function Pagination({ pageCount, selectedPage, handlePageClick }) {
    const { t } = useTranslation()

    return (
        <ReactPaginate
            previousLabel={t('Previous')}
            nextLabel={t('Next')}
            breakLabel={'...'}
            breakClassName={'break-me'}
            pageCount={pageCount}
            pageRangeDisplayed={2}
            marginPagesDisplayed={1}
            forcePage={selectedPage - 1}
            onPageChange={handlePageClick}
            containerClassName={'pagination'}
            pageClassName={'paginate_button page-item'}
            pageLinkClassName={'page-link'}
            previousLinkClassName={'page-link'}
            nextLinkClassName={'page-link'}
            activeClassName={'active'}
        />
    )
}

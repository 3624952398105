import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import usePages from '../../utils/hooks/usePages'
import { FORM_INITIAL_VALUES } from '../../utils/constants/formValues'
import { ArrayStringToSelectMulti, ObjectToSelect, SelectMultiToArrayString } from '../../utils/transformer/Select'
import { getObjectId } from '../../utils/transformer/Object'

import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { selectStyles } from '../custom/FieldsStyles'
import useOrganizations from '../../utils/hooks/useOrganizations'
import PageForm from './PageForm'
import PageTable from './PageTable'

function Pages() {
    const { t } = useTranslation()
    const loggedInUser = useSelector(state => state.authData.loggedInUser)
    const [activeOrganization, setActiveOrganization] = useState(null)
    const [data, setData] = useState(FORM_INITIAL_VALUES.PAGE)
    const [organizationOptions, setOrganizationOptions] = useState([])
    const [datas, setDatas] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const [view, setView] = useState('table')
    const [params, setParams] = useState({
        page: 1,
        limit: 10,
        sort: 'createdAt:desc',
        organization: loggedInUser.organization,
    })

    const { getPagesByParams, createPage, updatePage, deletePage } = usePages()

    const { getOrganizationByParams } = useOrganizations()
    const noRegisteredOrganizations = organizationOptions.length <= 0

    useEffect(() => {
        getOrganizationData()
    }, [])

    useEffect(() => {
        getData()
    }, [params])

    const getData = async () => {
        setIsLoading(true)
        const response = await getPagesByParams(params)
        setDatas(response.data)
        window.tinymce && window.tinymce.remove()
        setIsLoading(false)
    }

    const getSpecificPageData = (page) => {
        setParams(oldParams => {
            return {
                ...oldParams,
                page: page.selected + 1
            }
        })
    }

    const getOrganizationData = async () => {
        const response = await getOrganizationByParams({
            page: 1,
            limit: 100
        })
        if (response.data && response.data.docs.length > 0) {
            const options = response.data.docs.map(doc => ObjectToSelect(`${ doc.name }`, doc))
            setOrganizationOptions(options)
        }
    }

    const handleDelete = async (id, formikProps) => {
        await deletePage(id)
        formikProps.resetForm()
        setView('table')
        getData()
    }

    const handleUpload = async (formValues, formikProps) => {
        const organizationId = loggedInUser.type !== 'ADMIN' ? loggedInUser.organization : getObjectId(formValues.organization.value)
        const data = {
            ...formValues,
            organization: organizationId,
            user: loggedInUser._id,
            chatConnection: formValues.chatConnection && formValues.chatConnection.value && getObjectId(formValues.chatConnection?.value),
            metadataKeywords: formValues.metadataKeywords ? SelectMultiToArrayString(formValues.metadataKeywords) : [],
            emailRecipients: formValues.emailRecipients ? SelectMultiToArrayString(formValues.emailRecipients) : []
        }
        const response = formValues._id ? await updatePage(data) : await createPage(data)
        if (response?.data?._id) {
            setView('table')
            formikProps.resetForm()
            getData()
            if (window.tinymce) {
                window.tinymce.remove()
            }
        }
    }

    const handleSetData = (data) => {
        let tempData = { ...data }
        tempData.chatConnection = ObjectToSelect(tempData.chatConnection?.name, tempData.chatConnection)
        tempData.organization = ObjectToSelect(tempData.organization?.name, tempData.organization)
        tempData.metadataKeywords = tempData.metadataKeywords && ArrayStringToSelectMulti(tempData.metadataKeywords)
        tempData.emailRecipients = tempData.emailRecipients && ArrayStringToSelectMulti(tempData.emailRecipients)
        setData(tempData)
        setView('form')
    }

    const openForm = () => {
        if (window.tinymce) {
            window.tinymce.remove()
        }
        let newData = { ...FORM_INITIAL_VALUES.PAGE }
        if (activeOrganization) {
            newData.organization = activeOrganization
        }
        setView('form')
        setData(newData)
    }

    const cancelForm = () => {
        setView('table')
        setData(FORM_INITIAL_VALUES.PAGE)
        if (window.tinymce) {
            window.tinymce.remove()
        }
    }

    return (
        <div id='kt_content' className='content d-flex flex-column flex-column-fluid'>
            <div id='kt_content_container' className='container-xxl'>
                <div className='card'>
                    {
                        view === 'table' &&
                        <div className='card-header border-0 pt-6'>
                            <div className='card-title w-50'>
                                <div className='d-flex align-items-center position-relative my-1 w-100'>
                                    {
                                        loggedInUser.type === 'ADMIN' &&
                                        <Select
                                            styles={ selectStyles }
                                            className={ 'form-control py-1 form-control-solid' }
                                            onChange={ data => {
                                                setActiveOrganization(data)
                                                setParams(state => ({
                                                    ...state,
                                                    page: 1,
                                                    organization: data?.value?._id
                                                }))
                                            } }
                                            placeholder={ t('SelectOrganization') }
                                            value={ activeOrganization }
                                            options={ organizationOptions }
                                            isClearable={ true }
                                            isSearchable={ true }
                                        />
                                    }
                                </div>
                            </div>
                            <div className='card-toolbar'>
                                <div className='d-flex me-3' data-kt-user-table-toolbar='base'>
                                </div >
                                <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
                                    <button className='btn btn-primary' type='button' disabled={ noRegisteredOrganizations } onClick={ openForm }>{ t('AddPage') }</button>
                                </div>
                            </div >
                        </div >
                    }
                    {
                        view === 'form' &&
                        <>
                            <div className='card-header border-0 pt-6'>
                                <div className='card-title w-50'>
                                    <div className='d-flex align-items-center position-relative my-1 w-100'>
                                        <h3>{ t('CreatePage') }</h3>
                                    </div>
                                </div>
                            </div >
                            <div className='card-body pt-0 d-flex'>
                                <PageForm
                                    initialValues={ data }
                                    handleSubmit={ handleUpload }
                                    handleDelete={ handleDelete }
                                    organizationOptions={ organizationOptions }
                                    cancelForm={ cancelForm }
                                />
                            </div>
                        </>
                    }
                    {
                        view === 'table' &&
                        <div className='card-body pt-0'>
                            <PageTable
                                datas={ datas }
                                handleSetData={ handleSetData }
                                params={ params }
                                getSpecificPageData={ getSpecificPageData }
                                isLoading={ isLoading }
                            />
                        </div>
                    }
                </div >
            </div >
        </div >
    )
}

export default Pages
export const FORM_INITIAL_VALUES = {
    ASSET: {
        organization: '',
        names: [],
        tags: [],
    },
    BUSINESS: {
        name: '',
        websiteUrl: '',
        organization: '',
        customLayout: {
            videoCollectionBackgroundColor: '#fff',
        },
    },
    CHAT_CONNECTION: {
        domainURL: '',
        name: '',
        isOnline: '',
        title: '',
        composeInputPlaceholder: '',
        emailRecipients: []
    },
    COMMENT: {
        text: ''
    },
    FORGOT_PASSWORD: {
        email: ''
    },
    ORGANIZATION: {
        name: '',
        code: ''
    },
    PAGE: {
        chatConnection: undefined,
        bodyContent: '',
        metadataKeywords: [],
        metadataTitle: '',
        metadataDescription: '',
        metadataThumbnail: '',
        emailRecipients: [],
        sendEmailNotificationIfGainVisitor: false
    },
    RAQ: {
        firstName: '',
        middleName: '',
        lastName: '',
        email: '',
        organization: '',
        status: '',
        remarks: []
    },
    RESET_PASSWORD: {
        password: '',
        confirmPassword: ''
    },
    USER: {
        type: '',
        organization: '',
        planType: '',
        firstName: '',
        middleName: '',
        lastName: '',
        email: '',
        password: ''
    },
    VIDEO: {
        video: '',
        business: '',
        title: '',
        description: '',
        qaList: [],
        tags: '',
        leadValue: '',
        fileType: '',
        isActive: false,
        layout: {
            leadButtonLabel: 'Buy now',
            leadButtonLabelColor: '#fff',
            leadButtonBackgroundColor: '#009EF7',
            isLeadButtonVisible: true,
            isLikeButtonVisible: true,
            isCommentButtonVisible: false,
            isShareButtonVisible: false,
            isProgressBarVisible: false,
            isTimeStampVisible: false,
            isQaButtonVisible: false,
            ratio: '9:16',
            width: 0,
            height: 0,
        },
        settings: {
            delayBeforeDisplay: 1,
            displayCount: 1,
            displayWhenLeaving: false,
        }
    },
    VIDEOLANDSCAPE: {
        video: '',
        business: '',
        title: '',
        description: '',
        qaList: [],
        tags: '',
        leadValue: '',
        fileType: '',
        isActive: false,
        layout: {
            leadButtonLabel: 'Buy now',
            leadButtonLabelColor: '#fff',
            leadButtonBackgroundColor: '#009EF7',
            isLeadButtonVisible: true,
            isLikeButtonVisible: true,
            isCommentButtonVisible: false,
            isShareButtonVisible: false,
            isProgressBarVisible: false,
            isTimeStampVisible: false,
            isQaButtonVisible: false,
            ratio: '16:9',
            width: 0,
            height: 0,
        },
        settings: {
            delayBeforeDisplay: 1,
            displayCount: 1,
            displayWhenLeaving: false,
        }
    },
    REGISTER: {
        email: '',
        organization: '',
        firstName: '',
        middleName: '',
        lastName: '',
        password: '',
        confirmPassword: '',
    },
    CHANGE_PASSWORD: {
        oldPassword: '',
        newPassword: '',
        confirmPassword: ''
    }
}
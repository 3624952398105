import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

function ComposeMessage({ activeConversation, handleCloseConversation, sendMessage }) {
    const { t } = useTranslation()
    const [composeText, setComposeText] = useState('')

    useEffect(() => {
        setComposeText('')
    }, [activeConversation])

    const handleSendMessage = async () => {
        if (composeText.trim().length > 0) {
            await sendMessage(composeText)
            setComposeText('')
        }
    }

    const handleTextAreaChange = (e) => {
        if (e.code === 'Enter' && !e.shiftKey && !e.nativeEvent.isComposing) {
            e.preventDefault()
            handleSendMessage()
        }
    }

    return (
        <>
            <textarea
                value={ composeText }
                onChange={ e => setComposeText(e.currentTarget.value) }
                onKeyDown={ handleTextAreaChange }
                style={ { height: '10vh' } }
                className='form-control mt-3'
                placeholder={ t('TypeYourReplyHere') }
                disabled={ !activeConversation?._id }
            />
            <div className='d-flex justify-content-end mt-3' style={ { height: '5vh' } }>
                <button onClick={ handleCloseConversation } className='btn btn-secondary me-3 d-block d-md-none'>{ t('Back') }</button>
                <button className='btn btn-primary' onClick={ handleSendMessage }>{ t('Send') }</button>
            </div>
        </>
    )
}

export default ComposeMessage